<template>
  <div class="campaign_analize">
    <!-- side var  -->
    <SideVar />
    <!-- main div -->
    <RightColumnOutline>
      <PageTitle>Campaign Analisis </PageTitle>
      <div class="px-4">
        <div class="my-8 text-white text-xl text-left">
          Campaign name: {{ campaign_name }}
        </div>
        <!-- summary seciton -->
        <div class="container px-4 mx-auto">
          <!-- max-w-xs	max-width: 20rem; /* 320px */ -->
          <div class="max-w-none mx-auto bg-gray-600 rounded-xl">
            <div class="flex flex-wrap">
              <div
                class="w-full sm:w-1/2 lg:w-1/4 pl-4 md:pl-8 xl:pl-10 border-b lg:border-b-0 sm:border-r border-gray-700"
              >
                <div class="py-5 px-6">
                  <h5
                    class="text-sm font-semibold text-gray-300 mb-6 leading-normal tracking-wide"
                  >
                    Count posts<i
                      class="pi pi-question-circle mx-1"
                      v-tippy="{
                        content:
                          'ツイートが作成されてから約15分以内に反映されます。tweetが作成されてから24時間が経過するとエンゲージメントとスコアが確定します。増加率はUTC 00:00を基準にした前日比です。',
                      }"
                    ></i>
                  </h5>
                  <div class="flex flex-wrap justify-center mb-1 -m-2">
                    <div class="w-auto p-2">
                      <h4
                        class="text-2xl text-gray-100 font-bold tracking-wide"
                      >
                        {{ tweetCount.toLocaleString() }}
                      </h4>
                    </div>
                    <div class="w-auto p-2">
                      <div
                        class="inline-flex px-2 h-7 items-center justify-center text-green-500 bg-green-900 rounded-full"
                      >
                        <i class="text-xs mr-1 pi pi-arrow-up"></i>
                        <span class="text-xs font-medium"
                          >{{
                            Math.floor(
                              (tweetCount / tweetCount_yesterday - 1) * 100
                            )
                          }}%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="w-full sm:w-1/2 lg:w-1/4 pl-4 md:pl-8 xl:pl-10 border-b lg:border-b-0 lg:border-r border-gray-700"
              >
                <div class="py-5 px-6">
                  <h5
                    class="text-sm font-semibold text-gray-300 mb-6 leading-normal tracking-wide"
                  >
                    Engagements<i
                      class="pi pi-question-circle mx-1"
                      v-tippy="{
                        content:
                          'ツイート作成後24時間が経過したpostのエンゲージメントの合計です。増加率はUTC 00:00を基準にした前日比です。',
                      }"
                    ></i>
                  </h5>
                  <div class="flex flex-wrap justify-center mb-1 -m-2">
                    <div class="w-auto p-2">
                      <h4
                        class="text-2xl text-gray-100 font-bold tracking-wide"
                      >
                        {{ engagementCount.toLocaleString() }}
                      </h4>
                    </div>
                    <div class="w-auto p-2">
                      <div
                        class="inline-flex px-2 h-7 items-center justify-center text-green-500 bg-green-900 rounded-full"
                      >
                        <i class="text-xs mr-1 pi pi-arrow-up"></i>
                        <span class="text-xs font-medium"
                          >{{
                            Math.floor(
                              (engagementCount / engagementCount_yesterday -
                                1) *
                                100
                            )
                          }}%</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="w-full sm:w-1/2 lg:w-1/4 pl-4 md:pl-8 xl:pl-10 border-b md:border-b-0 sm:border-r border-gray-700"
              >
                <div class="py-5 px-6">
                  <h5
                    class="text-sm font-semibold text-gray-300 mb-6 leading-normal tracking-wide"
                  >
                    Participants<i
                      class="pi pi-question-circle mx-1"
                      v-tippy="{
                        content:
                          'PostやLike、Repost、QTでキャンペーンに参加した人数です',
                      }"
                    ></i>
                  </h5>
                  <div class="flex flex-wrap justify-center mb-1 -m-2">
                    <div class="w-auto p-2">
                      <h4
                        class="text-2xl text-gray-100 font-bold tracking-wide"
                      >
                        {{ paricipantsCount.toLocaleString() }}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full sm:w-1/2 lg:w-1/4 pl-4 md:pl-8 xl:pl-10">
                <div class="py-5 px-6">
                  <h5
                    class="text-sm font-semibold text-gray-300 mb-6 leading-normal tracking-wide"
                  >
                    Score<i
                      class="pi pi-question-circle mx-1"
                      v-tippy="{
                        content:
                          '全参加者が獲得したスコアの合計です。２倍期間を設定している場合や、参加者の中に非公開ユーザーが含まれる場合、最終的なランキングのスコアの合計とは一致しない場合があります。',
                      }"
                    ></i>
                  </h5>
                  <div class="flex flex-wrap justify-center mb-1 -m-2">
                    <div class="w-auto p-2">
                      <h4
                        class="text-2xl text-gray-100 font-bold tracking-wide"
                      >
                        {{ scoreCount.toLocaleString() }}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-left grid grid-cols-3 mt-3">
          <div v-for="dailycount in dailycountSeries" :key="dailycount">
            <p class="text-lg text-gray-200 font-semibold mb-3 mt-3">
              {{ dailycount["name"] }}
            </p>
            <div v-if="dailycountOptions.xaxis.categories.length > 0">
              <apexchart
                :height="chart_height"
                :title="dailycount.name"
                :options="dailycountOptions"
                :series="[dailycount]"
              ></apexchart>
            </div>
          </div>
          <div></div>
          <div class="col-span-3">
            <p class="text-lg text-gray-100 font-semibold mb-6 mt-6">
              Score distribution(top 20)
            </p>
            <p class="text-xs text-gray-200 font-semibold mb-6">
              ※速報値：2倍期間や同一コンテンツの非加点は考慮していない
            </p>
            <div v-if="scoreOptions.labels.length > 1">
              <apexchart
                height="400"
                type="donut"
                :options="scoreOptions"
                :series="scoreSeries"
              ></apexchart>
            </div>
          </div>
          <div class="mt-6 col-span-3">
            <p class="text-lg text-gray-100 font-semibold mb-6 mt-6">
              Get Ranking CSV
            </p>
            <div class="flex items-center">
              <div class="w-96">
                <Datepicker v-model="date" range />
              </div>
              <div class="mx-4">
                <DoButton :clickFunction="downlordrankingPromise" :values="{}"
                  >Download</DoButton
                >
              </div>
            </div>
          </div>
          <div class="mt-6 col-span-3">
            <p class="text-lg text-gray-100 font-semibold mb-6 mt-6">
              Post Details
            </p>
            <div class="items-center">
              <DoButton :clickFunction="getTweetsPromise" :values="{}"
                >Get posts</DoButton
              >
            </div>
            <template v-if="tweets_items.length != 0">
              <div class="m-3">
                <span class="text-white">search field:</span>
                <select v-model="searchField">
                  <option>username</option>
                  <option>content</option>
                </select>
              </div>
              <div class="m-3">
                <span class="text-white">search value: </span>
                <input type="text" v-model="searchValue" />
              </div>
              <div class="mt-4 p-3">
                <EasyDataTable
                  :headers="tweets_headers"
                  table-class-name="customize-table"
                  :items="tweets_items"
                  :sort-by="tweets_sortBy"
                  :sort-type="tweets_sortType"
                  :search-field="searchField"
                  :search-value="searchValue"
                >
                  <template #item-username="{ username }">
                    <a
                      :href="'https://twitter.com/' + username"
                      target="_blank"
                      rel="noopener noreferrer"
                      >{{ username }}</a
                    >
                  </template>
                  <template #item-content="{ content, username, tweet_id }">
                    <a
                      :href="'https://twitter.com/hoge/status/' + tweet_id"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div class="max-w-lg mx-auto m-4">
                        <div
                          class="bg-red shadow-lg rounded-lg overflow-hidden"
                        >
                          <div class="p-4">
                            <div class="flex items-center">
                              <img
                                class="h-4 w-4 rounded-full mr-2 mb-1"
                                src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/iconfinder-social-media-applications-6twitter-4102580_113802.png"
                                alt="twitter_icon"
                              />
                              <span
                                class="block font-medium text-base leading-snug text-black"
                                >{{ username }}</span
                              >
                            </div>
                            <p class="mt-3 text-gray-700 text-sm">
                              {{ content }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </template>
                </EasyDataTable>
              </div>
            </template>
          </div>
        </div>
      </div>
    </RightColumnOutline>
  </div>
</template>

<script>
import api from "../api/api.js";
import { useRoute } from "vue-router";
import VueApexCharts from "vue3-apexcharts";
import Datepicker from "@vuepic/vue-datepicker";

export default {
  name: "Campaign_regit",
  components: {
    apexchart: VueApexCharts,
    Datepicker,
  },
  async mounted() {
    const route = useRoute();
    await api
      .getdailyTweetCount({ campaign_id: route.params.id })
      .then((response) => {
        this.dailycountOptions.xaxis.categories =
          response["data"]["categories"];
        console.log(response["data"]);
        for (let i = 0; i < response["data"]["name"].length; i++) {
          this.dailycountSeries.push({
            name: response["data"]["name"][i],
            data: response["data"]["data"][i],
          });
        }
        this.tweetCount = response["data"]["data"][0].reduce(function (a, b) {
          return a + b;
        });
        this.tweetCount_yesterday =
          this.tweetCount - response["data"]["data"][0].slice(-1)[0];
        this.engagementCount = 0;
        this.engagementCount += response["data"]["data"][1].reduce(function (
          a,
          b
        ) {
          return a + b;
        });
        this.engagementCount += response["data"]["data"][2].reduce(function (
          a,
          b
        ) {
          return a + b;
        });
        this.engagementCount += response["data"]["data"][3].reduce(function (
          a,
          b
        ) {
          return a + b;
        });
        this.engagementCount += response["data"]["data"][4].reduce(function (
          a,
          b
        ) {
          return a + b;
        });
        this.engagementCount_yesterday =
          this.engagementCount -
          response["data"]["data"][1].slice(-2)[0] -
          response["data"]["data"][2].slice(-2)[0] -
          response["data"]["data"][3].slice(-2)[0] -
          response["data"]["data"][4].slice(-2)[0];
      })
      .catch((error) => {
        console.log(error);
        this.$toast.show("分析データの取得に失敗しました", {
          type: "error",
          position: "top-right",
          duration: 5000,
        });
      });
    await api
      .getScore({ campaign_id: route.params.id })
      .then((response) => {
        this.scoreSeries = response["data"]["series"].slice(0, 20);
        this.scoreOptions.labels = response["data"]["labels"].slice(0, 20);
        this.paricipantsCount = response["data"]["labels"].length;
        if (this.paricipantsCount != 0) {
          this.scoreCount = response["data"]["series"].reduce(function (a, b) {
            return a + b;
          });
        } else this.scoreCount = 0;
      })
      .catch((error) => {
        console.log(error);
        this.$toast.show("スコアデータの取得に失敗しました", {
          type: "error",
          position: "top-right",
          duration: 5000,
        });
      });
    await api
      .getCampaign({ id: route.params.id })
      .then((response) => {
        this.campaign = response["data"][0];
        this.campaign_name = this.campaign["campaign_name"];
        let start_date = new Date(this.campaign["start_time"]);
        start_date.setHours(start_date.getHours() + 9);
        let end_date = new Date(this.campaign["end_time"]);
        end_date.setHours(end_date.getHours() + 9);
        this.date = [start_date, end_date];
      })
      .catch((error) => {
        console.log(error);
        this.$toast.show("キャンペーンデータの取得に失敗しました", {
          type: "error",
          position: "top-right",
          duration: 5000,
        });
      });
  },
  data() {
    return {
      campaign_name: null,
      keyword: null,
      campaign: null,
      date: [],
      tweetCount: "-",
      engagementCount: "-",
      paricipantsCount: "-",
      scoreCount: "-",
      tweetCount_yesterday: 1,
      engagementCount_yesterday: 1,
      paricipantsCount_yesterday: 1,
      scoreCount_yesterday: 1,
      chart_height: window.innerHeight / 3,
      chart_width: window.innerWidth / 3,
      dailycountOptions: {
        chart: {
          id: "Daily_Counts",
          type: "area",
        },
        title: {
          text: "",
          align: "left",
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "datetime",
          categories: [],
          labels: {
            style: {
              colors: "#C4BBAF",
            },
          },
        },
        yaxis: {
          // type: "datetime",
          labels: {
            style: {
              colors: "#C4BBAF",
            },
          },
        },
        legend: {
          show: true,
          labels: {
            colors: "#C4BBAF",
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      dailycountSeries: [],
      scoreOptions: {
        labels: [],
        legend: {
          show: true,
          labels: {
            colors: "#C4BBAF",
          },
        },
        title: {
          text: "",
        },
      },
      scoreSeries: [],
      tweets_headers: [
        { text: "Username", value: "username", sortable: true, width: 100 },
        { text: "Content", value: "content" },
        { text: "Like", value: "like", sortable: true },
        { text: "RT", value: "retweet", sortable: true },
        { text: "Rep", value: "reply", sortable: true },
        { text: "Imp", value: "impression", sortable: true },
        { text: "Date", value: "created_at", sortable: true },
      ],
      tweets_items: [],
      tweets_sortBy: "impression",
      tweets_sortType: "desc",
      searchField: "",
      searchValue: "",
    };
  },
  computed: {},
  methods: {
    downlordrankingPromise() {
      return new Promise((resolve) => {
        let csv = "\ufeff";
        api
          .getRanking({
            campaign_id: this.$route.params.id,
            start: this.date[0],
            end: this.date[1],
          })
          .then((response) => {
            csv += response["data"];
            let blob = new Blob([csv], { type: "text/csv" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "sociama_ranking.csv";
            link.click();
            this.$toast.show("ランキングファイルが完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("ランキングファイルの取得に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    getTweetsPromise() {
      return new Promise((resolve) => {
        api
          .getCampaignTweets({
            id: this.$route.params.id,
          })
          .then((response) => {
            // this.tweets = response["data"];
            console.log(this.tweets);
            this.tweets_items = [];
            for (let i = 0; i < response["data"].length; i++) {
              this.tweets_items.push({
                username: response["data"][i]["username"],
                user_id: String(response["data"][i]["user_id"]),
                tweet_id: String(response["data"][i]["tweet_id"]),
                content: response["data"][i]["content"],
                like: response["data"][i]["like"],
                retweet: response["data"][i]["retweet"],
                reply: response["data"][i]["reply"],
                impression: response["data"][i]["impression"],
                created_at: response["data"][i]["tweet_created_at"],
              });
            }
            this.$toast.show("Postの取得に成功しました。", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("Postの取得に失敗しました。", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
  },
};
</script>

<style>
.customize-table {
  --easy-table-border: 1px solid #445f69;
  --easy-table-row-border: 1px solid #c5c9ce;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: #c1cad4;
  --easy-table-header-background-color: #0869a1;

  --easy-table-header-item-padding: 10px 15px;

  --easy-table-footer-background-color: #0869a1;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-loading-mask-background-color: #2d3a4f;
}
</style>
