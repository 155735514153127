<template>
  <section class="pt-6 bg-white overflow-hidden px-4">
    <div class="container w-auto">
      <div class="pb-20 border-b border-t pt-12">
        <div class="flex justify-center -m-8">
          <div class="w-1/4 p-8">
            <h3 class="mb-6 font-semibold leading-normal">Company</h3>
            <ul>
              <li class="mb-3.5">
                <router-link to="/about-us">
                  <a
                    class="text-gray-600 hover:text-gray-700 text-xs md:text-base leading-relaxed"
                    >About Us
                  </a>
                </router-link>
              </li>
              <li class="mb-3.5">
                <a
                  class="text-gray-600 hover:text-gray-700 text-xs md:text-base leading-relaxed"
                  href="https://corp.social-marketing.io/news"
                >
                  News
                </a>
              </li>
              <li>
                <a
                  class="text-gray-600 hover:text-gray-700 text-xs md:text-base leading-relaxed"
                  href="https://corp.social-marketing.io/blog"
                >
                  Blog
                </a>
              </li>
            </ul>
          </div>
          <div class="w-1/4 p-8">
            <h3 class="mb-6 font-semibold leading-normal">Pages</h3>
            <ul>
              <li class="mb-3.5">
                <router-link to="/login">
                  <p
                    class="text-gray-600 hover:text-gray-700 text-xs md:text-base leading-relaxed"
                  >
                    ログイン
                  </p>
                </router-link>
              </li>
              <li class="mb-3.5">
                <router-link to="/term-of-service">
                  <p
                    class="text-gray-600 hover:text-gray-700 text-xs md:text-base leading-relaxed"
                    href="#"
                  >
                    利用規約
                  </p>
                </router-link>
              </li>
              <li class="mb-3.5">
                <router-link to="/privacy-policy">
                  <p
                    class="text-gray-600 hover:text-gray-700 text-xs md:text-base leading-relaxed"
                  >
                    プライバシーポリシー
                  </p>
                </router-link>
              </li>
              <li>
                <router-link to="/special-commercial-law">
                  <p
                    class="text-gray-600 hover:text-gray-700 text-xs md:text-base leading-relaxed"
                  >
                    特定商取引法に基づく表記
                  </p>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="w-1/4 p-8">
            <h3 class="mb-6 font-semibold leading-normal">Support</h3>
            <ul>
              <li class="mb-3.5">
                <a
                  class="text-gray-600 text-xs md:text-base leading-relaxed"
                  href="https://quick-nebula-353.notion.site/d6913178b00640c2b5e9925d5682544d"
                >
                  FAQ
                </a>
              </li>
              <li class="mb-3.5">
                <p class="text-gray-600 text-xs md:text-base leading-relaxed">
                  Mail: support@social-marketing.io
                </p>
              </li>
              <li class="mb-3.5">
                <a
                  class="text-gray-600 text-xs md:text-base leading-relaxed"
                  href="https://social-marketing.io/contact"
                >
                  お問合せ
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="flex flex-wrap justify-between items-center py-6 -m-4 md:px-48"
      >
        <div class="flex items-center p-4">
          <a href="https://social-marketing.io"
            ><img
              class="w-18 md:w-48"
              src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/logo_color_socialmarketing.png"
              alt="socialmarketing.inc"
            />
          </a>
        </div>
        <div class="w-auto py-4">
          <p class="text-gray-600 text-xs md:text-base">
            Copyright © 2023 Social Marketing Inc. All Rights Reserved
          </p>
        </div>
        <div class="w-auto p-4">
          <div class="flex flex-wrap">
            <a
              class="text-blueGray-400 hover:text-blueGray-500"
              href="https://twitter.com/Sociama"
            >
              <svg
                width="15"
                height="13"
                viewbox="0 0 15 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.0005 2.27494C14.4603 2.50797 13.8724 2.67569 13.2669 2.74101C13.8956 2.36751 14.3664 1.77744 14.591 1.08151C14.001 1.43246 13.3547 1.67855 12.6808 1.80887C12.3991 1.50773 12.0584 1.26784 11.68 1.10413C11.3015 0.940414 10.8934 0.856396 10.4811 0.857307C8.81277 0.857307 7.47106 2.20962 7.47106 3.86911C7.47106 4.10214 7.49931 4.33518 7.54521 4.55938C5.04715 4.42874 2.8192 3.23532 1.33802 1.40812C1.06813 1.86909 0.9267 2.39397 0.928441 2.92814C0.928441 3.97327 1.45983 4.89481 2.27015 5.4368C1.79262 5.41799 1.32626 5.28673 0.909022 5.0537V5.09077C0.909022 6.5543 1.94355 7.76714 3.32234 8.04608C3.06346 8.11332 2.79714 8.14773 2.52967 8.14847C2.33371 8.14847 2.14834 8.12905 1.96121 8.10257C2.34254 9.29599 3.45298 10.1628 4.77528 10.1911C3.74074 11.0014 2.44493 11.478 1.0379 11.478C0.785443 11.478 0.552408 11.4692 0.310547 11.441C1.6452 12.2972 3.22877 12.7915 4.93416 12.7915C10.4705 12.7915 13.4999 8.20497 13.4999 4.22395C13.4999 4.09331 13.4999 3.96267 13.4911 3.83203C14.0772 3.40304 14.591 2.87165 15.0005 2.27494Z"
                  fill="currentColor"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
