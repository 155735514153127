<template>
  <div class="factory-summary">
    <!-- main div -->
    <div class="px-4 pb-20">
      <!-- サマリー表示部分 -->
      <div v-if="activeTab === 'summary'">
        <!-- 死活監視サマリー -->
        <div class="summary-card mb-6">
          <h2 class="text-lg text-gray-100 font-semibold mb-3">死活監視</h2>
          <div class="flex justify-between">
            <div class="status-count status-green">
              <div class="status-label">正常</div>
              <div class="status-number">{{ statusCounts.green }}</div>
            </div>
            <div class="status-count status-orange">
              <div class="status-label">注意</div>
              <div class="status-number">{{ statusCounts.orange }}</div>
            </div>
            <div class="status-count status-red">
              <div class="status-label">異常</div>
              <div class="status-number">{{ statusCounts.red }}</div>
            </div>
          </div>
        </div>

        <!-- 日別データ -->
        <div class="summary-card mb-6">
          <h2 class="text-lg text-gray-100 font-semibold mb-3">
            直近24時間の実行回数
          </h2>

          <!-- 累計実行回数を表示するカード -->
          <div class="total-execution-card mb-3">
            <span class="total-label">累計実行回数:</span>
            <span class="total-number">{{ dailyTotalCount }}</span>
          </div>

          <div class="chart-container" v-if="isDailyChartReady">
            <apexchart
              type="bar"
              height="300"
              :options="dailyChartOptions"
              :series="dailyChartSeries"
            ></apexchart>
          </div>
          <div v-else class="loading-message">
            <p>データを読み込み中...</p>
          </div>
        </div>

        <!-- 月別データ -->
        <div class="summary-card">
          <h2 class="text-lg text-gray-100 font-semibold mb-3">
            直近30日間の実行回数
          </h2>
          <div class="chart-container" v-if="isMonthlyChartReady">
            <apexchart
              type="bar"
              height="300"
              :options="monthlyChartOptions"
              :series="monthlyChartSeries"
            ></apexchart>
          </div>
          <div v-else class="loading-message">
            <p>データを読み込み中...</p>
          </div>
        </div>
      </div>

      <!-- 死活監視詳細表示部分 -->
      <div v-if="activeTab === 'status'">
        <div class="status-summary mb-4">
          <h2 class="text-lg text-gray-100 font-semibold mb-3">
            端末ステータス一覧
          </h2>
          <div class="status-grid">
            <div
              v-for="device in statusData"
              :key="device.device_name"
              class="status-tile"
              :class="getStatusClass(device.status)"
              @click="selectedDevice = device"
            >
              <div class="status-tile-inner">
                {{ device.device_name }}
              </div>
            </div>
          </div>
        </div>

        <!-- 選択された端末の詳細情報 -->
        <div v-if="selectedDevice" class="device-detail">
          <div class="card-header">
            <h3 class="device-name">{{ selectedDevice.device_name }}</h3>
            <div
              class="status-badge"
              :class="getStatusClass(selectedDevice.status)"
            >
              {{ getStatusText(selectedDevice.status) }}
            </div>
          </div>
          <div class="card-body">
            <p class="last-execution">
              最終実行: {{ formatDateTime(selectedDevice.last_execution) }}
            </p>
            <div class="targets">
              <h4>ターゲット別データ数</h4>
              <div
                v-for="(count, target) in selectedDevice.targets"
                :key="target"
                class="target-item"
              >
                <span class="target-name">{{ target }}:</span>
                <span class="target-count">{{ count }}</span>
              </div>
              <div
                v-if="Object.keys(selectedDevice.targets).length === 0"
                class="no-data"
              >
                データがありません
              </div>
            </div>
          </div>
        </div>

        <div v-else class="no-device-selected">端末をタップして詳細を表示</div>

        <div v-if="statusData.length === 0" class="no-data-message">
          データが読み込まれていないか、存在しません
        </div>
      </div>

      <!-- ナビゲーションタブ -->
      <div
        class="bottom-navigation"
        :style="{
          width: navigationWidth + '%',
          left: (100 - navigationWidth) / 2 + '%',
        }"
      >
        <button
          @click="activeTab = 'summary'"
          :class="{ active: activeTab === 'summary' }"
        >
          <i class="pi pi-chart-bar"></i>
          <span>サマリー</span>
        </button>
        <button
          @click="activeTab = 'status'"
          :class="{ active: activeTab === 'status' }"
        >
          <i class="pi pi-server"></i>
          <span>死活監視</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import api from "../api/api.js";

export default {
  name: "FactorySummary",
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      activeTab: "summary",
      statusData: [],
      dailyData: [],
      monthlyData: [],
      selectedDevice: null,
      statusCounts: {
        green: 0,
        orange: 0,
        red: 0,
      },
      navigationWidth: 50, // デフォルトは画面の50%
      isDailyChartReady: false,
      isMonthlyChartReady: false,
      refreshInterval: null,
      isLoading: true,
      dailyTotalCount: 0, // 累計実行回数を保持する変数

      // 日別チャートの設定
      dailyChartSeries: [],
      dailyChartOptions: {
        chart: {
          type: "bar",
          stacked: true,
          stackType: "normal",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "85%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        colors: [
          "#008FFB",
          "#00E396",
          "#FEB019",
          "#FF4560",
          "#775DD0",
          "#3F51B5",
          "#546E7A",
          "#D4526E",
          "#8D5B4C",
          "#F86624",
        ],
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: "#C4BBAF",
            },
            formatter: function (val) {
              // UTC時刻をJSTに変換（+9時間）
              const timeStr = val.split(" ")[1];
              const [hours, minutes] = timeStr.split(":");

              // 時間を数値に変換して9時間追加
              let jstHours = parseInt(hours) + 9;
              if (jstHours >= 24) {
                jstHours -= 24; // 24時以上なら0時からに戻す
              }

              // 2桁の時間形式に整形
              const formattedHours = jstHours.toString().padStart(2, "0");
              return `${formattedHours}:${minutes}`;
            },
          },
          axisBorder: {
            show: true,
            color: "#C4BBAF",
          },
          axisTicks: {
            show: true,
            color: "#C4BBAF",
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "#C4BBAF",
            },
          },
          title: {
            text: "時間ごとの実行回数",
            style: {
              color: "#C4BBAF",
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " 回";
            },
          },
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          labels: {
            colors: "#C4BBAF",
          },
        },
        grid: {
          borderColor: "#2D3748",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
      },

      // 月別チャートの設定
      monthlyChartSeries: [],
      monthlyChartOptions: {
        chart: {
          type: "bar",
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "85%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        colors: [
          "#008FFB",
          "#00E396",
          "#FEB019",
          "#FF4560",
          "#775DD0",
          "#3F51B5",
          "#546E7A",
          "#D4526E",
          "#8D5B4C",
          "#F86624",
        ],
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: "#C4BBAF",
            },
            formatter: function (val) {
              // 日付フォーマットを簡略化
              const parts = val.split("-");
              return parts[1] + "/" + parts[2];
            },
          },
          axisBorder: {
            show: true,
            color: "#C4BBAF",
          },
          axisTicks: {
            show: true,
            color: "#C4BBAF",
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "#C4BBAF",
            },
          },
          title: {
            text: "実行回数",
            style: {
              color: "#C4BBAF",
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " 回";
            },
          },
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          labels: {
            colors: "#C4BBAF",
          },
        },
        grid: {
          borderColor: "#2D3748",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
      },
    };
  },
  mounted() {
    this.fetchAllData();
    this.updateNavigationWidth();
    // リサイズイベントのリスナーを追加
    window.addEventListener("resize", this.updateNavigationWidth);
    // 5分ごとに自動更新
    this.refreshInterval = setInterval(() => {
      this.fetchAllData();
    }, 5 * 60 * 1000);
  },
  beforeUnmount() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
    // イベントリスナーを削除
    window.removeEventListener("resize", this.updateNavigationWidth);
  },
  methods: {
    async fetchAllData() {
      this.isLoading = true;
      try {
        await Promise.all([
          this.fetchStatusData(),
          this.fetchDailyData(),
          this.fetchMonthlyData(),
        ]);
      } catch (error) {
        console.error("データの取得中にエラーが発生しました:", error);
        this.$toast.show("データの取得中にエラーが発生しました。", {
          type: "error",
          position: "top-right",
          duration: 5000,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async fetchStatusData() {
      try {
        const response = await api.getAutoResultsStatus();
        this.statusData = response.data;

        // ステータスカウントをリセット
        this.statusCounts = {
          green: 0,
          orange: 0,
          red: 0,
        };

        // ステータスごとのカウントを計算
        this.statusData.forEach((device) => {
          this.statusCounts[device.status]++;
        });
      } catch (error) {
        console.error("ステータスデータの取得に失敗しました:", error);
        throw error;
      }
    },
    async fetchDailyData() {
      try {
        const response = await api.getAutoResultsDaily();
        this.dailyData = response.data;
        this.processDailyChartData();
      } catch (error) {
        console.error("日別データの取得に失敗しました:", error);
        throw error;
      }
    },
    async fetchMonthlyData() {
      try {
        const response = await api.getAutoResultsMonthly();
        this.monthlyData = response.data;
        this.processMonthlyChartData();
      } catch (error) {
        console.error("月別データの取得に失敗しました:", error);
        throw error;
      }
    },
    processDailyChartData() {
      // データが空の場合は処理をスキップ
      if (!this.dailyData || this.dailyData.length === 0) {
        this.isDailyChartReady = true;
        return;
      }

      // カテゴリ（時間帯）の取得
      const hourSlots = [
        ...new Set(this.dailyData.map((item) => item.hour_slot)),
      ].sort();
      this.dailyChartOptions.xaxis.categories = hourSlots;

      // ターゲットごとにデータをグループ化
      const targets = [...new Set(this.dailyData.map((item) => item.target))];

      // 時間スロットごとのターゲット別データを準備
      // 積み上げグラフ用にすべての系列をタイプ「bar」に設定
      const barSeries = targets.map((target) => {
        return {
          name: target,
          type: "bar", // すべて棒グラフ
          data: hourSlots.map((slot) => {
            const items = this.dailyData.filter(
              (item) => item.target === target && item.hour_slot === slot
            );
            return items.length > 0 ? items[0].count : 0;
          }),
        };
      });

      // 全データの合計を計算して累計表示用の変数にセット
      this.dailyTotalCount = this.dailyData.reduce(
        (total, item) => total + item.count,
        0
      );

      // シリーズをセット
      this.dailyChartSeries = barSeries;

      this.isDailyChartReady = true;
    },
    processMonthlyChartData() {
      // データが空の場合は処理をスキップ
      if (!this.monthlyData || this.monthlyData.length === 0) {
        this.isMonthlyChartReady = true;
        return;
      }

      // カテゴリ（日付）の取得
      const daySlots = [
        ...new Set(this.monthlyData.map((item) => item.day_slot)),
      ].sort();
      this.monthlyChartOptions.xaxis.categories = daySlots;

      // ターゲットごとにデータをグループ化
      const targets = [...new Set(this.monthlyData.map((item) => item.target))];

      // シリーズデータの準備
      this.monthlyChartSeries = targets.map((target) => {
        return {
          name: target,
          data: daySlots.map((slot) => {
            const items = this.monthlyData.filter(
              (item) => item.target === target && item.day_slot === slot
            );
            return items.length > 0 ? items[0].count : 0;
          }),
        };
      });

      this.isMonthlyChartReady = true;
    },
    updateNavigationWidth() {
      // 画面幅に基づいてナビゲーションの幅を設定
      const screenWidth = window.innerWidth;
      if (screenWidth < 640) {
        // スマホサイズ
        this.navigationWidth = 90;
      } else if (screenWidth < 1024) {
        // タブレットサイズ
        this.navigationWidth = 70;
      } else {
        // デスクトップサイズ
        this.navigationWidth = 50;
      }
    },
    getStatusClass(status) {
      switch (status) {
        case "green":
          return "status-green";
        case "orange":
          return "status-orange";
        case "red":
          return "status-red";
        default:
          return "";
      }
    },
    getStatusText(status) {
      switch (status) {
        case "green":
          return "正常";
        case "orange":
          return "注意";
        case "red":
          return "異常";
        default:
          return "不明";
      }
    },
    formatDateTime(dateTime) {
      if (!dateTime) return "データなし";
      const date = new Date(dateTime);
      return date.toLocaleString("ja-JP", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    },
  },
};
</script>

<style scoped>
.factory-summary {
  min-height: 100vh;
  background-color: #1a202c;
}

.summary-card {
  background-color: #2d3748;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.status-count {
  flex: 1;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
  margin: 0 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.status-count:first-child {
  margin-left: 0;
}

.status-count:last-child {
  margin-right: 0;
}

.status-green {
  background-color: rgba(72, 187, 120, 0.2);
  border: 1px solid #48bb78;
}

.status-orange {
  background-color: rgba(237, 137, 54, 0.2);
  border: 1px solid #ed8936;
}

.status-red {
  background-color: rgba(229, 62, 62, 0.2);
  border: 1px solid #e53e3e;
}

.status-label {
  font-size: 14px;
  color: #e2e8f0;
  margin-bottom: 8px;
}

.status-number {
  font-size: 24px;
  font-weight: bold;
  color: #f7fafc;
}

.total-execution-card {
  background-color: rgba(66, 153, 225, 0.2);
  border: 1px solid #4299e1;
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.total-label {
  font-size: 14px;
  color: #e2e8f0;
}

.total-number {
  font-size: 18px;
  font-weight: bold;
  color: #f7fafc;
}

.chart-container {
  height: 300px;
  position: relative;
}

.loading-message {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a0aec0;
}

.bottom-navigation {
  position: fixed;
  bottom: 0;
  background-color: #2d3748;
  display: flex;
  padding: 8px 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  border-radius: 8px 8px 0 0;
  transition: all 0.3s ease;
}

.bottom-navigation button {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  color: #a0aec0;
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
}

.bottom-navigation button.active {
  color: #4299e1;
}

.bottom-navigation button i {
  font-size: 20px;
  margin-bottom: 4px;
}

.bottom-navigation button span {
  font-size: 12px;
}

.status-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;
}

@media (min-width: 640px) {
  .status-grid {
    grid-template-columns: repeat(10, 1fr);
  }
}

@media (min-width: 1024px) {
  .status-grid {
    grid-template-columns: repeat(20, 1fr);
  }
}

.status-tile {
  aspect-ratio: 1/1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  overflow: hidden;
}

.status-tile:hover {
  transform: scale(1.05);
}

.status-tile-inner {
  font-weight: bold;
  color: white;
  font-size: 12px;
  text-align: center;
}

.status-tile.status-green {
  background-color: rgba(72, 187, 120, 0.7);
  border: 1px solid #48bb78;
}

.status-tile.status-orange {
  background-color: rgba(237, 137, 54, 0.7);
  border: 1px solid #ed8936;
}

.status-tile.status-red {
  background-color: rgba(229, 62, 62, 0.7);
  border: 1px solid #e53e3e;
}

.device-detail {
  background-color: #2d3748;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin-top: 16px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background-color: rgba(0, 0, 0, 0.1);
}

.device-name {
  font-size: 16px;
  font-weight: 600;
  color: #e2e8f0;
  margin: 0;
}

.status-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  color: white;
}

.status-badge.status-green {
  background-color: #48bb78;
}

.status-badge.status-orange {
  background-color: #ed8936;
}

.status-badge.status-red {
  background-color: #e53e3e;
}

.card-body {
  padding: 16px;
}

.last-execution {
  font-size: 14px;
  color: #cbd5e0;
  margin-top: 0;
  margin-bottom: 12px;
}

.targets h4 {
  font-size: 14px;
  color: #e2e8f0;
  margin-bottom: 8px;
}

.target-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}

.target-name {
  color: #cbd5e0;
  font-size: 14px;
}

.target-count {
  color: #f7fafc;
  font-weight: 600;
  font-size: 14px;
}

.no-data,
.no-data-message {
  color: #a0aec0;
  font-style: italic;
  font-size: 14px;
  text-align: center;
  padding: 20px 0;
}

.no-device-selected {
  text-align: center;
  padding: 20px;
  color: #a0aec0;
  font-style: italic;
  font-size: 14px;
  background-color: #2d3748;
  border-radius: 8px;
  margin-top: 16px;
}

/* スマホ向け最適化 */
@media (max-width: 640px) {
  .px-4 {
    padding-left: 12px;
    padding-right: 12px;
  }

  .status-count {
    padding: 12px 8px;
  }

  .status-label {
    font-size: 12px;
  }

  .status-number {
    font-size: 20px;
  }

  .chart-container {
    height: 250px;
  }
}
</style>
