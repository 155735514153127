<template>
  <div class="subscribe_plans">
    <SideVar />
    <RightColumnOutline>
      <PageTitle>Subscription Plan </PageTitle>>
      <div class="relative z-10 container px-8 mx-auto">
        <div class="flex justify-between">
          <p
            class="mb-10 text-gray-100 font-medium leading-normal text-left pl-4"
          >
            有料プランを登録して機能を解放しましょう<i class="pi pi-bolt"></i>
          </p>
          <a
            class="text-right text-blue-300 pr-6"
            href="https://billing.stripe.com/p/login/28o4hE0XH4IIdeE3cc"
            >履歴/領収書/解約等はこちら</a
          >
        </div>
        <div class="w-full mx-auto">
          <div class="flex flex-wrap -m-5">
            <div class="w-full md:w-1/3 py-5 px-4">
              <img
                src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/img_plan_01bronze.png"
              />
              <a
                class="mt-6 py-4 px-16 text-white font-semibold rounded-xl focus:ring focus:ring-blue-300 bg-blue-600 hover:bg-blue-700 transition ease-in-out duration-200"
                type="button"
                href="https://buy.stripe.com/28oaGcapdcpi34c8ww"
                v-if="plan == 'FREE'"
              >
                購入する
              </a>
              <p
                class="mt-6 py-4 px-16 text-white font-semibold rounded-xl focus:ring focus:ring-gray-300 bg-gray-600 transition ease-in-out duration-200"
                type="button"
                href="https://buy.stripe.com/28oaGcapdcpi34c8ww"
                v-tippy="{
                  content:
                    'プランを変更する際はお手数ですがメールにてお問合せください',
                }"
                v-else
              >
                変更
              </p>
            </div>
            <div class="w-full md:w-1/3 py-5 px-4">
              <img
                src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/img_plan_01silver.png"
              />
              <a
                class="mt-6 py-4 px-16 text-white font-semibold rounded-xl focus:ring focus:ring-blue-300 bg-blue-600 hover:bg-blue-700 transition ease-in-out duration-200"
                type="button"
                href="https://buy.stripe.com/dR63dK68XfBuawE3cd"
                v-if="plan == 'FREE'"
              >
                購入する
              </a>
              <p
                class="mt-6 py-4 px-16 text-white font-semibold rounded-xl focus:ring focus:ring-gray-300 bg-gray-600 transition ease-in-out duration-200"
                type="button"
                href="https://buy.stripe.com/28oaGcapdcpi34c8ww"
                v-tippy="{
                  content:
                    'プランを変更する際はお手数ですがメールにてお問合せください',
                }"
                v-else
              >
                変更
              </p>
            </div>
            <div class="w-full md:w-1/3 py-5 px-4">
              <img
                src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/img_plan_01gold.png"
              />
              <a
                class="mt-6 py-4 px-16 text-white font-semibold rounded-xl focus:ring focus:ring-blue-300 bg-blue-600 hover:bg-blue-700 transition ease-in-out duration-200"
                type="button"
                href="https://buy.stripe.com/eVa29G2WL0GAcEM3ce"
                v-if="plan == 'FREE'"
              >
                購入する
              </a>
              <p
                class="mt-6 py-4 px-16 text-white font-semibold rounded-xl focus:ring focus:ring-gray-300 bg-gray-600 transition ease-in-out duration-200"
                type="button"
                href="https://buy.stripe.com/28oaGcapdcpi34c8ww"
                v-tippy="{
                  content:
                    'プランを変更する際はお手数ですがメールにてお問合せください',
                }"
                v-else
              >
                変更
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="absolute bottom-0 left-0 w-full py-64 bg-indigo-600"></div>
    </RightColumnOutline>
  </div>
</template>

<script>
import SideVar from "@/components/SideVar.vue";
export default {
  name: "SubscribePlans",
  components: {
    SideVar,
  },
  computed: {
    plan: function () {
      return this.$store.getters["subscribePlan"];
    },
  },
};
</script>
