<template>
  <section class="sticky top-0 z-50">
    <div class="flex items-center justify-between bg-white h-20 px-4 md:px-24">
      <div class="w-auto">
        <a href="https://social-marketing.io">
          <img
            src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/logo_color_sociama.png"
            alt="logo"
            class="w-3/4"
          />
        </a>
      </div>
      <div class="w-auto hidden md:inline-block">
        <div class="inline-flex space-x-6 items-center justify-end h-11">
          <a
            href="https://social-marketing.io/#feature"
            class="text-xs font-bold text-gray-800"
          >
            特徴・メリット
          </a>
          <p class="text-xs font-bold text-gray-800">/</p>
          <a
            href="https://social-marketing.io/#plans"
            class="text-xs font-bold text-gray-800"
          >
            料金・プラン
          </a>
          <p class="text-xs font-bold text-gray-800">/</p>
          <a
            class="text-xs font-bold text-gray-800"
            href="https://social-marketing.io/#result"
            >効果・事例</a
          >
          <p class="text-xs font-bold text-gray-800">/</p>
          <router-link to="/contact">
            <p class="text-xs font-bold text-gray-800">お問い合わせ</p>
          </router-link>
          <router-link to="/company-detail">
            <div
              class="flex items-center justify-center w-48 px-12 py-3 bg-yellow-400 rounded"
            >
              <p class="text-xs font-bold text-gray-800">資料ダウンロード</p>
            </div>
          </router-link>
          <router-link to="/login">
            <div
              class="flex items-center justify-center w-48 px-12 py-3 border-2 rounded border-yellow-400"
            >
              <p class="text-xs font-bold text-gray-800">無料ではじめる</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>
