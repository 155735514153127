<template>
  <div class="container mx-auto p-4">
    <h1 class="text-2xl font-bold mb-4">クエスト一覧</h1>

    <div class="flex justify-between mb-4">
      <input
        v-model="searchQuery"
        class="border p-2 rounded w-1/3"
        placeholder="クエスト名で検索..."
      />
      <button
        @click="navigateToQuizAnswer"
        class="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600"
      >
        クイズの正解を入力
      </button>
      <button
        @click="navigateToCreateQuest"
        class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
      >
        新規クエスト作成
      </button>
    </div>

    <EasyDataTable
      :headers="headers"
      :items="quests"
      :search-value="searchQuery"
      :sort-by="sortBy"
      :sort-type="sortType"
      :rows-per-page="15"
      :rows-items="[15, 25, 50, 100]"
      buttons-pagination
      @click-row="handleRowClick"
      @sort="handleSort"
    >
      <template #item-name="item">
        <input
          :value="item.name"
          @input="updateField(item, 'name', $event.target.value)"
          class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
        />
      </template>
      <template #item-description="item">
        <input
          :value="item.description"
          @input="updateField(item, 'description', $event.target.value)"
          class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
        />
      </template>
      <template #item-content_type="item">
        <select
          :value="item.content_type"
          @change="updateField(item, 'content_type', $event.target.value)"
          class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
        >
          <option value="INSTANTWIN">INSTANTWIN</option>
          <option value="X_FOLLOW">X_FOLLOW</option>
          <option value="INSTAGRAM_FOLLOW">INSTAGRAM_FOLLOW</option>
          <option value="VOTING">VOTING</option>
          <option value="QUIZ">QUIZ</option>
          <option value="TIKTOK_FOLLOW">TIKTOK_FOLLOW</option>
          <option value="TIKTOK_VIDEO">TIKTOK_VIDEO</option>
        </select>
      </template>
      <template #item-frequency_type="item">
        <select
          :value="item.frequency_type"
          @change="updateField(item, 'frequency_type', $event.target.value)"
          class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
        >
          <option value="ONCE">ONCE</option>
          <option value="UNLIMITED">UNLIMITED</option>
          <option value="DAILY">DAILY</option>
        </select>
      </template>
      <template #item-start_date="item">
        <input
          type="datetime-local"
          :value="item.start_date"
          @input="updateField(item, 'start_date', $event.target.value)"
          class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
        />
      </template>
      <template #item-end_date="item">
        <input
          type="datetime-local"
          :value="item.end_date"
          @input="updateField(item, 'end_date', $event.target.value)"
          class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
        />
      </template>
      <template #item-point_amount="item">
        <input
          type="number"
          :value="item.point_amount"
          @input="
            updateField(item, 'point_amount', Number($event.target.value))
          "
          class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
        />
      </template>
      <template #item-priority="item">
        <input
          type="number"
          :value="item.priority"
          @input="updateField(item, 'priority', Number($event.target.value))"
          class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
        />
      </template>
      <template #item-available_count="item">
        <input
          type="number"
          :value="item.available_count"
          @input="
            updateField(item, 'available_count', Number($event.target.value))
          "
          class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
        />
      </template>
      <template #item-visible="item">
        <input
          type="checkbox"
          :checked="item.visible"
          @change="updateField(item, 'visible', $event.target.checked)"
        />
      </template>
      <template #item-actions="item">
        <button
          @click="updateQuest(item)"
          class="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600"
        >
          更新
        </button>
        <button
          @click="confirmDelete(item)"
          class="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
        >
          削除
        </button>
      </template>
    </EasyDataTable>
  </div>
</template>

<script>
import { ref, onMounted, inject } from "vue";
import { useRouter } from "vue-router";
import api from "../api/api.js";

export default {
  setup() {
    const router = useRouter();
    const quests = ref([]);
    const toast = inject("toast");
    const searchQuery = ref("");
    const sortBy = ref("quest_id");
    const sortType = ref("asc");

    const headers = [
      { text: "ID", value: "quest_id", width: 80, sortable: true },
      { text: "名前", value: "name", width: 240, sortable: true },
      { text: "説明", value: "description", width: 360, sortable: true },
      {
        text: "コンテンツタイプ",
        value: "content_type",
        width: 150,
        sortable: true,
      },
      {
        text: "頻度タイプ",
        value: "frequency_type",
        width: 150,
        sortable: true,
      },
      { text: "開始日時", value: "start_date", width: 180, sortable: true },
      { text: "終了日時", value: "end_date", width: 180, sortable: true },
      {
        text: "ポイントタイプ",
        value: "point_type",
        width: 150,
        sortable: true,
      },
      { text: "ポイント量", value: "point_amount", width: 120, sortable: true },
      { text: "表示", value: "visible", width: 80, sortable: true },
      { text: "優先度", value: "priority", width: 100, sortable: true },
      {
        text: "利用可能回数",
        value: "available_count",
        width: 150,
        sortable: true,
      },
      { text: "操作", value: "actions", width: 100 },
    ];

    const fetchQuests = async () => {
      try {
        const response = await api.getQuests();
        quests.value = response.data.map((quest) => ({
          ...quest,
          _original: { ...quest },
        }));
      } catch (error) {
        console.error("クエストの取得に失敗しました:", error);
        toast.error("クエストの取得に失敗しました。", {
          position: "top-right",
          duration: 5000,
        });
      }
    };

    const updateField = (item, field, value) => {
      const index = quests.value.findIndex((q) => q.quest_id === item.quest_id);
      if (index !== -1) {
        quests.value[index] = {
          ...quests.value[index],
          [field]: value,
        };
      }
      console.log(`Updated ${field} for quest ${item.quest_id}:`, value);
    };

    const updateQuest = async (quest) => {
      try {
        const updatedQuest = { ...quest };
        delete updatedQuest._original;
        console.log("Sending updated quest:", updatedQuest);
        await api.updateQuest(updatedQuest);
        toast.success("クエストが更新されました", {
          position: "top-right",
          duration: 5000,
        });
        const index = quests.value.findIndex(
          (q) => q.quest_id === quest.quest_id
        );
        if (index !== -1) {
          quests.value[index]._original = { ...updatedQuest };
        }
      } catch (error) {
        console.error("クエストの更新に失敗しました:", error);
        toast.error("クエストの更新に失敗しました。", {
          position: "top-right",
          duration: 5000,
        });
        const index = quests.value.findIndex(
          (q) => q.quest_id === quest.quest_id
        );
        if (index !== -1) {
          quests.value[index] = { ...quests.value[index]._original };
        }
      }
    };

    const confirmDelete = async (quest) => {
      if (
        window.confirm(
          `本当にクエスト「${quest.name}」を削除してよろしいですか？`
        )
      ) {
        try {
          await api.deleteQuest(quest.quest_id);
          toast.success("クエストが削除されました", {
            position: "top-right",
            duration: 5000,
          });
          quests.value = quests.value.filter(
            (q) => q.quest_id !== quest.quest_id
          );
        } catch (error) {
          console.error("クエストの削除に失敗しました:", error);
          toast.error("クエストの削除に失敗しました。", {
            position: "top-right",
            duration: 5000,
          });
        }
      }
    };

    const navigateToQuizAnswer = () => {
      router.push("/quiz-answer"); // クイズ正解入力ページへのルートを適切に設定してください
    };

    const handleRowClick = (item) => {
      console.log("Clicked row:", item);
    };

    const handleSort = (event) => {
      sortBy.value = event.sortBy;
      sortType.value = event.sortType;
    };

    const navigateToCreateQuest = () => {
      router.push("/create-quest");
    };

    onMounted(fetchQuests);

    return {
      quests,
      searchQuery,
      headers,
      sortBy,
      sortType,
      updateQuest,
      updateField,
      handleRowClick,
      handleSort,
      navigateToCreateQuest,
      confirmDelete,
      navigateToQuizAnswer,
    };
  },
};
</script>
