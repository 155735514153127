import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    user: null,
    idToken: null,
    organizerId: null,
    subscribePlan: null,
    oauthAccessToken: null,
    oauthTokenSecret: null,
    userId: null,
    discordMenuActive: false,
    smallCampaignCount: 0,
    bigCampaignCount: 0,
    viewSwitch: "HASHTAG",
  },
  getters: {
    user(state) {
      return state.user;
    },
    idToken(state) {
      return state.idToken;
    },
    organizerId(state) {
      return state.organizerId;
    },
    subscribePlan(state) {
      return state.subscribePlan;
    },
    oauthAccessToken(state) {
      return state.oauthAccessToken;
    },
    oauthTokenSecret(state) {
      return state.oauthTokenSecret;
    },
    userId(state) {
      return state.userId;
    },
    discordMenuActive(state) {
      return state.discordMenuActive;
    },
    smallCampaignCount(state) {
      return state.smallCampaignCount;
    },
    bigCampaignCount(state) {
      return state.bigCampaignCount;
    },
    viewSwitch(state) {
      return state.viewSwitch;
    },
  },
  mutations: {
    updateUser(state, user_info) {
      state.user = user_info;
    },
    updateidToken(state, token) {
      state.idToken = token;
    },
    updateOrganizer(state, organizerId) {
      state.organizerId = organizerId;
    },
    updateSubscribePlan(state, subscribePlan) {
      state.subscribePlan = subscribePlan;
    },
    updateoauthAccessToken(state, Token) {
      state.oauthAccessToken = Token;
    },
    updateoauthAccessSecret(state, Secret) {
      state.oauthTokenSecret = Secret;
    },
    updateuserId(state, id) {
      state.userId = id;
    },
    updatediscordMenuActive(state, bool) {
      state.discordMenuActive = bool;
    },
    updatesmallCampaignCount(state, num) {
      state.smallCampaignCount = num;
    },
    updatebigCampaignCount(state, num) {
      state.bigCampaignCount = num;
    },
    updateviewSwitch(state, view) {
      state.viewSwitch = view;
    },
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      // ストレージのキーを指定
      key: "sociama-organizer",
      // ストレージの種類を指定
      // storage: window.sessionStorage,
    }),
  ],
});
