<template>
  <div class="container mx-auto p-4 flex justify-center">
    <div class="w-full max-w-2xl">
      <h1 class="text-2xl font-bold mb-4">新規お知らせ作成</h1>
      <form @submit.prevent="createAnnouncement" class="space-y-4">
        <div>
          <label for="title" class="block mb-1">タイトル</label>
          <input
            id="title"
            v-model="announcement.title"
            type="text"
            class="w-full p-2 border rounded"
            required
          />
        </div>
        <div>
          <label for="content" class="block mb-1">内容</label>
          <textarea
            id="content"
            v-model="announcement.content"
            class="w-full p-2 border rounded"
            rows="5"
            required
          ></textarea>
        </div>
        <div>
          <label for="target_audience" class="block mb-1">対象者</label>
          <select
            id="target_audience"
            v-model="announcement.target_audience"
            class="w-full p-2 border rounded"
            required
          >
            <option value="ALL">全員</option>
            <!-- 他の対象者オプションを必要に応じて追加 -->
          </select>
        </div>
        <div>
          <label for="published_at" class="block mb-1"
            >投稿予約(JST)(すぐ投稿する場合は変更不要）</label
          >
          <input
            id="published_at"
            v-model="announcement.published_at"
            type="datetime-local"
            class="w-full p-2 border rounded"
          />
        </div>
        <div>
          <label for="expiration_datetime" class="block mb-1">期限(JST)</label>
          <input
            id="expiration_datetime"
            v-model="announcement.expiration_datetime"
            type="datetime-local"
            class="w-full p-2 border rounded"
          />
        </div>
        <div>
          <AnnouncementHeaderImageUpload
            v-model:headerUrl="announcement.header_url"
          />
        </div>
        <div>
          <button
            type="submit"
            class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            :disabled="isLoading"
          >
            {{ isLoading ? "作成中..." : "お知らせを作成" }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, inject } from "vue";
import { useRouter } from "vue-router";
import api from "../api/api.js";
import AnnouncementHeaderImageUpload from "./AnnouncementImageUpload.vue";

export default {
  components: {
    AnnouncementHeaderImageUpload,
  },
  setup() {
    const router = useRouter();
    const toast = inject("toast");
    const isLoading = ref(false);

    // 現在のJST時刻を取得してデフォルト値として設定
    const now = new Date();
    const jstOffset = 9 * 60 * 60 * 1000; // JST offset in milliseconds
    const jstNow = new Date(now.getTime() + jstOffset);
    const defaultDateTime = jstNow.toISOString().slice(0, 16); // YYYY-MM-DDThh:mm 形式に変換

    const announcement = ref({
      title: "",
      content: "",
      target_audience: "ALL",
      published_at: defaultDateTime,
      expiration_datetime: "",
      header_url: null,
    });

    const createAnnouncement = async () => {
      if (!validateAnnouncement()) {
        return;
      }

      isLoading.value = true;
      try {
        const announcementData = { ...announcement.value };

        // 投稿予約時刻をJSTからUTCに変換
        if (announcementData.published_at) {
          const publishDate = new Date(announcementData.published_at);
          announcementData.published_at = publishDate.toISOString();
        }

        // 期限をJSTからUTCに変換
        if (announcementData.expiration_datetime) {
          const expirationDate = new Date(announcementData.expiration_datetime);
          announcementData.expiration_datetime = expirationDate.toISOString();
        }

        const response = await api.createAnnouncement(announcementData);
        console.log("Created announcement:", response);

        toast.success("お知らせが正常に作成されました", {
          position: "top-right",
          duration: 5000,
        });
        router.push("/announcements");
      } catch (error) {
        console.error("お知らせの作成に失敗しました:", error);
        toast.error(error.message || "お知らせの作成に失敗しました", {
          position: "top-right",
          duration: 5000,
        });
      } finally {
        isLoading.value = false;
      }
    };

    const validateAnnouncement = () => {
      if (!announcement.value.title || !announcement.value.content) {
        toast.error("タイトルと内容は必須項目です", {
          position: "top-right",
          duration: 5000,
        });
        return false;
      }

      const now = new Date();

      // 期限のバリデーション
      if (announcement.value.expiration_datetime) {
        const expirationDate = new Date(announcement.value.expiration_datetime);
        const publishDate = new Date(announcement.value.published_at);

        if (expirationDate <= now) {
          toast.error("期限は現在時刻より後に設定してください", {
            position: "top-right",
            duration: 5000,
          });
          return false;
        }

        if (expirationDate <= publishDate) {
          toast.error("期限は投稿予約時刻より後に設定してください", {
            position: "top-right",
            duration: 5000,
          });
          return false;
        }
      }

      return true;
    };

    return {
      announcement,
      isLoading,
      createAnnouncement,
    };
  },
};
</script>
