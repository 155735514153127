<template>
  <div class="mailsent">
    <section class="relative pt-12">
      <div class="container px-4 mx-auto">
        <div class="h-full">
          <div class="relative max-w-2xl mx-auto text-center">
            <a
              class="w-24 inline-block mx-auto mb-10"
              href="https://social-marketing.io"
            >
              <img
                class="block"
                src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/sociama_01@4x.png"
              />
            </a>
            <h2 class="text-xl text-gray-500 font-semibold mb-2">
              <p>ご登録いただいたアドレスに認証メールを送付いたしました。</p>
            </h2>
          </div>
        </div>
      </div>
      <button
        class="container mx-auto block max-w-xs mt-12 py-4 mb-4 leading-6 text-white font-semibold bg-blue-500 hover:bg-blue-600 rounded-lg transition duration-200"
        @click="backtologin"
      >
        ログインページに戻る
      </button>
    </section>
  </div>
</template>

<script>
export default {
  methods: {
    backtologin: function () {
      this.$router.replace("/login");
    },
  },
};
</script>
