<template>
  <div class="campaignAddOptions">
    <SideVar />
    <RightColumnOutline>
      <PageTitle> Campaign Setting </PageTitle>
      <h4
        class="text-xl font-bold tracking-wide text-white mb-5 text-left pl-4"
      >
        スコアブースト
      </h4>
      <div class="flex flex-wrap items-center pb-8 mb-8">
        <div class="w-1/3 pl-18 text-left">
          <span class="text-sm font-medium text-gray-100"
            >スコアブースト倍率
          </span>
        </div>
        <div class="w-2/3 px-4 max-w-xl">
          <div class="flex flex-wrap items-center w-full px-3">
            <input
              class="block py-4 px-3 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
              type="text"
              v-model="multipleVulue"
              placeholder="2.0"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-wrap items-center pb-8">
        <div class="w-1/3 pl-18 text-left">
          <span class="text-sm font-medium text-gray-100"
            >スコアブースト期間
          </span>
        </div>
        <div class="w-2/3 px-4 max-w-xl">
          <div class="flex flex-wrap items-center -mx-3 w-full px-3">
            <div class="relative block px-3 w-full text-gray-100">
              <Datepicker
                v-model="date"
                range
                :min-date="min_date"
                :max-date="max_date"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="my-6">
        <DoButton
          :clickFunction="createMultiplePromise"
          :values="{}"
          :disabled="!boostfilled"
          >登録</DoButton
        >
      </div>

      <div class="flex flex-wrap items-center pb-8 mb-8">
        <div class="w-1/3 pl-18 text-left">
          <span class="text-sm font-medium text-gray-100"
            >登録済みスコアブースト
          </span>
        </div>
        <div class="w-2/3 px-4">
          <table class="w-full min-w-max">
            <thead>
              <tr class="text-left">
                <th class="p-0">
                  <div class="py-3 px-6 rounded-l-xl bg-gray-600">
                    <span class="text-xs text-gray-300 font-semibold"
                      >倍率</span
                    >
                  </div>
                </th>
                <th class="p-0">
                  <div class="py-3 px-6 bg-gray-600">
                    <span class="text-xs text-gray-300 font-semibold"
                      >START</span
                    >
                  </div>
                </th>
                <th class="p-0">
                  <div class="py-3 px-6 bg-gray-600">
                    <span class="text-xs text-gray-300 font-semibold">END</span>
                  </div>
                </th>
                <th class="p-0">
                  <div class="py-6 px-6 bg-gray-600 rounded-r-xl">
                    <span class="text-xs text-gray-300 font-semibold"> </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="multi in multipleRecords"
                v-bind:key="multi.multiplied_score_id"
              >
                <td class="p-0">
                  <div class="flex items-center h-16 px-6">
                    <span class="text-sm text-gray-100 font-medium">{{
                      multi.times
                    }}</span>
                  </div>
                </td>
                <td class="p-0">
                  <div class="flex items-center h-16 px-6">
                    <div class="flex h-full items-center">
                      <span class="text-sm font-medium text-gray-100">{{
                        multi.start_time
                      }}</span>
                    </div>
                  </div>
                </td>
                <td class="p-0">
                  <div class="flex items-center h-16 px-6">
                    <span class="text-sm text-gray-100 font-medium">{{
                      multi.end_time
                    }}</span>
                  </div>
                </td>
                <td class="p-0">
                  <BackButton
                    :clickFunction="deleteMultiplePromise"
                    :values="multi.multiplied_score_id"
                    ><i class="pi pi-trash"></i
                  ></BackButton>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </RightColumnOutline>
  </div>
</template>

<script>
import SideVar from "@/components/SideVar.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import api from "../api/api.js";
import { useRoute } from "vue-router";
import moment from "moment";

export default {
  name: "Campaign_regit",
  components: {
    SideVar,
    Datepicker,
  },
  mounted() {
    const route = useRoute();
    api
      .getCampaign({ id: route.params.id })
      .then((response) => {
        this.campaign_id = route.params.id;

        let tmp_min = null;
        let tmp_max = null;
        tmp_min = new Date(response.data[0]["start_time"]);
        tmp_min.setHours(tmp_min.getHours() + 9);
        this.min_date = tmp_min;
        tmp_max = new Date(response.data[0]["end_time"]);
        tmp_max.setHours(tmp_max.getHours() + 9);
        this.max_date = tmp_max;
        this.date = [tmp_min, tmp_max];
      })
      .catch((error) => {
        console.log("error: ", error);
      });
    this.getMultiple();
  },
  data() {
    return {
      date: null,
      campaign_id: null,
      multipleVulue: null,
      min_date: null,
      max_date: null,
      multipleRecords: null,
    };
  },
  computed: {
    boostfilled() {
      return this.date && this.multipleVulue;
    },
  },
  methods: {
    createMultiplePromise: function () {
      return new Promise((resolve) => {
        api
          .createMultiple({
            campaign_id: this.$route.params.id,
            times: Number(this.multipleVulue),
            start_time: this.date[0],
            end_time: this.date[1],
          })
          .then(() => {
            this.getMultiple();
            this.$toast.show("スコアブーストの登録が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("スコアブーストの登録に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    getMultiple: function () {
      api
        .getMultiple({ id: this.$route.params.id })
        .then((response) => {
          this.multipleRecords = response.data;
          for (var i in this.multipleRecords) {
            let start_date = new Date(this.multipleRecords[i]["start_time"]);
            start_date.setHours(start_date.getHours() + 9); // 日本時間に変更
            let end_date = new Date(this.multipleRecords[i]["end_time"]);
            end_date.setHours(end_date.getHours() + 9); // 日本時間に変更
            this.multipleRecords[i]["start_time"] = moment(start_date).format(
              "YYYY-MM-DD HH:mm:SS"
            );
            this.multipleRecords[i]["end_time"] = moment(end_date).format(
              "YYYY-MM-DD HH:mm:SS"
            );
          }
        })
        .catch((error) => {
          this.$toast.show("スコアブーストの取得に失敗しました", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
          console.log("error: ", error);
        });
    },
    deleteMultiplePromise: function (multiplied_score_id) {
      return new Promise((resolve) => {
        this.delete_loading = true;
        api
          .deleteMultiple({
            multiplied_score_id: multiplied_score_id,
          })
          .then(() => {
            this.$toast.show("スコアブーストの削除が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });

            this.getMultiple();
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("スコアブーストの削除に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });

            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
  },
};
</script>
