<template>
  <div class="campaign_analize">
    <!-- side var  -->
    <SideVar />
    <!-- main div -->
    <RightColumnOutline>
      <PageTitle>Campaign Analisis</PageTitle>
      <div class="px-6">
        <div class="my-8 text-white text-xl text-left">
          Campaign name: {{ campaign_name }}
        </div>
        <div class="text-left mt-3">
          <div class="flex flex-wrap items-center mx-2 pb-8">
            <div class="w-1/4 px-4 mb-0">
              <span class="text-sm font-medium text-gray-100"
                >キャンペーンURL
                <i
                  class="pi pi-question-circle mr-1"
                  v-tippy="{
                    content:
                      '利用者がキャンペーン参加条件を確認するページのURLです。キャンペーンを告知する際にご利用ください',
                  }"
                ></i>
              </span>
            </div>
            <div class="text-white text-sm">
              https://app.social-marketing.io/action-raffle/{{ campaign_id }}
            </div>
          </div>
          <div class="flex flex-wrap items-center mx-2 pb-8">
            <div class="w-1/4 px-4 mb-0">
              <span class="text-sm font-medium text-gray-100"
                >当選受取URL
                <i
                  class="pi pi-question-circle mr-1"
                  v-tippy="{
                    content:
                      '当選者がギフトコードを受け取るページのURLです。キャンペーン参加者に告知をお願いします。',
                  }"
                ></i>
              </span>
            </div>
            <div class="text-white text-sm">
              https://app.social-marketing.io/
            </div>
          </div>
          <div
            class="text-lg text-gray-100 font-semibold mb-6 mt-6 ml-6 flex items-center justify-left"
          >
            <div class="w-1/2 mr-6" v-if="participantsView">
              <div class="">キャンペーン対象者推移</div>
              <div class="">
                <div id="chart" class="w-full text-black">
                  <apexchart
                    type="line"
                    height="350"
                    :options="participantsOptions"
                    :series="participantsSeries"
                  >
                  </apexchart>
                </div>
              </div>
            </div>
            <div class="w-1/2 mr-6" v-if="followerhistoryView">
              <div class="flex items-center justify-left">
                <div>フォロワー推移</div>
                <div class="text-xs text-gray-200 ml-2">
                  ※フォロワーが1万以上の場合は100件単位の取得となります
                </div>
              </div>
              <div class="">
                <div id="chart" class="w-full text-black">
                  <apexchart
                    type="line"
                    height="350"
                    :options="followerhistoryOptions"
                    :series="followerhistorySeries"
                  >
                  </apexchart>
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-3">
            <div
              class="text-lg text-gray-100 font-semibold mb-6 mt-6 ml-6 flex items-center justify-between"
            >
              <div class="flex items-center">
                当選者
                <div class="ml-1 text-sm text-gray-100">
                  ( {{ winners.length }} )
                </div>
              </div>
              <div class="mx-4">
                <DoButton :clickFunction="downloadWinnersPromise" :values="{}"
                  >CSVダウンロード</DoButton
                >
              </div>
            </div>
            <div class="w-full px-4" v-if="campaign_status == 3">
              <table class="w-full min-w-max">
                <thead>
                  <tr class="text-left">
                    <th class="p-0">
                      <div class="py-3 px-6 rounded-l-xl bg-gray-600">
                        <span class="text-xs text-gray-300 font-semibold"
                          >username</span
                        >
                      </div>
                    </th>
                    <th class="p-0">
                      <div class="py-3 px-6 bg-gray-600">
                        <span class="text-xs text-gray-300 font-semibold"
                          >followers</span
                        >
                      </div>
                    </th>
                    <th class="p-0">
                      <div class="py-3 px-6 bg-gray-600">
                        <span class="text-xs text-gray-300 font-semibold"
                          >gift id</span
                        >
                      </div>
                    </th>
                    <th class="p-0">
                      <div class="py-3 px-6 bg-gray-600">
                        <span class="text-xs text-gray-300 font-semibold"
                          >gift label</span
                        >
                      </div>
                    </th>
                    <th class="p-0" v-if="walletConditionExists">
                      <div class="py-3 px-6 bg-gray-600">
                        <span class="text-xs text-gray-300 font-semibold"
                          >wallet address</span
                        >
                      </div>
                    </th>
                    <th class="p-0">
                      <div class="py-6 px-6 bg-gray-600 rounded-r-xl">
                        <span class="text-xs text-gray-300 font-semibold">
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="winner in winners.slice(
                      (winner_page - 1) * winner_display_count,
                      winner_page * winner_display_count
                    )"
                    v-bind:key="winner.user_id"
                  >
                    <td class="p-0">
                      <div class="flex items-center h-8 px-6">
                        <a
                          class="text-sm text-blue-400 font-medium underline"
                          :href="'https://twitter.com/' + winner.username"
                          target="_blank"
                          rel="noopener noreferrer"
                          >{{ winner.username }}</a
                        >
                      </div>
                    </td>
                    <td class="p-0">
                      <div class="flex items-center h-8 px-6">
                        <div class="flex h-full items-center">
                          <span class="text-sm font-medium text-gray-100">{{
                            winner.followers
                          }}</span>
                        </div>
                      </div>
                    </td>
                    <td class="p-0">
                      <div class="flex items-center h-8 px-6">
                        <div class="flex h-full items-center">
                          <span class="text-sm font-medium text-gray-100">{{
                            winner.gift_id
                          }}</span>
                        </div>
                      </div>
                    </td>
                    <td class="p-0">
                      <div class="flex items-center h-8 px-6">
                        <div class="flex h-full items-center">
                          <span class="text-sm font-medium text-gray-100">{{
                            winner.label
                          }}</span>
                        </div>
                      </div>
                    </td>
                    <td class="p-0" v-if="walletConditionExists">
                      <div class="flex items-center h-8 px-6">
                        <div class="flex h-full items-center">
                          <span class="text-sm font-medium text-gray-100">{{
                            winner.address
                          }}</span>
                        </div>
                      </div>
                    </td>
                    <td class="p-0">
                      <button
                        class="inline-block py-1 px-4 text-xs text-center font-semibold leading-normal text-gray-100 bg-blue-400 hover:bg-blue-600 rounded-lg transition duration-200"
                        @click="WinnerChangeStart(winner.p_id)"
                        v-if="!before_winner && winner.status == 0"
                      >
                        当選者変更
                      </button>
                      <button
                        class="inline-block py-2 px-4 text-xs text-center font-semibold leading-normal text-gray-500 bg-yellow-400 hover:bg-yellow-600 rounded-lg transition duration-200"
                        @click="WinnerChangeCancel()"
                        v-if="before_winner == winner.p_id"
                      >
                        キャンセル
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <section
                class="py-6 flex justify-between items-center text-white"
              >
                <div class="text-xs text-gray-300">
                  showing {{ winner_page }} page of
                  {{ winner_display_count_max }} results
                </div>
                <div class="flex pr-12">
                  <button
                    @click="winners_page_min()"
                    :disabled="winner_page == 1"
                    :class="winner_page == 1 ? 'text-gray-300' : ''"
                  >
                    <i class="px-2 pi pi-angle-double-left"></i>
                  </button>
                  <button
                    @click="winners_page_decreace()"
                    :disabled="winner_page == 1"
                    :class="winner_page == 1 ? 'text-gray-300' : ''"
                  >
                    <i class="px-2 pi pi-angle-left"></i>
                  </button>
                  <p class="px-2">{{ winner_page }}</p>
                  <button
                    @click="winners_page_increace()"
                    :disabled="winner_page == winner_display_count_max"
                    :class="
                      winner_page == winner_display_count_max
                        ? 'text-gray-300'
                        : ''
                    "
                  >
                    <i class="px-2 pi pi-angle-right"></i>
                  </button>
                  <button
                    @click="winners_page_max()"
                    :disabled="winner_page == winner_display_count_max"
                    :class="
                      winner_page == winner_display_count_max
                        ? 'text-gray-300'
                        : ''
                    "
                  >
                    <i class="px-2 pi pi-angle-double-right"></i>
                  </button>
                </div>
              </section>
              <div>
                <button
                  class="ml-6 text-xs my-4 i py-2 px-4 nline-block text-center font-semibold leading-normal text-gray-100 bg-blue-400 hover:bg-blue-600 rounded-lg transition duration-200"
                  v-if="!before_winner && gift_unconfirmed"
                  @click="giftConfirm"
                >
                  当選者を確定する
                </button>
              </div>
            </div>
            <div class="px-4 text-gray-100 text-xs" v-else>
              <p>キャンペーン期間終了までお待ちください</p>
            </div>
          </div>
          <div class="col-span-3">
            <div
              class="text-lg text-gray-100 font-semibold mb-6 mt-6 ml-6 flex items-center justify-between"
            >
              <div class="flex items-center">
                参加者
                <div class="ml-1 text-sm text-gray-100">
                  ( {{ participants.length }} )
                </div>
              </div>
              <div class="mx-4">
                <DoButton
                  :clickFunction="downloadParticipansPromise"
                  :values="{}"
                  >CSVダウンロード</DoButton
                >
              </div>
            </div>
            <div class="w-full px-4" v-if="campaign_status == 3">
              <table class="w-full min-w-max">
                <thead>
                  <tr class="text-left">
                    <th class="p-0">
                      <div class="py-3 px-6 rounded-l-xl bg-gray-600">
                        <span class="text-xs text-gray-300 font-semibold"
                          >username</span
                        >
                      </div>
                    </th>
                    <th class="p-0">
                      <div class="py-3 px-6 bg-gray-600">
                        <span class="text-xs text-gray-300 font-semibold"
                          >followers</span
                        >
                      </div>
                    </th>
                    <th class="p-0">
                      <div class="py-3 px-6 bg-gray-600">
                        <span class="text-xs text-gray-300 font-semibold"
                          >win/lose</span
                        >
                      </div>
                    </th>
                    <th class="p-0" v-if="walletConditionExists">
                      <div class="py-3 px-6 bg-gray-600">
                        <span class="text-xs text-gray-300 font-semibold"
                          >wallet address</span
                        >
                      </div>
                    </th>
                    <th class="p-0">
                      <div class="py-6 px-6 bg-gray-600">
                        <span class="text-xs text-gray-300 font-semibold">
                        </span>
                      </div>
                    </th>
                    <th class="p-0">
                      <div class="py-6 px-6 bg-gray-600 rounded-r-xl">
                        <span class="text-xs text-gray-300 font-semibold">
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="participant in participants.slice(
                      (participants_page - 1) * particiapnts_display_count,
                      participants_page * particiapnts_display_count
                    )"
                    v-bind:key="participant.user_id"
                  >
                    <td class="p-0">
                      <div class="flex items-center h-8 px-6">
                        <a
                          class="text-sm text-blue-400 font-medium underline"
                          :href="'https://twitter.com/' + participant.username"
                          target="_blank"
                          rel="noopener noreferrer"
                          >{{ participant.username }}</a
                        >
                      </div>
                    </td>
                    <td class="p-0">
                      <div class="flex items-center h-8 px-6">
                        <div class="flex h-full items-center">
                          <span class="text-sm font-medium text-gray-100">{{
                            participant.followers
                          }}</span>
                        </div>
                      </div>
                    </td>
                    <td class="p-0">
                      <div class="flex items-center h-8 px-6">
                        <div class="flex h-full items-center">
                          <span class="text-sm font-medium text-gray-100">{{
                            participant.win_flag == 0 ? "×" : "◯"
                          }}</span>
                        </div>
                      </div>
                    </td>
                    <td class="p-0" v-if="walletConditionExists">
                      <div class="flex items-center h-8 px-6">
                        <div class="flex h-full items-center">
                          <span class="text-sm font-medium text-gray-100">{{
                            participant.address
                          }}</span>
                        </div>
                      </div>
                    </td>
                    <td></td>
                    <td class="p-0">
                      <button
                        class="inline-block py-2 px-4 text-xs text-center font-semibold leading-normal text-gray-100 bg-blue-400 hover:bg-blue-600 rounded-lg transition duration-200"
                        @click="WinnerChangeFinish(participant.p_id)"
                        v-if="before_winner && participant.win_flag == 0"
                      >
                        当選者変更
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <section
                class="py-6 flex justify-between items-center text-white"
              >
                <div class="text-xs text-gray-300">
                  showing {{ participants_page }} page of
                  {{ participants_display_count_max }} results
                </div>
                <div class="flex pr-12">
                  <button
                    @click="participans_page_min()"
                    :disabled="participants_page == 1"
                    :class="participants_page == 1 ? 'text-gray-300' : ''"
                  >
                    <i class="px-2 pi pi-angle-double-left"></i>
                  </button>
                  <button
                    @click="participans_page_decreace()"
                    :disabled="participants_page == 1"
                    :class="participants_page == 1 ? 'text-gray-300' : ''"
                  >
                    <i class="px-2 pi pi-angle-left"></i>
                  </button>
                  <p class="px-2">{{ participants_page }}</p>
                  <button
                    @click="participans_page_increace()"
                    :disabled="
                      participants_page == participants_display_count_max
                    "
                    :class="
                      participants_page == participants_display_count_max
                        ? 'text-gray-300'
                        : ''
                    "
                  >
                    <i class="px-2 pi pi-angle-right"></i>
                  </button>
                  <button
                    @click="participans_page_max()"
                    :disabled="
                      participants_page == participants_display_count_max
                    "
                    :class="
                      participants_page == participants_display_count_max
                        ? 'text-gray-300'
                        : ''
                    "
                  >
                    <i class="px-2 pi pi-angle-double-right"></i>
                  </button>
                </div>
              </section>
            </div>
            <div class="px-4 text-gray-100 text-xs" v-else>
              <p>キャンペーン期間終了までお待ちください</p>
            </div>
          </div>
        </div>
      </div> </RightColumnOutline
    >>
  </div>
</template>

<script>
import SideVar from "@/components/SideVar.vue";
import api from "../api/api.js";
// import { useRoute } from "vue-router";
import VueApexCharts from "vue3-apexcharts";

export default {
  name: "Campaign_regit",
  components: {
    SideVar,
    apexchart: VueApexCharts,
  },
  mounted() {
    // const route = useRoute();
    this.getCampaign();
    this.getConditions();
    this.getFollowandretweetDailydata();
    this.getFollowerhistorydata();
  },
  data() {
    return {
      campaign_status: null,
      campaign_id: null,
      participants: [],
      winners: [],
      before_winner: "",
      after_winner: "",
      gift_unconfirmed: false,
      winner_page: 1,
      participants_page: 1,
      winner_display_count: 10,
      particiapnts_display_count: 10,
      winner_display_count_max: 1,
      participants_display_count_max: 1,
      downloading: false,

      conditions_tweet: [],
      conditions_twitter_account: [],
      discordConditionExists: false,
      walletConditionExists: false,
      externalSiteConditionExists: false,
      externalSiteUrl: "",

      campaign_name: null,
      keyword: null,
      campaign: null,

      participantsView: false,
      participantsSeries: [
        {
          name: "時間別キャンペーン対象者数",
          type: "column",
          data: [],
        },
        {
          name: "累計キャンペーン対象者数",
          type: "line",
          data: [],
        },
      ],
      participantsOptions: {
        chart: { height: 350, type: "line", width: "100%" },
        stroke: { width: [0, 2] },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: false, // データラベル（ライン上のテキスト）を非表示に設定
        },
        xaxis: {
          categories: [],
          type: "datetime",
          labels: {
            style: {
              colors: "#C4BBAF", // X軸のラベルの色を白に設定
            },
            datetimeUTC: false,
          },
          axisBorder: {
            show: true,
            color: "#C4BBAF",
          },
          axisTicks: {
            show: true,
            color: "#C4BBAF",
          },
        },
        tooltip: {
          x: {
            format: "yyyy-MM-dd HH:mm", // ツールチップに表示する日時のフォーマット
          },
        },
        yaxis: [
          {
            title: {
              text: "時間別キャンペーン対象者数",
              style: {
                color: "#C4BBAF", // y軸タイトルの色を白に設定
              },
            },
            labels: {
              style: {
                colors: "#C4BBAF", // y軸のラベルの色を白に設定
              },
            },
          },
          {
            opposite: true,
            title: {
              text: "累計キャンペーン対象者数",
              style: {
                color: "#C4BBAF", // y軸タイトルの色を白に設定
              },
            },
            labels: {
              style: {
                colors: "#C4BBAF", // y軸のラベルの色を白に設定
              },
            },
          },
        ],
        legend: {
          labels: {
            colors: "#C4BBAF", // 凡例のテキスト色を白に設定
            useSeriesColors: false, // シリーズの色を使用しない
          },
        },
      },
      followerhistoryView: false,
      followerhistorySeries: [],
      tmp_followers: 0,
      followerhistoryOptions: {
        chart: {
          height: 350,
          type: "line",
          width: "100%",
        },
        stroke: { width: [2, 2] },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: false, // データラベル（ライン上のテキスト）を非表示に設定
        },
        xaxis: {
          categories: [],
          type: "datetime",
          labels: {
            style: {
              colors: "#C4BBAF", // X軸のラベルの色を白に設定
            },
            datetimeUTC: false,
          },
          axisBorder: {
            show: true,
            color: "#C4BBAF",
          },
          axisTicks: {
            show: true,
            color: "#C4BBAF",
          },
        },
        tooltip: {
          x: {
            format: "yyyy-MM-dd HH:mm", // ツールチップに表示する日時のフォーマット
          },
        },
        legend: {
          labels: {
            colors: "#C4BBAF", // 凡例のテキスト色を白に設定
            useSeriesColors: false, // シリーズの色を使用しない
          },
        },
        yaxis: [
          {
            title: {
              text: "フォロワー数",
              style: {
                color: "#C4BBAF", // y軸タイトルの色を白に設定
              },
            },
            labels: {
              style: {
                colors: "#C4BBAF", // y軸のラベルの色を白に設定
              },
            },
          },
        ],
      },
    };
  },
  watch: {
    winners: {
      handler() {
        for (let i = 0; i < this.winners.length; i++) {
          if (this.winners[i].status == 0) {
            this.gift_unconfirmed = true;
            break;
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    getCampaign() {
      api
        .getCampaign({ id: this.$route.params.id })
        .then((response) => {
          this.campaign_name = response["data"][0]["campaign_name"];
          this.campaign_status = response["data"][0]["status"];
          this.campaign_id = response["data"][0]["campaign_id"];
          if (this.campaign_status == 3) {
            // 終了済なら
            api
              .getParticipants({
                campaign_id: this.$route.params.id,
              })
              .then((response) => {
                console.log(response);
                if (response["data"].length != 0) {
                  for (var i = 0; i < response["data"].length; i++) {
                    this.participants.push(response["data"][i]);
                    if (response["data"][i]["win_flag"] == 1) {
                      this.winners.push(response["data"][i]);
                    }
                  }
                  this.participants_display_count_max = Math.ceil(
                    this.participants.length / this.particiapnts_display_count
                  );
                  this.winner_display_count_max = Math.ceil(
                    this.winners.length / this.winner_display_count
                  );
                }
              })
              .catch((error) => {
                console.log("error: ", error);
              });
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
    getConditions() {
      this.conditions_tweet = [];
      this.conditions_twitter_account = [];
      this.discordConditionExists = false;
      this.walletConditionExists = false;
      this.externalSiteConditionExists = false;
      this.externalSiteUrl = "";
      api
        .getConditions({
          campaign_id: this.$route.params.id,
          twitter_api_call: 0,
        })
        .then((response) => {
          console.log(response);
          let response_data = response["data"];
          for (var i in response_data) {
            if (response_data[i]["actions"] == "FOLLOW") {
              this.conditions_twitter_account.push({
                user_id: response_data[i]["user_id"],
                username: response_data[i]["username"],
                followers: response_data[i]["followers"],
              });
            } else if (response_data[i]["actions"] == "RETWEET") {
              this.conditions_tweet.push({
                tweet_id: response_data[i]["tweet_id"],
                username: response_data[i]["username"],
                text: response_data[i]["text"],
              });
            } else if (response_data[i]["actions"] == "DISCORD_SERVER_JOIN") {
              this.discordConditionExists = true;
              this.discordInviteUrl = response_data[i]["invite_url"];
            } else if (response_data[i]["actions"] == "WALLET_CONNECT") {
              this.walletConditionExists = true;
            } else if (response_data[i]["actions"] == "EXTERNAL_SITE_VISIT") {
              this.externalSiteConditionExists = true;
              this.externalSiteUrl = response_data[i]["external_site_url"];
            }
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          this.$toast.show(
            "条件の取得に失敗しました。時間を置いて再度お試しください。",
            {
              type: "error",
              position: "top-right",
              duration: 5000,
            }
          );
        });
    },
    getFollowandretweetDailydata() {
      api
        .getFollowandretweetDailydata({
          campaign_id: this.$route.params.id,
        })
        .then((response) => {
          // console.log(response["data"]);
          for (let i = 0; i < response["data"].length; i++) {
            this.participantsSeries[0]["data"].push(
              response["data"][i]["hourly_count"]
            );
            this.participantsSeries[1]["data"].push(
              response["data"][i]["cumulative_count"]
            );
            this.participantsOptions.xaxis.categories.push(
              response["data"][i]["formatted_datetime"]
            );
          }
          // console.log(this.participantsSeries);
          // console.log(this.participantsOptions["labels"]);
          this.participantsView = true;
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
    getFollowerhistorydata() {
      api
        .getFollowersHistory({
          campaign_id: this.$route.params.id,
        })
        .then((response) => {
          console.log("response", response["data"]);
          for (let i = 0; i < response["data"].length; i++) {
            this.followerhistorySeries.push({
              data: [],
              name: response["data"][i][0]["username"],
              type: "line",
            });
            for (let j = 0; j < response["data"][i].length; j++) {
              if (response["data"][i][j]["followers"] != 0) {
                this.tmp_followers = response["data"][i][j]["followers"];
              }
              this.followerhistorySeries[i]["data"].push(this.tmp_followers);
              if (i == 0) {
                this.followerhistoryOptions.xaxis.categories.push(
                  response["data"][i][j]["formatted_datetime"]
                );
              }
            }
          }
          // console.log("series", this.followerhistorySeries);
          // console.log("options", this.followerhistoryOptions.xaxis.categories);
          if (this.followerhistorySeries.length > 0) {
            this.followerhistoryView = true;
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
    WinnerChangeStart(p_id) {
      this.before_winner = p_id;
    },
    WinnerChangeCancel() {
      this.before_winner = "";
    },
    WinnerChangeFinish(p_id) {
      this.after_winner = p_id;
      this.changeWinner();
    },
    changeWinner() {
      api
        .changeWinner({
          campaign_id: this.$route.params.id,
          before: this.before_winner,
          after: this.after_winner,
        })
        .then((response) => {
          console.log(response);
          this.before_winner = "";
          this.after_winner = "";
          this.$router.go({
            name: "analisysFaR",
            params: { id: this.$route.params.id },
          });
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
    giftConfirm() {
      let gifts = [];
      for (let i = 0; i < this.winners.length; i++) {
        gifts.push(this.winners[i]["gift_id"]);
      }
      api
        .confirmGifts({
          campaign_id: this.$route.params.id,
          gifts: gifts,
        })
        .then((response) => {
          console.log(response);
          this.$router.go({
            name: "analisysFaR",
            params: { id: this.$route.params.id },
          });
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
    participans_page_increace() {
      this.participants_page += 1;
    },
    participans_page_decreace() {
      this.participants_page -= 1;
    },
    participans_page_max() {
      this.participants_page = this.participants_display_count_max;
    },
    participans_page_min() {
      this.participants_page = 1;
    },
    winners_page_increace() {
      this.winner_page += 1;
    },
    winners_page_decreace() {
      this.winner_page -= 1;
    },
    winners_page_max() {
      this.winner_page = this.winner_display_count_max;
    },
    winners_page_min() {
      this.winner_page = 1;
    },
    downloadWinnersPromise() {
      return new Promise((resolve) => {
        var csv = "";
        if (this.walletConditionExists) {
          csv =
            "\ufeff" +
            "username,followers,win/lose,gift label,wallet address\n";
        } else {
          csv = "\ufeff" + "username,followers,win/lose,gift label\n";
        }
        this.winners.forEach((el) => {
          var line = "";
          if (this.walletConditionExists) {
            line =
              el["username"] +
              "," +
              el["followers"] +
              "," +
              el["win_flag"] +
              "," +
              el["label"] +
              "," +
              el["address"] +
              "\n";
          } else {
            line =
              el["username"] +
              "," +
              el["followers"] +
              "," +
              el["win_flag"] +
              "," +
              el["label"] +
              "\n";
          }
          csv += line;
        });
        let blob = new Blob([csv], { type: "text/csv" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "winners.csv";
        link.click();
        resolve("resolved");
      });
    },
    downloadParticipansPromise() {
      return new Promise((resolve) => {
        var csv = "";
        if (this.walletConditionExists) {
          csv = "\ufeff" + "username,followers,win/lose,wallet address\n";
        } else {
          csv = "\ufeff" + "username,followers,win/lose\n";
        }
        this.participants.forEach((el) => {
          var line = "";
          if (this.walletConditionExists) {
            line =
              el["username"] +
              "," +
              el["followers"] +
              "," +
              el["win_flag"] +
              "," +
              el["address"] +
              "\n";
          } else {
            line =
              el["username"] +
              "," +
              el["followers"] +
              "," +
              el["win_flag"] +
              "\n";
          }
          csv += line;
        });
        let blob = new Blob([csv], { type: "text/csv" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "participants.csv";
        link.click();
        resolve("resolved");
      });
    },
  },
};
</script>
