<template>
  <div class="campaignModifyConditions">
    <!-- side var  -->
    <SideVar />
    <!-- input panel -->
    <RightColumnOutline>
      <div
        class="items-center -mx-4 mb-8 pb-6 border-b border-gray-400 border-opacity-3"
      >
        <div class="w-auto px-4 mb-0">
          <PageTitle>Campaign Setting By Panel</PageTitle>
        </div>
        <div class="w-auto px-4"></div>
      </div>
      <!-- general -->
      <div v-if="campaign_category == 'INSTANT_WIN'">
        <div class="pb-8 mb-8 border-b border-gray-400 border-opacity-3">
          <h4
            class="text-xl font-bold tracking-wide text-white mb-5 text-left flex items-center"
          >
            <i class="w-8 ml-6 pi pi-gift"></i>
            ポイント形式設定
          </h4>
          <div
            class="flex flex-wrap items-center -mx-4 mb-4 border-gray-400 border-opacity-20"
          >
            <div class="w-1/3 pl-18 text-left">
              <span class="text-sm font-medium text-gray-100"
                >ポイント形式<i
                  class="pi pi-question-circle mx-1"
                  v-tippy="{
                    content:
                      'ONにするとポイント形式のキャンペーン方法になります。',
                  }"
                ></i>
              </span>
            </div>
            <div class="w-1/3 px-4 text-left text-gray-100 text-sm">
              現在の設定：{{ pointbase_display }}
            </div>
            <div class="w-1/4 px-4">
              <div class="max-w-xl" v-if="!pointBase">
                <DoButton
                  :clickFunction="onPointBasePromise"
                  :values="{}"
                  :disabled="pointBase || campaign_status != 1"
                  >ONにする</DoButton
                >
              </div>
              <div class="max-w-xl" v-else>
                <BackButton
                  :clickFunction="offPointBasePromise"
                  :values="{}"
                  :disabled="!pointBase || campaign_status != 1"
                  >OFFにする</BackButton
                >
              </div>
            </div>
          </div>
        </div>
        <div class="pb-8 mb-8 border-b border-gray-400 border-opacity-3">
          <h4
            class="text-xl font-bold tracking-wide text-white mb-5 text-left flex items-center"
          >
            <i class="w-8 ml-6 pi pi-gift"></i>
            抽選設定
          </h4>
          <div
            class="flex flex-wrap items-center -mx-4 mb-4 border-gray-400 border-opacity-20"
          >
            <div class="w-1/3 pl-18 text-left">
              <span class="text-sm font-medium text-gray-100"
                >毎日抽選<i
                  class="pi pi-question-circle mx-1"
                  v-tippy="{
                    content:
                      'ONにすると未当選のアカウントは毎日00:00(JST)に抽選可能数がリセットされて、毎日抽選できるようになります。',
                  }"
                ></i>
              </span>
            </div>
            <div class="w-1/3 px-4 text-left text-gray-100 text-sm">
              現在の設定：{{ raffleEveryday_display }}
            </div>
            <div class="w-1/4 px-4">
              <div class="max-w-xl" v-if="!raffleEveryday">
                <DoButton
                  :clickFunction="onRaffleEverydayPromise"
                  :values="{}"
                  :disabled="raffleEveryday || campaign_status != 1"
                  >ONにする</DoButton
                >
              </div>
              <div class="max-w-xl" v-else>
                <BackButton
                  :clickFunction="offRaffleEverydayPromise"
                  :values="{}"
                  :disabled="!raffleEveryday || campaign_status != 1"
                  >OFFにする</BackButton
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- task setting -->
      <div v-if="campaign_category == 'INSTANT_WIN'">
        <div class="pb-8 mb-8 border-b border-gray-400 border-opacity-3">
          <h4
            class="text-xl font-bold tracking-wide text-white mb-5 text-left flex items-center"
          >
            <i class="w-8 ml-6 pi pi-check-circle"></i>
            タスク設定
          </h4>
          <div
            class="flex flex-wrap items-center -mx-4 mb-4 border-gray-400 border-opacity-20"
          >
            <div class="bg-gray-300 m-10 py-20 w-full h-full rounded-lg">
              <!-- ここがパネル領域 -->
              <div
                v-for="item in taskList"
                :key="item.id"
                class="bg-gray-100 p-2 m-4 rounded-lg"
              >
                <!-- タスクnull -->
                <div
                  v-if="item.taskType == null"
                  class="flex justify-center items-center"
                >
                  <div class="text-sm mr-4">タスクの種類を選んでください</div>
                  <div>
                    <select v-model="item.taskType" @change="handleChange">
                      <option
                        class="text-sm py-2 ml-3 text-black"
                        disabled
                        value=""
                      ></option>
                      <option
                        v-for="(option, index) in options"
                        :key="index"
                        :value="option"
                        class="text-sm py-2 ml-3"
                      >
                        {{ option }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- タスクありのトップdiv -->
                <div class="flex justify-between items-center" v-else>
                  <!-- タスク follow&repost -->
                  <div
                    v-if="item.taskType == 'Xフォロー＆リポスト'"
                    class="w-11/12"
                  >
                    <div>
                      <!-- FOLLOW -->
                      <div>
                        <h4
                          class="text-xl font-bold tracking-wide text-black my-3 text-left flex items-center"
                        >
                          <img
                            class="w-8 mx-4"
                            src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/iconfinder-social-media-applications-6twitter-4102580_113802.png"
                            alt="twitter_icon"
                          />
                          FOLLOW
                        </h4>
                        <div>
                          <div class="flex flex-wrap items-center">
                            <div class="w-1/3 pl-10 text-left">
                              <span class="text-sm font-medium text-black"
                                >Twitterアカウント *
                              </span>
                            </div>
                            <div class="w-full sm:w-1/3 px-4">
                              <div class="max-w-xl">
                                <input
                                  class="block py-2 px-2 w-full text-sm text-black placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
                                  type="text"
                                  v-model="twitter_account"
                                  placeholder="@username"
                                />
                              </div>
                            </div>
                            <div class="w-1/4 px-4">
                              <div
                                class="max-w-xl"
                                v-if="!twitter_account_check"
                              >
                                <DoButton
                                  :clickFunction="checkTwitterAccountPromise"
                                  :values="{}"
                                  :disabled="
                                    !twitter_account ||
                                    twitter_account_check ||
                                    campaign_status != 1
                                  "
                                  >アカウントチェック</DoButton
                                >
                              </div>
                              <div class="max-w-xl" v-else>
                                <DoButton
                                  :clickFunction="createFollowConditionPromise"
                                  :values="{}"
                                  :disabled="true"
                                  >OK
                                </DoButton>
                              </div>
                            </div>
                          </div>
                          <div class="flex flex-wrap items-center">
                            <div class="w-1/3 px-4 pb-4"></div>
                            <div class="w-2/3 px-4">
                              <div
                                v-if="twitter_account_result"
                                class="max-w-xl text-gray-900 text-left text-xs"
                              >
                                {{ twitter_account_result }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="follow_two_targets">
                          <div class="flex flex-wrap items-center mt-4">
                            <div class="w-1/3 pl-10 text-left">
                              <span class="text-sm font-medium text-black"
                                >Twitterアカウント *
                              </span>
                            </div>
                            <div class="w-full sm:w-1/3 px-4">
                              <div class="max-w-xl">
                                <input
                                  class="block py-2 px-2 w-full text-sm text-black placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
                                  type="text"
                                  v-model="twitter_account_2"
                                  placeholder="@username"
                                />
                              </div>
                            </div>
                            <div class="w-1/4 px-4">
                              <div
                                class="max-w-xl"
                                v-if="!twitter_account_check_2"
                              >
                                <DoButton
                                  :clickFunction="checkTwitterAccountPromise2"
                                  :values="{}"
                                  :disabled="
                                    !twitter_account_2 ||
                                    twitter_account_check_2 ||
                                    campaign_status != 1
                                  "
                                  >アカウントチェック</DoButton
                                >
                              </div>
                              <div class="max-w-xl" v-else>
                                <DoButton
                                  :clickFunction="createFollowConditionPromise"
                                  :values="{}"
                                  :disabled="true"
                                  >OK
                                </DoButton>
                              </div>
                            </div>
                          </div>
                          <div class="flex flex-wrap items-center">
                            <div class="w-1/3 px-4 pb-4"></div>
                            <div class="w-2/3 px-4">
                              <div
                                v-if="twitter_account_result_2"
                                class="max-w-xl text-gray-900 text-left text-xs"
                              >
                                {{ twitter_account_result_2 }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div
                            class="bg-gray-100 rounded-lg p-3 w-auto inline-block text-sm"
                          >
                            <button
                              class="flex justify-center items-center"
                              @click="addFollow"
                              :values="{}"
                            >
                              <i class="mr-2 pi pi-plus-circle"></i
                              >フォロー対象を追加
                            </button>
                          </div>
                        </div>
                      </div>
                      <!-- RETWEET -->
                      <div>
                        <h4
                          class="text-xl font-bold tracking-wide text-black mb-5 text-left flex items-center"
                        >
                          <img
                            class="w-8 mx-4"
                            src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/iconfinder-social-media-applications-6twitter-4102580_113802.png"
                            alt="twitter_icon"
                          />
                          REPOST
                        </h4>
                        <div class="flex flex-wrap items-center">
                          <div class="w-1/3 pl-10 text-left">
                            <span class="text-sm font-medium text-black">
                              Post URL *
                            </span>
                          </div>
                          <div class="w-1/3 px-4">
                            <div class="max-w-xl">
                              <input
                                class="block py-2 px-2 w-full text-sm text-black placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
                                type="text"
                                v-model="tweet_url"
                                placeholder="https://"
                              />
                            </div>
                          </div>
                          <div class="w-1/4 px-4">
                            <div class="max-w-xl" v-if="!tweet_url_check">
                              <DoButton
                                :clickFunction="checkTweetPromise"
                                :values="{}"
                                :disabled="!tweet_url || tweet_url_check"
                                >ツイートチェック</DoButton
                              >
                            </div>
                            <div class="max-w-xl" v-else>
                              <DoButton
                                :clickFunction="createRetweetConditionPromise"
                                :values="{}"
                                :disabled="true"
                                >OK
                              </DoButton>
                            </div>
                          </div>
                        </div>
                        <div class="flex flex-wrap items-center mb-4">
                          <div class="w-full sm:w-1/3 px-4 pb-4 sm:mb-0">
                            <span
                              class="text-sm font-medium text-gray-100"
                            ></span>
                          </div>
                          <div class="w-full sm:w-2/3 px-4">
                            <div
                              v-if="tweet_url_result"
                              class="max-w-xl text-gray-900 text-left text-xs"
                            >
                              {{ tweet_url_result }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- タスク 外部サイトリンク -->
                  <div v-if="item.taskType == '外部サイト遷移'" class="w-11/12">
                    <div>
                      <div class="p-3">
                        <h4
                          class="text-xl font-bold tracking-wide text-black mb-5 text-left flex items-center"
                        >
                          <i class="w-8 ml-6 pi pi-sign-out"></i>
                          外部サイト
                        </h4>
                        <div class="flex flex-wrap items-center">
                          <div class="w-1/3 pl-10 text-left">
                            <span class="text-sm font-medium text-black"
                              >外部サイトURL<i
                                class="pi pi-question-circle mx-1"
                                v-tippy="{
                                  content:
                                    '登録された外部リンクに遷移することを条件として設定することができます。',
                                }"
                              ></i>
                            </span>
                          </div>
                          <div class="w-1/3 px-4">
                            <div class="max-w-xl text-white text-left">
                              <div class="flex items-center justify-start">
                                <div class="">
                                  <input
                                    class="block py-2 px-2 mx-4 w-full text-sm text-gray-900 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
                                    type="text"
                                    v-model="item.externalSiteUrl"
                                    placeholder="https://"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- タスク discord参加 -->
                  <div v-if="item.taskType == 'Discord参加'" class="w-11/12">
                    <div class="p-3">
                      <h4
                        class="text-xl font-bold tracking-wide text-black mb-5 text-left flex items-center"
                      >
                        <img
                          class="w-8 mx-4"
                          src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/discord_icon_130958.png"
                          alt="discord_icon"
                        />
                        DISCORD
                      </h4>
                      <div class="flex flex-wrap items-center">
                        <div class="w-1/3 pl-10 text-left">
                          <span class="text-sm font-medium text-black"
                            >サーバーへの参加<i
                              class="pi pi-question-circle mx-1"
                              v-tippy="{
                                content:
                                  'Discordサーバーへの参加を条件にする場合、事前に外部サービス連携画面からDiscordサーバーとの連携が必要です',
                              }"
                            ></i>
                          </span>
                        </div>
                      </div>
                      <div class="flex flex-wrap items-center">
                        <div class="w-1/3 pl-10 text-left">
                          <span class="text-sm font-medium text-black"
                            >参加先サーバー
                          </span>
                        </div>
                        <div class="w-1/3 text-left py-2">
                          <div class="max-w-xl text-black text-left">
                            <div
                              v-if="discordGuild"
                              class="flex items-center justify-start"
                            >
                              <img
                                :src="
                                  'https://cdn.discordapp.com/icons/' +
                                  discordGuild.item_data_1 +
                                  '/' +
                                  discordGuild.item_data_3
                                "
                                alt="discord_icon"
                                class="w-8 mx-4"
                              />
                              {{ discordGuild.item_data_2 }}
                            </div>
                            <div v-else>Discordサーバーが未連携です</div>
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-wrap items-center">
                        <div class="w-1/3 pl-10 text-left">
                          <span class="text-sm font-medium text-black"
                            >招待URL
                          </span>
                        </div>
                        <div class="w-1/3">
                          <div class="max-w-xl text-white text-left">
                            <div
                              v-if="discordGuild"
                              class="flex items-center justify-start"
                            >
                              <input
                                class="block p-2 mx-4 w-full text-sm text-gray-900 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
                                type="text"
                                v-model="discordInviteUrl"
                                placeholder="https://"
                              />
                            </div>
                            <div v-else>Discordサーバーが未連携です</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- タスク walletアドレス提出 -->
                  <div
                    v-if="item.taskType == 'Walletアドレス登録'"
                    class="w-11/12"
                  >
                    <div class="p-3">
                      <h4
                        class="text-xl font-bold tracking-wide text-black mb-5 text-left flex items-center"
                      >
                        <img
                          class="w-8 mx-4"
                          src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/MetaMask_Fox.svg.png"
                          alt="metamask_icon"
                        />
                        WALLET
                      </h4>
                      <div class="flex flex-wrap items-center">
                        <div class="w-1/3 pl-18 text-left">
                          <span class="text-sm font-medium text-black"
                            >ウォレット連携（アドレス登録）<i
                              class="pi pi-question-circle mx-1"
                              v-tippy="{
                                content:
                                  'キャンペーン参加者がMetamaskウォレットを連携してERC20アドレスを登録することを条件とする項目です',
                              }"
                            ></i>
                          </span>
                        </div>
                        <div class="w-1/3 px-4"></div>
                      </div>
                    </div>
                  </div>
                  <div class="text-xs mr-3">
                    <div v-if="item.taskType == 'Xフォロー＆リポスト'">
                      <div
                        v-if="!item.registed"
                        class="flex flex-col items-center"
                      >
                        <div class="p-2 mb-2 flex justify-center items-center">
                          <div class="mr-1">必須</div>
                          <div>
                            <input type="checkbox" v-model="item.must" />
                          </div>
                        </div>
                        <div class="max-w-xs">
                          <input
                            class="block p-2 rounded-lg mb-4 w-12 placeholder-gray-300"
                            type="number"
                            v-model="item.point"
                            placeholder="0"
                          />
                        </div>
                        <DoButton
                          class="mb-4"
                          :clickFunction="createTwitterConditionPromise"
                          :values="{ point: item.point, must: item.must }"
                          :disabled="
                            !twitter_account_check ||
                            campaign_status != 1 ||
                            !tweet_url_check ||
                            !item.point ||
                            (follow_two_targets && !twitter_account_check_2)
                          "
                          >登録</DoButton
                        >
                        <BackButton
                          :clickFunction="removeTaskList"
                          :values="item.id"
                          >削除</BackButton
                        >
                      </div>
                      <div v-else class="flex flex-col items-center">
                        <div class="max-w-xs">
                          <input
                            class="block p-2 rounded-lg mb-4 w-12 placeholder-gray-300"
                            type="number"
                            v-model="item.point"
                            placeholder="0"
                          />
                        </div>
                        <BackButton
                          :clickFunction="deleteTwitterConditionPromise"
                          :values="{}"
                          ><i class="pi pi-trash"></i
                        ></BackButton>
                      </div>
                    </div>
                    <div v-if="item.taskType == '外部サイト遷移'">
                      <div
                        v-if="!item.registed"
                        class="flex flex-col items-center"
                      >
                        <div class="p-2 mb-2 flex justify-center items-center">
                          <div class="mr-1">必須</div>
                          <div>
                            <input type="checkbox" v-model="item.must" />
                          </div>
                        </div>
                        <div class="max-w-xs">
                          <input
                            class="block p-2 rounded-lg mb-4 w-12 placeholder-gray-300"
                            type="number"
                            v-model="item.point"
                            placeholder="0"
                          />
                        </div>
                        <DoButton
                          class="mb-4"
                          :clickFunction="createExternalSiteConditionPromise"
                          :values="{
                            url: item.externalSiteUrl,
                            point: item.point,
                            must: item.must,
                          }"
                          :disabled="
                            !item.externalSiteUrl ||
                            campaign_status != 1 ||
                            !item.point
                          "
                          >登録</DoButton
                        >
                        <BackButton
                          :clickFunction="removeTaskList"
                          :values="item.id"
                          >削除</BackButton
                        >
                      </div>
                      <div v-else class="flex flex-col items-center">
                        <div class="max-w-xs">
                          <input
                            class="block p-2 rounded-lg mb-4 w-12 placeholder-gray-300"
                            type="number"
                            v-model="item.point"
                            placeholder="0"
                          />
                        </div>
                        <BackButton
                          :clickFunction="deleteExternalSiteConditionPromise"
                          :values="item.condition_id"
                          ><i class="pi pi-trash"></i
                        ></BackButton>
                      </div>
                    </div>
                    <div v-if="item.taskType == 'Discord参加'">
                      <div
                        v-if="!item.registed"
                        class="flex flex-col items-center"
                      >
                        <div class="p-2 mb-2 flex justify-center items-center">
                          <div class="mr-1">必須</div>
                          <div>
                            <input type="checkbox" v-model="item.must" />
                          </div>
                        </div>
                        <div class="max-w-xs">
                          <input
                            class="block p-2 rounded-lg mb-4 w-12 placeholder-gray-300"
                            type="number"
                            v-model="item.point"
                            placeholder="0"
                          />
                        </div>
                        <DoButton
                          class="mb-4"
                          :clickFunction="createDiscordConditionPromise"
                          :values="{ point: item.point, must: item.must }"
                          :disabled="
                            !discordConnect ||
                            discordConditionExists ||
                            !discordInviteUrl ||
                            campaign_status != 1 ||
                            !item.point
                          "
                          >登録</DoButton
                        >
                        <BackButton
                          :clickFunction="removeTaskList"
                          :values="item.id"
                          >削除</BackButton
                        >
                      </div>
                      <div v-else class="flex flex-col items-center">
                        <div class="max-w-xs">
                          <input
                            class="block p-2 rounded-lg mb-4 w-12 placeholder-gray-300"
                            type="number"
                            v-model="item.point"
                            placeholder="0"
                          />
                        </div>
                        <BackButton
                          :clickFunction="deleteDiscordConditionPromise"
                          :values="{}"
                          ><i class="pi pi-trash"></i
                        ></BackButton>
                      </div>
                    </div>
                    <div v-if="item.taskType == 'Walletアドレス登録'">
                      <div
                        v-if="!item.registed"
                        class="flex flex-col items-center"
                      >
                        <div class="p-2 mb-2 flex justify-center items-center">
                          <div class="mr-1">必須</div>
                          <div>
                            <input type="checkbox" v-model="item.must" />
                          </div>
                        </div>
                        <div class="max-w-xs">
                          <input
                            class="block p-2 rounded-lg mb-4 w-12 placeholder-gray-300"
                            type="number"
                            v-model="item.point"
                            placeholder="0"
                          />
                        </div>
                        <DoButton
                          class="mb-4"
                          :clickFunction="createWalletConditionPromise"
                          :values="{ point: item.point, must: item.must }"
                          :disabled="
                            walletConditionExists || campaign_status != 1
                          "
                          >登録</DoButton
                        >
                        <BackButton
                          :clickFunction="removeTaskList"
                          :values="item.id"
                          >削除</BackButton
                        >
                      </div>
                      <div v-else class="flex flex-col items-center">
                        <div class="max-w-xs">
                          <input
                            class="block p-2 rounded-lg mb-4 w-12 placeholder-gray-300"
                            type="number"
                            v-model="item.point"
                            placeholder="0"
                          />
                        </div>
                        <BackButton
                          :clickFunction="deleteWalletConditionPromise"
                          :values="{}"
                          ><i class="pi pi-trash"></i
                        ></BackButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="bg-gray-100 rounded-lg p-3 w-auto inline-block text-sm"
              >
                <button
                  class="flex justify-center items-center"
                  @click="addTaskPanel"
                  :values="{}"
                >
                  <i class="mr-2 pi pi-plus-circle"></i>タスクを追加
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- REWORD -->
      <div>
        <div class="ml-6 flex justify-between">
          <h4 class="text-xl font-bold tracking-wide text-white mb-8 text-left">
            REWARD
          </h4>
          <input
            class="text-white mr-4"
            type="file"
            accept="text/csv"
            @change="handleFileSelect"
          />
        </div>
        <div class="flex flex-wrap justify-center items-center -mx-4">
          <div class="text-white">No</div>
          <div class="w-full sm:w-1/3 px-4">
            <div class="max-w-xl">
              <a
                class="block py-4 px-3 w-full text-sm text-gray-50 font-medium outline-none"
                >ラベル</a
              >
            </div>
          </div>
          <div class="w-full sm:w-1/2 px-4">
            <div class="max-w-xl">
              <a
                class="block py-4 px-3 w-full text-sm text-gray-50 font-medium outline-none"
                >ギフトコード</a
              >
            </div>
          </div>
        </div>
        <div
          class="flex flex-wrap justify-center items-center -mx-4 mb-4 border-gray-400 border-opacity-20"
          v-for="(reword, index) in reword_list"
          v-bind:key="reword.code"
        >
          <div class="text-white">{{ index + 1 }}</div>
          <div class="w-full sm:w-1/3 px-4">
            <div class="max-w-xl">
              <input
                class="block py-4 px-3 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
                id="formInput1-3"
                type="text"
                v-model="reword[0]"
                placeholder="label"
              />
            </div>
          </div>
          <div class="w-full sm:w-1/2 px-4">
            <div class="max-w-xl">
              <input
                class="block py-4 px-3 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
                id="formInput1-3"
                type="text"
                v-model="reword[1]"
                placeholder="code"
              />
            </div>
          </div>
        </div>
        <div class="mb-8">
          <div v-if="!reword_update">
            <DoButton
              :clickFunction="createGiftsPromise"
              :values="{}"
              :disabled="!gift_filled || campaign_status != 1"
            >
              登録</DoButton
            >
          </div>
          <div v-else>
            <DoButton
              :clickFunction="updateGiftsPromise"
              :values="{}"
              :disabled="!gift_filled || campaign_status != 1"
            >
              変更</DoButton
            >
          </div>
        </div>
      </div>
    </RightColumnOutline>
  </div>
</template>

<script>
import SideVar from "@/components/SideVar.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import api from "../api/api.js";
import { ref } from "vue";
import { useRoute } from "vue-router";
import checks from "../assets/mixins/Checks";

export default {
  name: "Campaign_regit",
  mixins: [checks],
  components: {
    SideVar,
  },
  setup() {
    let date = ref();
    let taskList = ref([]);
    return {
      date,
      taskList,
    };
  },
  data() {
    return {
      campaign_id: null,
      campaign_status: 1,
      conditions_twitter_account: [],
      conditions_tweet: [],
      registered_twitter_account: "",
      registered_twitter_account_2: "",
      registered_tweet_id: "",
      reword_amount: null,
      reword_list: [],
      gift_filled: false,
      reword_update: false,
      campaign_category: null,

      discordGuild: null,
      discordInviteUrl: "",
      discordConnect: false,
      discordConditionExists: false,

      walletConditionExists: false,

      externalSiteUrl: "",
      externalSiteConditionExists: false,

      instagram_account_url: "",
      instagraFollowConditionExists: false,

      instagram_feed_url: "",
      instagraLikeConditionExists: false,

      instagram_keyword: "",
      instagraHashtagConditionExists: false,

      raffleEveryday: false,
      pointBase: false,

      twitter_account_2: null,
      twitter_account_id_2: null,
      twitter_account_response_2: null,
      twitter_account_result_2: null,
      twitter_account_check_2: false,
      follow_two_targets: false,

      taskId: 1,
      selectedOption: "", // 選択されたオプションを格納する変数
      options: [
        "Xフォロー＆リポスト",
        "外部サイト遷移",
        "Discord参加",
        "Walletアドレス登録",
        // "Instagramフォロー",
        // "Instagramハッシュタグ投稿",
      ], // 選択肢の配列
    };
  },
  mounted() {
    const route = useRoute();
    this.campaign_id = route.params.id;
    this.getConditions();
    this.getCampaign();
    this.getDiscordInfo();
  },
  computed: {
    plan: function () {
      return this.$store.getters["subscribePlan"];
    },
    raffleEveryday_display: function () {
      if (this.raffleEveryday == 1) {
        return "ON";
      } else {
        return "OFF";
      }
    },
    pointbase_display: function () {
      if (this.pointBase == 1) {
        return "ON";
      } else {
        return "OFF";
      }
    },
  },
  watch: {
    // twitterアカウントのテキストボックス
    twitter_account(value) {
      this.twitter_account_check = false;
      this.twitter_account_result = "";
      if (value.indexOf("@")) {
        this.twitter_account = "@" + value;
      }
    },
    twitter_account_2(value) {
      this.twitter_account_check_2 = false;
      this.twitter_account_result_2 = "";
      if (value.indexOf("@")) {
        this.twitter_account_2 = "@" + value;
      }
    },
    // tweet URLのテキストボックス
    tweet_url() {
      this.tweet_url_check = false;
      this.tweet_url_result = "";
      console.log(this.tweet_url);
      // x.comはAPIとしては無効なURLなので、twitter.comに置換する
      if (this.tweet_url.includes("https://x.com/") == true) {
        this.tweet_url = this.tweet_url.replace(
          "https://x.com/",
          "https://twitter.com/"
        );
      }
      // 謎のクエリパラメータが混入する可能性があるが、不要なので削除する
      if (this.tweet_url.includes("?") == true) {
        this.tweet_url = this.tweet_url.split("?")[0];
      }
    },
    instagram_keyword(value) {
      if (value.indexOf("#")) {
        this.instagram_keyword = "#" + value;
      }
    },
    reword_list: {
      handler() {
        let _ = true;
        for (var i = 0; i < this.reword_list.length; i++) {
          if (this.reword_list[i][0].length == 0) {
            this.gift_filled = false;
            _ = false;
            break;
          }
          if (this.reword_list[i][1].length == 0) {
            this.gift_filled = false;
            _ = false;
            break;
          }
        }
        if (_ == true) {
          this.gift_filled = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    getConditions: function () {
      this.taskList = [];
      this.conditions_tweet = [];
      this.conditions_twitter_account = [];
      this.discordConditionExists = false;
      this.walletConditionExists = false;
      this.instagramFollowConditionExists = false;
      this.instagram_account_url = "";
      this.instagramLikeConditionExists = false;
      this.instagram_feed_url = "";
      this.externalSiteConditionExists = false;
      this.externalSiteUrl = "";
      this.instagramHashtagConditionExists = false;
      this.instagram_keyword = "";

      api
        .getConditions({
          campaign_id: this.$route.params.id,
          twitter_api_call: 0,
        })
        .then((response) => {
          console.log(response);
          let response_data = response["data"];
          for (var i in response_data) {
            if (response_data[i]["actions"] == "FOLLOW") {
              this.conditions_twitter_account.push({
                user_id: response_data[i]["user_id"],
                username: response_data[i]["username"],
                followers: response_data[i]["followers"],
              });
              if (this.twitter_account == "") {
                this.twitter_account = response_data[i]["username"];
                this.registered_twitter_account = response_data[i]["user_id"];
                console.log("twitter account null");
              } else {
                // フォロー対象が二つあるパターン
                this.twitter_account_2 = response_data[i]["username"];
                this.registered_twitter_account_2 = response_data[i]["user_id"];
                this.follow_two_targets = true;
                console.log("twitter account not null");
              }
            } else if (response_data[i]["actions"] == "RETWEET") {
              this.conditions_tweet.push({
                tweet_id: response_data[i]["tweet_id"],
                username: response_data[i]["username"],
                text: response_data[i]["text"],
              });
              this.twitter_account_check = true;
              this.tweet_url_check = true;
              this.tweet_url =
                "https://twitter.com/" +
                response_data[i]["username"] +
                "/status/" +
                response_data[i]["tweet_id"];
              this.registered_tweet_id = response_data[i]["tweet_id"];
              this.taskList.push({
                id: i,
                taskType: "Xフォロー＆リポスト",
                registed: true,
                point: response_data[i]["points"],
              });
            } else if (response_data[i]["actions"] == "DISCORD_SERVER_JOIN") {
              this.discordConditionExists = true;
              this.discordInviteUrl = response_data[i]["invite_url"];
              this.taskList.push({
                id: i,
                taskType: "Discord参加",
                registed: true,
                point: response_data[i]["points"],
              });
            } else if (response_data[i]["actions"] == "WALLET_CONNECT") {
              this.walletConditionExists = true;
              this.taskList.push({
                id: i,
                taskType: "Walletアドレス登録",
                registed: true,
                point: response_data[i]["points"],
              });
            } else if (response_data[i]["actions"] == "EXTERNAL_SITE_VISIT") {
              // this.externalSiteConditionExists = true;
              // this.externalSiteUrl = response_data[i]["external_site_url"];
              this.taskList.push({
                id: i,
                taskType: "外部サイト遷移",
                registed: true,
                externalSiteUrl: response_data[i]["external_site_url"],
                condition_id: response_data[i]["condition_id"],
                point: response_data[i]["points"],
              });
            } else if (response_data[i]["actions"] == "INSTAGRAM_FOLLOW") {
              this.instagramFollowConditionExists = true;
              this.instagram_account_url =
                response_data[i]["external_site_url"];
            } else if (response_data[i]["actions"] == "INSTAGRAM_LIKE") {
              this.instagramLikeConditionExists = true;
              this.instagram_feed_url = response_data[i]["external_site_url"];
            } else if (response_data[i]["actions"] == "INSTAGRAM_HASHTAG") {
              this.instagramHashtagConditionExists = true;
              this.instagram_keyword = response_data[i]["keyword"];
            }
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          this.$toast.show(
            "条件の取得に失敗しました。時間を置いて再度お試しください。",
            {
              type: "error",
              position: "top-right",
              duration: 5000,
            }
          );
        });
    },
    createTwitterConditionPromise(item) {
      return new Promise((resolve) => {
        this.createFollowConditionPromise(item.point, item.must).then(() => {
          this.createRetweetConditionPromise(item.point, item.must).then(() => {
            this.getConditions();
            resolve("resolved");
          });
        });
      });
    },
    createFollowConditionPromise(point, must) {
      return new Promise((resolve) => {
        console.log(this.twitter_account_id);
        console.log(this.twitter_account_id_2);
        api
          .createConditions({
            campaign_id: this.$route.params.id,
            user_id: this.twitter_account_id,
            actions: "FOLLOW",
            points: point,
            must_complete: must,
          })
          .then(() => {
            // this.twitter_account = "@";
            // this.twitter_account_check = false;
            this.$toast.show("フォロー条件の追加が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            if (this.follow_two_targets == true) {
              api
                .createConditions({
                  campaign_id: this.$route.params.id,
                  user_id: this.twitter_account_id_2,
                  actions: "FOLLOW",
                  points: point,
                  must_complete: must,
                })
                .then(() => {
                  // this.twitter_account_2 = "@";
                  // this.twitter_account_check_2 = false;
                  this.$toast.show("フォロー条件の追加が完了しました", {
                    type: "success",
                    position: "top-right",
                    duration: 3000,
                  });
                  resolve("resolved");
                })
                .catch((error) => {
                  this.$toast.show("フォロー条件の追加に失敗しました", {
                    type: "error",
                    position: "top-right",
                    duration: 5000,
                  });
                  console.log("error: ", error);
                  this.twitter_account_check = false;
                  resolve("rejected");
                });
            } else {
              resolve("resolved");
            }
          })
          .catch((error) => {
            this.$toast.show("フォロー条件の追加に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            this.twitter_account_check = false;
            resolve("rejected");
          });
      });
    },
    createRetweetConditionPromise(point, must) {
      return new Promise((resolve) => {
        console.log(this.$route.params.id, this.tweet_id);
        api
          .createConditions({
            campaign_id: this.$route.params.id,
            tweet_id: this.tweet_id,
            actions: "RETWEET",
            points: point,
            must_complete: must,
          })
          .then(() => {
            this.conditions_tweet_url = [];
            this.tweet_url = "";
            this.tweet_url_check = false;
            this.$toast.show("リツイート条件の追加が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("リツイート条件の追加に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    deleteTwitterConditionPromise() {
      return new Promise((resolve) => {
        this.deleteFollowConditionPromise().then(() => {
          this.deleteRetweetConditionPromise().then(() => {
            this.getConditions();
            resolve("resolved");
          });
        });
      });
    },
    deleteFollowConditionPromise() {
      return new Promise((resolve) => {
        api
          .deletefollowConditions({
            campaign_id: this.$route.params.id,
            user_id: this.registered_twitter_account,
          })
          .then(() => {
            this.twitter_account = "@";
            this.twitter_account_check = false;
            this.$toast.show("フォロー条件の削除が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            if (this.follow_two_targets == true) {
              api
                .deletefollowConditions({
                  campaign_id: this.$route.params.id,
                  user_id: this.registered_twitter_account_2,
                })
                .then(() => {
                  this.twitter_account_2 = "@";
                  this.twitter_account_check_2 = false;
                  this.$toast.show("フォロー条件の削除が完了しました", {
                    type: "success",
                    position: "top-right",
                    duration: 3000,
                  });
                  resolve("resolved");
                });
            } else {
              resolve("resolved");
            }
          })
          .catch((error) => {
            this.$toast.show("フォロー条件の削除に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    deleteRetweetConditionPromise() {
      return new Promise((resolve) => {
        api
          .deleteretweetConditions({
            campaign_id: this.$route.params.id,
            tweet_id: this.registered_tweet_id,
          })
          .then(() => {
            this.tweet_url = "";
            this.tweet_url_check = false;
            this.$toast.show("リツイート条件の削除が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("リツイート条件の削除に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    createDiscordConditionPromise(item) {
      return new Promise((resolve) => {
        api
          .createConditions({
            campaign_id: this.$route.params.id,
            guild_id: this.discordGuild.item_data_1,
            actions: "DISCORD_SERVER_JOIN",
            invite_url: this.discordInviteUrl,
            points: item.point,
            must_complete: item.must,
          })
          .then(() => {
            this.getConditions();
            this.$toast.show("Discord条件の追加が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("Discord条件の追加に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    deleteDiscordConditionPromise() {
      return new Promise((resolve) => {
        api
          .deletediscordConditions({
            campaign_id: this.$route.params.id,
            guild_id: this.discordGuild.item_data_1,
          })
          .then(() => {
            this.$toast.show("Discord条件の削除が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            this.getConditions();
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("Discord条件の削除に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    createWalletConditionPromise(item) {
      return new Promise((resolve) => {
        api
          .createConditions({
            campaign_id: this.$route.params.id,
            actions: "WALLET_CONNECT",
            points: item.point,
            must_complete: item.must,
          })
          .then(() => {
            this.getConditions();
            this.$toast.show("Wallet条件の追加が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("Wallet条件の追加に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    deleteWalletConditionPromise() {
      return new Promise((resolve) => {
        api
          .deleteWalletConditions({
            campaign_id: this.$route.params.id,
          })
          .then(() => {
            this.$toast.show("Wallet条件の削除が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            this.getConditions();
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("Wallet条件の削除に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    createExternalSiteConditionPromise(item) {
      return new Promise((resolve) => {
        api
          .createConditions({
            campaign_id: this.$route.params.id,
            external_site_url: item.url,
            actions: "EXTERNAL_SITE_VISIT",
            points: item.point,
            must_complete: item.must,
          })
          .then(() => {
            this.getConditions();
            this.$toast.show("外部サイト条件の追加が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("外部サイト条件の追加に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    deleteExternalSiteConditionPromise() {
      return new Promise((resolve) => {
        api
          .deleteExternalSiteConditions({
            campaign_id: this.$route.params.id,
          })
          .then(() => {
            this.$toast.show("外部サイト条件の削除が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            this.getConditions();
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("外部サイト条件の削除に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    getCampaign() {
      api
        .getCampaign({ id: this.$route.params.id })
        .then((response) => {
          this.campaign_category = response["data"][0]["category"];
          this.campaign_status = response["data"][0]["status"];
          this.reword_amount = response["data"][0]["amount"];
          for (var i = 0; i < this.reword_amount; i++) {
            this.reword_list.push(["", "", ""]);
          }
          api
            .getGifts({
              campaign_id: this.$route.params.id,
            })
            .then((response) => {
              console.log(response);
              if (response["data"].length != 0) {
                for (var i = 0; i < this.reword_amount; i++) {
                  this.reword_list[i][0] = response["data"][i]["label"];
                  this.reword_list[i][1] = response["data"][i]["code"];
                  this.reword_list[i][2] = response["data"][i]["gift_id"];
                  this.reword_update = true;
                }
              }
            })
            .catch((error) => {
              this.$toast.show("ギフトの取得に失敗しました", {
                type: "error",
                position: "top-right",
                duration: 5000,
              });
              console.log("error: ", error);
            });
          this.getInstantwin();
        })
        .catch((error) => {
          this.$toast.show("キャンペーンの取得に失敗しました", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
          console.log("error: ", error);
        });
    },
    getInstantwin() {
      if (this.campaign_category == "INSTANT_WIN") {
        api
          .getInstantWin({ campaign_id: this.$route.params.id })
          .then((response) => {
            console.log(response);
            this.raffleEveryday = response.data[0].raffle_everyday;
            this.pointBase = response.data[0].pointbase;
          })
          .catch((error) => {
            this.$toast.show("キャンペーン情報の取得に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
          });
      }
    },
    createGiftsPromise() {
      return new Promise((resolve) => {
        api
          .createGifts({
            campaign_id: this.$route.params.id,
            gifts: this.reword_list,
          })
          .then(() => {
            this.$toast.show("ギフトの登録が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            this.$router.go({
              name: "addConditions",
              params: { id: this.$route.params.id },
            });
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("ギフトの登録に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    updateGiftsPromise() {
      return new Promise((resolve) => {
        api
          .updateGifts({
            campaign_id: this.$route.params.id,
            gifts: this.reword_list,
          })
          .then(() => {
            this.$toast.show("ギフトの変更が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            this.$router.go({
              name: "addConditions",
              params: { id: this.$route.params.id },
            });
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("ギフトの変更に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    async handleFileSelect(e) {
      // 読み込んだCSVファイルを配列に変換
      const file = this.parseCSV(await e.target.files[0].text());

      // フォーマットチェック
      // 行数チェック
      if (file.length != this.reword_amount) {
        this.$toast.show(
          "CSVのデータ数が一致しません。ヘッダーなしでデータ行の数をギフトの数と一致させてください",
          {
            type: "error",
            position: "top-right",
            duration: 5000,
          }
        );
        return;
      }
      // 列数チェック
      for (let i = 0; i < this.reword_list.length; i++) {
        if (file[i].length != 2) {
          this.$toast.show(
            "CSVのデータ数が一致しません。各行は ラベル, コード の２列のみ入力してください",
            {
              type: "error",
              position: "top-right",
              duration: 5000,
            }
          );
          return;
        }
      }

      for (let i = 0; i < this.reword_list.length; i++) {
        this.reword_list[i][0] = file[i][0];
        this.reword_list[i][1] = file[i][1];
      }
      this.reword_list.splice;
      this.$toast.show("CSVの読み込みが完了しました", {
        type: "success",
        position: "top-right",
        duration: 3000,
      });
    },
    parseCSV(data) {
      return data.split("\n").map((row) => row.split(","));
    },
    getDiscordInfo() {
      api
        .getItemsDiscord()
        .then((response) => {
          console.log(response);
          if (response.data.length != 0) {
            this.discordGuild = response.data[0];
            this.discordConnect = true;
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.$toast.show("Discordのデータ取得に失敗しました", {
            type: "error",
            position: "top",
            duration: 5000,
          });
        });
    },
    onRaffleEverydayPromise() {
      return new Promise((resolve) => {
        api
          .updateInstantWin({
            campaign_id: this.$route.params.id,
            raffle_everyday: 1,
          })
          .then(() => {
            this.getInstantwin();
            this.$toast.show("毎日抽選をONにしました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("毎日抽選の設定更新に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    offRaffleEverydayPromise() {
      return new Promise((resolve) => {
        api
          .updateInstantWin({
            campaign_id: this.$route.params.id,
            raffle_everyday: 0,
          })
          .then(() => {
            this.getInstantwin();
            this.$toast.show("毎日抽選をOFFにしました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            this.getConditions();
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("毎日抽選の設定更新に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    onPointBasePromise() {
      return new Promise((resolve) => {
        api
          .updateInstantWin({
            campaign_id: this.$route.params.id,
            pointbase: 1,
          })
          .then(() => {
            this.getInstantwin();
            this.$toast.show("ポイント形式をONにしました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("ポイント形式の設定更新に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    offPointBasePromise() {
      return new Promise((resolve) => {
        api
          .updateInstantWin({
            campaign_id: this.$route.params.id,
            pointbase: 0,
          })
          .then(() => {
            this.getInstantwin();
            this.$toast.show("ポイント形式をOFFにしました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            this.getConditions();
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("ポイント形式の設定更新に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    addTaskPanel() {
      this.taskList.push({ id: this.taskId, taskType: null, must: false });
      this.taskId += 1;
      console.log(this.taskList);
    },
    removeTaskList(id) {
      this.taskList = this.taskList.filter((task) => task.id !== id);
    },
    addFollow() {
      this.follow_two_targets = true;
    },
    checkTwitterAccountPromise2: function () {
      return new Promise((resolve) => {
        api
          .checkTwitterAccount({
            username: this.twitter_account_2,
          })
          .then((response) => {
            this.twitter_account_response_2 = response["data"];
            if (this.twitter_account_response_2 != "NotFound") {
              this.twitter_account_check_2 = true;
              this.twitter_account_id_2 = this.twitter_account_response_2["id"];
              this.twitter_account_result_2 =
                String(this.twitter_account_response_2["username"]) +
                " : " +
                String(this.twitter_account_response_2["description"]);
            } else {
              this.twitter_account_check_2 = false;
              this.twitter_account_result_2 =
                "入力いただいたusernameのアカウントは見つかりませんでした。";
            }
            resolve("resolve");
          })
          .catch((error) => {
            this.$toast.show("アカウントチェックに失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("reject");
          });
      });
    },
  },
};
</script>
../assets/mixins/Checks.js
