<template>
  <!-- モーダル -->
  <div>
    <div
      class="absolute left-0 top-0 z-30 w-full h-full bg-opacity-50 bg-black flex justify-center items-center"
      v-if="displayModal"
      @click="closeModal"
    >
      <div class="relative z-40 w-auto h-auto bg-white text-black rounded-lg">
        <div class="m-6">
          現在の内容でキャンペーンを確定します。よろしいですか？
        </div>
        <button
          class="inline-block py-2 px-4 text-xs text-center font-semibold leading-normal rounded-lg transition duration-200 m-4"
          :class="
            !fixLoading
              ? 'text-blue-50 bg-blue-500 hover:bg-blue-600'
              : 'text-blue-50 bg-blue-700 hover:bg-blue-800'
          "
          :disabled="fixLoading"
          @click="updateCampaignForReady"
        >
          <i class="pi pi-spin pi-spinner text-xs" v-if="fixLoading"></i>
          確定
        </button>
        <button
          class="inline-block py-2 px-4 text-xs text-center font-semibold leading-normal text-blue-50 bg-gray-300 hover:bg-gray-400 rounded-lg transition duration-200 m-4"
          @click="closeModal"
        >
          キャンセル
        </button>
      </div>
    </div>
    <div
      class="absolute left-0 top-0 z-30 w-full h-full bg-opacity-50 bg-black flex justify-center items-center"
      v-if="deleteModal"
      @click="closeModal"
    >
      <div class="relative z-40 w-auto h-auto bg-white text-black rounded-lg">
        <div class="m-6">本当に削除してよろしいですか？</div>
        <button
          class="inline-block py-2 px-4 text-xs text-center font-semibold leading-normal rounded-lg transition duration-200 m-4"
          :class="
            !fixLoading
              ? 'text-blue-50 bg-red-500 hover:bg-red-600'
              : 'text-blue-50 bg-red-700 hover:bg-red-800'
          "
          :disabled="fixLoading"
          @click="deleteCampaign"
        >
          <i class="pi pi-spin pi-spinner text-xs" v-if="fixLoading"></i>
          削除する
        </button>
        <button
          class="inline-block py-2 px-4 text-xs text-center font-semibold leading-normal text-blue-50 bg-gray-300 hover:bg-gray-400 rounded-lg transition duration-200 m-4"
          @click="closeModal"
        >
          キャンセル
        </button>
      </div>
    </div>
  </div>
  <div>
    <SideVar />
    <RightColumnOutline>
      <PageTitle>
        <div class="w-full flex items-center justify-between">
          <div>Campaigns</div>
          <div
            class="p-3 text-xs text-left text-gray-200 font-light bg-gray-400 rounded-lg border-2 border-gray-300"
          >
            <div class="mb-2 flex items-center">
              フォロー＆リツイート / ハッシュタグキャンペーン :
              <div class="text-white text-sm ml-2">
                残{{ small_campaign_count }}回
              </div>
            </div>
            <div class="flex items-center">
              インスタントウィン :
              <div class="text-white text-sm ml-2">
                残{{ big_campaign_count }}回
              </div>
            </div>
          </div>
        </div>
      </PageTitle>
      <div class="flex justify-start ml-4 my-2 text-gray-100">
        <button
          class="px-2 mx-2 py-1 text-xs text-gray-100 font-semibold rounded-full hover:bg-blue-500"
          v-bind:class="
            view_switch == 'HASHTAG'
              ? 'bg-blue-700 hover:bg-blue-800 border border-gray-100'
              : 'bg-gray-500 hover:bg-gray-500 border border-gray-100'
          "
          @click="view_switch_change('HASHTAG')"
        >
          ハッシュタグ
        </button>
        <button
          class="px-2 mx-2 py-1 text-xs text-gray-100 font-semibold rounded-full hover:bg-blue-500"
          @click="view_switch_change('FOLLOW_AND_RETWEET')"
          v-bind:class="
            view_switch == 'FOLLOW_AND_RETWEET'
              ? 'bg-blue-700 hover:bg-blue-800 border border-gray-100'
              : 'bg-gray-500 hover:bg-gray-500 border border-gray-100'
          "
        >
          フォロー＆リポスト
        </button>
        <button
          class="px-2 mx-2 py-1 text-xs text-gray-100 font-semibold rounded-full hover:bg-blue-500"
          @click="view_switch_change('INSTANT_WIN')"
          v-bind:class="
            view_switch == 'INSTANT_WIN'
              ? 'bg-blue-700 hover:bg-blue-800 border border-gray-100'
              : 'bg-gray-500 hover:bg-gray-500 border border-gray-100'
          "
        >
          インスタントウィン
        </button>
      </div>
      <!-- ハッシュタグのtable -->
      <div
        class="w-full mt-6 pb-6 overflow-x-auto"
        v-if="view_switch == 'HASHTAG'"
      >
        <table class="w-full min-w-max">
          <thead>
            <tr class="text-left">
              <th class="p-0">
                <div class="py-3 px-6 rounded-l-xl bg-gray-600">
                  <span class="text-xs text-gray-300 font-semibold">NAME</span>
                </div>
              </th>
              <th class="p-0">
                <div class="py-3 px-6 bg-gray-600">
                  <span class="text-xs text-gray-300 font-semibold"
                    >KEYWORD</span
                  >
                </div>
              </th>
              <th class="p-0">
                <div class="py-3 px-6 bg-gray-600">
                  <span class="text-xs text-gray-300 font-semibold"
                    >STATUS</span
                  >
                </div>
              </th>
              <th class="p-0">
                <div class="py-3 px-6 bg-gray-600 text-center">
                  <span class="text-xs text-gray-300 font-semibold">START</span>
                </div>
              </th>
              <th class="p-0">
                <div class="py-3 px-6 bg-gray-600 text-center">
                  <span class="text-xs text-gray-300 font-semibold">END</span>
                </div>
              </th>
              <th class="p-0">
                <div class="py-6 px-6 bg-gray-600"></div>
              </th>
              <th class="p-0">
                <div class="py-6 px-6 bg-gray-600"></div>
              </th>
              <th class="p-0">
                <div class="py-6 px-6 bg-gray-600"></div>
              </th>
              <th class="p-0">
                <div class="py-6 px-6 bg-gray-600 rounded-r-xl"></div>
              </th>
            </tr>
          </thead>
          <!-- start table records -->
          <tbody>
            <tr
              v-for="campaign in campaign_dict.HASHTAG"
              v-bind:key="campaign.campaign_id"
            >
              <td class="p-0">
                <div class="flex items-center h-16 px-6">
                  <span class="text-sm text-gray-100 font-medium text-left">{{
                    campaign.campaign_name
                  }}</span>
                </div>
              </td>
              <td class="p-0">
                <div class="flex items-center h-16 px-6">
                  <span class="text-sm text-gray-100 font-medium text-left"
                    >{{ campaign.keyword }}
                  </span>
                </div>
              </td>
              <td class="p-0">
                <div class="flex items-center h-16 px-6 w-32">
                  <span
                    class="text-sm font-medium text-left"
                    v-bind:class="statusColor(campaign.status)"
                  >
                    {{ statusConvert(campaign.status) }}
                  </span>
                </div>
              </td>
              <td class="p-0">
                <div class="flex items-center h-16 px-6">
                  <div class="flex h-full items-center">
                    <span class="text-sm font-medium text-gray-100">{{
                      campaign.start_time
                    }}</span>
                  </div>
                </div>
              </td>
              <td class="p-0">
                <div class="flex items-center h-16 px-6">
                  <span class="text-sm text-gray-100 font-medium">{{
                    campaign.end_time
                  }}</span>
                </div>
              </td>
              <td class="p-0">
                <div v-if="campaign.status == 1">
                  <router-link
                    :to="{
                      name: 'modifyCampaign',
                      params: { id: campaign.campaign_id },
                    }"
                  >
                    <button
                      class="inline-block w-8 h-8 text-xs text-center font-semibold leading-normal text-blue-50 rounded-lg transition duration-200 pi pi-file-edit bg-blue-500 hover:bg-blue-600"
                      v-tippy="{ content: '編集' }"
                    ></button>
                  </router-link>
                </div>
                <div v-else>
                  <router-link
                    :to="{
                      name: 'campaignDetail',
                      params: { id: campaign.campaign_id },
                    }"
                  >
                    <button
                      class="inline-block w-8 h-8 text-xs text-center font-semibold leading-normal text-blue-50 rounded-lg transition duration-200 pi pi-file-edit bg-yellow-800 hover:bg-yellow-900"
                      v-tippy="{ content: '詳細' }"
                    ></button>
                  </router-link>
                </div>
              </td>
              <td class="p-0">
                <router-link
                  :to="{
                    name: 'addOptions',
                    params: { id: campaign.campaign_id },
                  }"
                >
                  <button
                    class="inline-block w-8 h-8 font-semibold leading-normal text-blue-50 bg-blue-500 hover:bg-blue-600 rounded-lg transition duration-200 pi pi-plus-circle"
                    v-tippy="{ content: '追加設定' }"
                  ></button>
                </router-link>
              </td>
              <td class="p-0">
                <router-link
                  :to="{
                    name: 'analisys',
                    params: { id: campaign.campaign_id },
                  }"
                >
                  <button
                    class="inline-block w-8 h-8 text-xs text-center font-semibold leading-normal text-blue-50 rounded-lg transition duration-200 pi pi-chart-line"
                    :class="
                      campaign.status != 1
                        ? 'bg-blue-500 hover:bg-blue-600'
                        : 'bg-gray-700 hover:bg-gray-800'
                    "
                    :disabled="campaign.status == 1"
                    v-tippy="{ content: '分析' }"
                  ></button>
                </router-link>
              </td>
              <td class="p-0">
                <button
                  class="inline-block w-8 h-8 text-xs text-center font-semibold leading-normal text-blue-50 rounded-lg transition duration-200 pi pi-trash"
                  :class="
                    campaign.status == 1
                      ? 'bg-red-500 hover:bg-red-600'
                      : 'bg-gray-700 hover:bg-gray-800'
                  "
                  :disabled="campaign.status != 1"
                  v-tippy="{ content: '削除' }"
                  @click="showdeleteModal(campaign.campaign_id)"
                ></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- フォロー＆リツイートのtable -->
      <div
        class="w-full mt-6 pb-6 overflow-x-auto"
        v-if="view_switch == 'FOLLOW_AND_RETWEET'"
      >
        <table class="w-full min-w-max">
          <thead>
            <tr class="text-left">
              <th class="p-0">
                <div class="py-3 px-6 rounded-l-xl bg-gray-600">
                  <span class="text-xs text-gray-300 font-semibold">NAME</span>
                </div>
              </th>
              <th class="p-0">
                <div class="py-3 px-6 bg-gray-600">
                  <span class="text-xs text-gray-300 font-semibold"
                    >STATUS</span
                  >
                </div>
              </th>
              <th class="p-0">
                <div class="py-3 px-6 bg-gray-600">
                  <span class="text-xs text-gray-300 font-semibold text-center"
                    >END</span
                  >
                </div>
              </th>
              <th class="p-0">
                <div class="py-3 px-6 bg-gray-600">
                  <span class="text-xs text-gray-300 font-semibold"
                    >TARGET_USER</span
                  >
                </div>
              </th>
              <th class="p-0">
                <div class="py-3 px-6 bg-gray-600">
                  <span class="text-xs text-gray-300 font-semibold"
                    >TARGET_POST</span
                  >
                </div>
              </th>
              <th class="p-0">
                <div class="py-6 px-6 bg-gray-600"></div>
              </th>
              <th class="p-0">
                <div class="py-6 px-6 bg-gray-600"></div>
              </th>
              <th class="p-0">
                <div class="py-6 px-6 bg-gray-600"></div>
              </th>
              <th class="p-0">
                <div class="py-6 px-6 bg-gray-600 rounded-r-xl"></div>
              </th>
            </tr>
          </thead>
          <!-- FOLLOW_AND_RETWEETテーブル -->
          <tbody>
            <tr
              v-for="campaign in campaign_dict.FOLLOW_AND_RETWEET"
              v-bind:key="campaign.status"
            >
              <td class="p-0">
                <div class="flex items-center h-16 px-6">
                  <span class="text-sm text-gray-100 font-medium text-left">{{
                    campaign.campaign_name
                  }}</span>
                </div>
              </td>
              <td class="p-0">
                <div class="flex items-center h-16 px-6 w-32">
                  <button
                    v-if="campaign.status == 1"
                    class="inline-block py-2 px-4 text-xs text-center font-semibold leading-normal rounded-lg transition duration-200"
                    :class="
                      !modalLoading
                        ? 'text-blue-50 bg-blue-500 hover:bg-blue-600'
                        : 'text-blue-50 bg-blue-700 hover:bg-blue-800'
                    "
                    :disabled="modalLoading"
                    @click="showModal(campaign.campaign_id)"
                  >
                    <i
                      class="pi pi-spin pi-spinner text-xs"
                      v-if="modalLoading"
                    ></i>
                    内容確定
                  </button>
                  <span
                    v-else
                    class="text-sm font-medium text-left"
                    v-bind:class="statusColor(campaign.status)"
                  >
                    {{ statusConvert(campaign.status) }}
                  </span>
                </div>
              </td>
              <td class="p-0">
                <div class="flex items-center h-16 px-6">
                  <span class="text-sm text-gray-100 font-medium">{{
                    campaign.end_time
                  }}</span>
                </div>
              </td>
              <td class="p-0">
                <div class="flex items-center h-16 px-6">
                  <div class="flex h-full items-center">
                    <span class="text-sm font-medium text-blue-400 underline">
                      <a
                        :href="
                          'https://twitter.com/' +
                          conditions_dict[campaign.campaign_id][2]
                        "
                        target="_blank"
                        rel="noopener noreferrer"
                        >{{ conditions_dict[campaign.campaign_id][2] }}</a
                      ></span
                    >
                  </div>
                </div>
              </td>
              <td class="p-0">
                <div class="flex items-center h-16 px-6">
                  <div class="flex h-full items-center">
                    <span class="text-sm font-medium text-blue-400 underline">
                      <a
                        :href="
                          'https://twitter.com/' +
                          conditions_dict[campaign.campaign_id][2] +
                          '/status/' +
                          conditions_dict[campaign.campaign_id][1]
                        "
                        target="_blank"
                        rel="noopener noreferrer"
                        >{{ conditions_dict[campaign.campaign_id][1] }}</a
                      ></span
                    >
                  </div>
                </div>
              </td>
              <td class="p-0">
                <div v-if="campaign.status == 1">
                  <router-link
                    :to="{
                      name: 'modifyCampaign',
                      params: { id: campaign.campaign_id },
                    }"
                  >
                    <button
                      class="inline-block w-8 h-8 text-xs text-center font-semibold leading-normal text-blue-50 rounded-lg transition duration-200 pi pi-file-edit bg-blue-500 hover:bg-blue-600"
                      v-tippy="{ content: '編集' }"
                    ></button>
                  </router-link>
                </div>
                <div v-else>
                  <router-link
                    :to="{
                      name: 'campaignDetail',
                      params: { id: campaign.campaign_id },
                    }"
                  >
                    <button
                      class="inline-block w-8 h-8 text-xs text-center font-semibold leading-normal text-blue-50 rounded-lg transition duration-200 pi pi-file-edit bg-yellow-800 hover:bg-yellow-900"
                      v-tippy="{ content: '詳細' }"
                    ></button>
                  </router-link>
                </div>
              </td>
              <td class="p-0">
                <router-link
                  :to="{
                    name: 'addConditions',
                    params: { id: campaign.campaign_id },
                  }"
                >
                  <button
                    class="inline-block w-8 h-8 font-semibold leading-normal text-blue-50 rounded-lg transition duration-2000 pi pi-plus-circle"
                    :class="
                      campaign.status != 3
                        ? 'bg-blue-500 hover:bg-blue-600'
                        : 'bg-gray-700 hover:bg-gray-800'
                    "
                    :disabled="campaign.status == 3"
                    v-tippy="{ content: '条件設定' }"
                  ></button>
                </router-link>
              </td>
              <td class="p-0">
                <button v-if="campaign.category == 'FOLLOW_AND_RETWEET'">
                  <router-link
                    :to="{
                      name: 'analisysFaR',
                      params: { id: campaign.campaign_id },
                    }"
                  >
                    <button
                      class="inline-block w-8 h-8 text-xs text-center font-semibold leading-normal text-blue-50 rounded-lg transition duration-200 pi pi-chart-line"
                      :class="
                        campaign.status != 1
                          ? 'bg-blue-500 hover:bg-blue-600'
                          : 'bg-gray-700 hover:bg-gray-800'
                      "
                      :disabled="campaign.status == 1"
                      v-tippy="{ content: '分析' }"
                    ></button>
                  </router-link>
                </button>
              </td>
              <td class="p-0">
                <button
                  class="inline-block w-8 h-8 text-xs text-center font-semibold leading-normal text-blue-50 rounded-lg transition duration-200 pi pi-trash"
                  :class="
                    campaign.status == 1
                      ? 'bg-red-500 hover:bg-red-600'
                      : 'bg-gray-700 hover:bg-gray-800'
                  "
                  :disabled="campaign.status != 1"
                  v-tippy="{ content: '削除' }"
                  @click="showdeleteModal(campaign.campaign_id)"
                ></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- インスタントウィン -->
      <div
        class="w-full mt-6 pb-6 overflow-x-auto"
        v-if="view_switch == 'INSTANT_WIN'"
      >
        <table class="w-full min-w-max">
          <thead>
            <tr class="text-left">
              <th class="p-0">
                <div class="py-3 px-6 rounded-l-xl bg-gray-600">
                  <span class="text-xs text-gray-300 font-semibold">NAME</span>
                </div>
              </th>
              <th class="p-0">
                <div class="py-3 px-6 bg-gray-600">
                  <span class="text-xs text-gray-300 font-semibold"
                    >STATUS</span
                  >
                </div>
              </th>
              <th class="p-0">
                <div class="py-3 px-6 bg-gray-600">
                  <span class="text-xs text-gray-300 font-semibold text-center"
                    >END</span
                  >
                </div>
              </th>
              <th class="p-0">
                <div class="py-3 px-6 bg-gray-600">
                  <span class="text-xs text-gray-300 font-semibold text-center"
                    >TARGET_USER</span
                  >
                </div>
              </th>
              <th class="p-0">
                <div class="py-3 px-6 bg-gray-600">
                  <span class="text-xs text-gray-300 font-semibold"
                    >TOTAL_WIN</span
                  >
                </div>
              </th>
              <th class="p-0">
                <div class="py-3 px-6 bg-gray-600">
                  <span class="text-xs text-gray-300 font-semibold"
                    >REMIAIN_WIN</span
                  >
                </div>
              </th>
              <th class="p-0">
                <div class="py-6 px-6 bg-gray-600"></div>
              </th>
              <th class="p-0">
                <div class="py-6 px-6 bg-gray-600"></div>
              </th>
              <th class="p-0">
                <div class="py-6 px-6 bg-gray-600"></div>
              </th>
              <th class="p-0">
                <div class="py-6 px-6 bg-gray-600"></div>
              </th>
              <th class="p-0">
                <div class="py-6 px-6 bg-gray-600 rounded-r-xl"></div>
              </th>
            </tr>
          </thead>
          <!-- INSTANT WINテーブル -->
          <tbody>
            <tr
              v-for="campaign in campaign_dict.INSTANT_WIN"
              v-bind:key="campaign.status"
            >
              <td class="p-0">
                <div class="flex items-center h-16 px-6">
                  <span class="text-sm text-gray-100 font-medium text-left">{{
                    campaign.campaign_name
                  }}</span>
                </div>
              </td>
              <td class="p-0">
                <div class="flex items-center h-16 px-6 w-32">
                  <button
                    v-if="campaign.status == 1"
                    class="inline-block py-2 px-4 text-xs text-center font-semibold leading-normal rounded-lg transition duration-200"
                    :class="
                      !modalLoading
                        ? 'text-blue-50 bg-blue-500 hover:bg-blue-600'
                        : 'bg-gray-700 hover:bg-gray-800'
                    "
                    :disabled="modalLoading"
                    @click="showModal(campaign.campaign_id)"
                  >
                    <i
                      class="pi pi-spin pi-spinner text-xs"
                      v-if="modalLoading"
                    ></i>
                    内容確定
                  </button>
                  <span
                    class="text-sm font-medium text-left"
                    v-bind:class="statusColor(campaign.status)"
                    v-else
                  >
                    {{ statusConvert(campaign.status) }}
                  </span>
                </div>
              </td>
              <td class="p-0">
                <div class="flex items-center h-16 px-6">
                  <span class="text-sm text-gray-100 font-medium">{{
                    campaign.end_time
                  }}</span>
                </div>
              </td>
              <td class="p-0">
                <div class="flex items-center h-16 px-6">
                  <div class="flex h-full items-center">
                    <span class="text-sm font-medium text-blue-400 underline">
                      <a
                        :href="
                          'https://twitter.com/' +
                          conditions_dict[campaign.campaign_id][2]
                        "
                        target="_blank"
                        rel="noopener noreferrer"
                        >{{ conditions_dict[campaign.campaign_id][2] }}</a
                      ></span
                    >
                  </div>
                </div>
              </td>
              <td class="p-0">
                <div class="flex items-center h-16 px-6">
                  <span class="text-sm text-gray-100 font-medium">{{
                    instant_dict[campaign.campaign_id]?.total_win
                  }}</span>
                </div>
              </td>
              <td class="p-0">
                <div class="flex items-center h-16 px-6">
                  <span class="text-sm text-gray-100 font-medium">{{
                    instant_dict[campaign.campaign_id]?.remain_win
                  }}</span>
                </div>
              </td>
              <td class="p-0">
                <div v-if="campaign.status == 1">
                  <router-link
                    :to="{
                      name: 'modifyCampaign',
                      params: { id: campaign.campaign_id },
                    }"
                    @click="routeWithKey($event, campaign.campaign_id)"
                  >
                    <button
                      class="inline-block w-8 h-8 text-xs text-center font-semibold leading-normal text-blue-50 rounded-lg transition duration-200 pi pi-file-edit bg-blue-500 hover:bg-blue-600"
                      v-tippy="{ content: '編集' }"
                    ></button>
                  </router-link>
                </div>
                <div v-else>
                  <router-link
                    :to="{
                      name: 'campaignDetail',
                      params: { id: campaign.campaign_id },
                    }"
                  >
                    <button
                      class="inline-block w-8 h-8 text-xs text-center font-semibold leading-normal text-blue-50 rounded-lg transition duration-200 pi pi-file-edit bg-yellow-800 hover:bg-yellow-900"
                      v-tippy="{ content: '詳細' }"
                    ></button>
                  </router-link>
                </div>
              </td>
              <td class="p-0">
                <router-link
                  :to="{
                    name: 'addConditions',
                    params: { id: campaign.campaign_id },
                  }"
                >
                  <button
                    class="inline-block w-8 h-8 text-xs text-center font-semibold leading-normal text-blue-50 rounded-lg transition duration-200 pi pi-plus-circle"
                    :class="
                      campaign.status != 3
                        ? 'bg-blue-500 hover:bg-blue-600'
                        : 'bg-gray-700 hover:bg-gray-800'
                    "
                    :disabled="campaign.status == 3"
                    v-tippy="{ content: '条件設定' }"
                  ></button>
                </router-link>
              </td>
              <td class="p-0">
                <router-link
                  :to="{
                    name: 'createCampaignPage',
                    params: { id: campaign.campaign_id },
                  }"
                >
                  <button
                    class="inline-block w-8 h-8 text-xs text-center font-semibold leading-normal text-blue-50 bg-blue-500 hover:bg-blue-600 rounded-lg transition duration-200 pi pi-images"
                    v-tippy="{ content: 'キャンペーンページ登録' }"
                  ></button>
                </router-link>
              </td>
              <td class="p-0">
                <button v-if="campaign.category == 'INSTANT_WIN'">
                  <router-link
                    :to="{
                      name: 'analisysITW',
                      params: { id: campaign.campaign_id },
                    }"
                  >
                    <button
                      class="inline-block w-8 h-8 text-xs text-center font-semibold leading-normal text-blue-50 rounded-lg transition duration-200 pi pi-chart-line"
                      :class="
                        campaign.status != 1
                          ? 'bg-blue-500 hover:bg-blue-600'
                          : 'bg-gray-700 hover:bg-gray-800'
                      "
                      :disabled="campaign.status == 1"
                      v-tippy="{ content: '分析' }"
                    ></button>
                  </router-link>
                </button>
              </td>
              <td class="p-0">
                <button
                  class="inline-block w-8 h-8 text-xs text-center font-semibold leading-normal text-blue-50 rounded-lg transition duration-200 pi pi-trash"
                  :class="
                    campaign.status == 1
                      ? 'bg-red-500 hover:bg-red-600'
                      : 'bg-gray-700 hover:bg-gray-800'
                  "
                  :disabled="campaign.status != 1"
                  v-tippy="{ content: '削除' }"
                  @click="showdeleteModal(campaign.campaign_id)"
                ></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </RightColumnOutline>
  </div>
</template>

<script>
import api from "../api/api.js";
import moment from "moment";
import store from "@/store";

export default {
  name: "Campaign_list",
  components: {},
  data() {
    return {
      campaigns: null,
      campaign_dict: { HASHTAG: [], FOLLOW_AND_RETWEET: [], INSTANT_WIN: [] },
      conditions_dict: {},
      instant_dict: {},

      displayModal: false,
      fixTargetCampaignId: 0,
      giftCheck: false,

      modalLoading: false,
      fixLoading: false,

      deleteModal: false,
      deleteTargetCampaignId: 0,
    };
  },
  computed: {
    small_campaign_count: function () {
      return this.$store.getters["smallCampaignCount"];
    },
    big_campaign_count: function () {
      return this.$store.getters["bigCampaignCount"];
    },
    view_switch: function () {
      return this.$store.getters["viewSwitch"];
    },
  },
  mounted() {
    this.getCampaigns();
    this.checkSubscribe();
    this.getConditions();
    this.getInstantwins();
  },
  methods: {
    getCampaigns() {
      api
        .getCampaigns()
        .then((response) => {
          this.campaigns = response.data;
          // console.log(response);
          for (var i in this.campaigns) {
            let start_date = new Date(this.campaigns[i]["start_time"]);
            start_date.setHours(start_date.getHours() + 9); // 日本時間に変更
            let end_date = new Date(this.campaigns[i]["end_time"]);
            end_date.setHours(end_date.getHours() + 9); // 日本時間に変更
            this.campaigns[i]["start_time"] = moment(start_date).format(
              "YYYY-MM-DD HH:mm:SS"
            );
            this.campaigns[i]["end_time"] = moment(end_date).format(
              "YYYY-MM-DD HH:mm:SS"
            );
            if (this.campaigns[i]["category"] == "HASHTAG") {
              this.campaign_dict["HASHTAG"].push(this.campaigns[i]);
            }
            if (this.campaigns[i]["category"] == "FOLLOW_AND_RETWEET") {
              this.campaign_dict["FOLLOW_AND_RETWEET"].push(this.campaigns[i]);
              this.conditions_dict[this.campaigns[i].campaign_id] = [
                "",
                "",
                "",
              ];
            }
            if (this.campaigns[i]["category"] == "INSTANT_WIN") {
              this.campaign_dict["INSTANT_WIN"].push(this.campaigns[i]);
              this.conditions_dict[this.campaigns[i].campaign_id] = [
                "",
                "",
                "",
              ];
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getConditions() {
      api
        .getAllConditions()
        .then((response) => {
          const _conditions = response.data;
          for (var i in _conditions) {
            const campaign_id = _conditions[i].campaign_id;
            if (_conditions[i].actions == "FOLLOW") {
              if (this.conditions_dict[campaign_id][0].length == 0) {
                this.conditions_dict[campaign_id][0] = _conditions[i].user_id;
                this.conditions_dict[campaign_id][2] = _conditions[i].username;
              }
            }
            if (_conditions[i].actions == "RETWEET") {
              if (this.conditions_dict[campaign_id][1].length == 0) {
                this.conditions_dict[campaign_id][1] = _conditions[i].tweet_id;
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getInstantwins() {
      api
        .getInstantWins()
        .then((response) => {
          const _instantwin = response.data;
          for (var i in _instantwin) {
            const campaign_id = _instantwin[i].campaign_id;
            this.instant_dict[campaign_id] = _instantwin[i];
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    statusConvert(status) {
      switch (status) {
        case 1:
          return "開始待ち";
        case 2:
          return "開催中";
        case 3:
          return "終了";
      }
    },
    statusColor(status) {
      switch (status) {
        case 1:
          return "text-yellow-500";
        case 2:
          return "text-green-500";
        case 3:
          return "text-gray-300";
      }
    },
    view_switch_change(target) {
      store.commit("updateviewSwitch", target);
    },
    closeModal() {
      this.displayModal = false;
      this.deleteModal = false;
    },
    async showModal(campaign_id) {
      this.modalLoading = true;
      this.displayModal = true;
      this.fixTargetCampaignId = campaign_id;
      this.modalLoading = false;
    },
    async showdeleteModal(campaign_id) {
      this.modalLoading = true;
      this.deleteModal = true;
      this.deleteTargetCampaignId = campaign_id;
      this.modalLoading = false;
    },
    updateCampaignForReady() {
      this.fixLoading = true;
      api
        .getCampaign({ id: this.fixTargetCampaignId })
        .then((response) => {
          this.reword_amount = response["data"][0]["amount"];
          api
            .getGifts({
              campaign_id: this.fixTargetCampaignId,
            })
            .then((response) => {
              if (response["data"].length == this.reword_amount) {
                api
                  .updateCampaignForReady({
                    campaign_id: this.fixTargetCampaignId,
                  })
                  .then(() => {
                    this.$toast.show("キャンペーンを開始しました", {
                      type: "success",
                      position: "top-right",
                      duration: 3000,
                    });
                    this.fixLoading = false;
                    this.$router.go({ name: "campaigns" });
                  })
                  .catch((error) => {
                    this.$toast.show("キャンペーンの内容確定に失敗しました", {
                      type: "error",
                      position: "top-right",
                      duration: 5000,
                    });
                    console.error(error);
                    this.fixLoading = false;
                  });
              } else {
                this.$toast.show(
                  "当選人数と登録されたギフトの数が一致しません",
                  {
                    type: "error",
                    position: "top-right",
                    duration: 5000,
                  }
                );
                this.fixLoading = false;
              }
            })
            .catch((error) => {
              this.$toast.show("ギフトの確認に失敗しました", {
                type: "error",
                position: "top-right",
                duration: 5000,
              });
              console.log("error: ", error);
              this.fixLoading = false;
            });
        })
        .catch((error) => {
          this.$toast.show("キャンペーンの確認に失敗しました", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
          console.log("error: ", error);
          this.fixLoading = false;
        });
    },
    deleteCampaign() {
      this.fixLoading = true;
      api
        .deleteCampaign({ id: this.deleteTargetCampaignId })
        .then(() => {
          this.$toast.show("キャンペーンの削除に成功しました", {
            type: "success",
            position: "top-right",
            duration: 3000,
          });
          this.fixLoading = false;
          this.$router.go({ name: "campaigns" });
        })
        .catch((error) => {
          this.$toast.show("キャンペーンの削除に成功しました", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
          console.error(error);
          this.fixLoading = false;
        });
    },
    checkSubscribe() {
      api
        .checkSubscribe()
        .then((response) => {
          store.commit("updateSubscribePlan", response["data"]);
          this.$router.replace("/campaigns");
        })
        .catch((error) => {
          this.$toast.show("プラン取得に失敗しました", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
          console.log("subscribe error:", error);
          this.$router.replace("/campaigns");
        });
    },
    routeWithKey(event, campaign_id) {
      if (event.altKey) {
        event.preventDefault(); // デフォルトのブラウザ動作をキャンセル
        this.$router.push({
          name: "addConditionsByPanel",
          params: { id: campaign_id },
        });
      }
    },
  },
};
</script>
