<template>
  <div class="AboutUs">
    <Header />
    <section class="relative py-32 bg-white overflow-hidden">
      <img
        class="absolute left-0 top-0"
        src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/elipse.svg"
        alt=""
      />
      <div class="grid grid-cols-12">
        <div class="col-span-2"></div>
        <div class="col-span-8">
          <p class="text-lg md:text-5xl text-left border-b">会社概要</p>
          <table
            class="table-auto text-left w-full text-xs md:text-base border-separate border-spacing-2 md:border-spacing-5"
          >
            <thead>
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody class="my-3">
              <tr>
                <td class="border-b">商号</td>
                <td class="border-b">ソーシャルマーケティング株式会社</td>
              </tr>
              <tr>
                <td class="border-b">住所</td>
                <td class="border-b">東京都文京区本郷4-1-4</td>
              </tr>
              <tr>
                <td class="border-b">設立</td>
                <td class="border-b">2023年1月11日</td>
              </tr>
              <tr>
                <td class="border-b">代表取締役</td>
                <td class="border-b">相原 和明</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-span-2"></div>
      </div>
    </section>
    <!-- footter -->
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/FooterParts.vue";
import Header from "@/components/HeaderParts.vue";
export default {
  name: "AboutUs",
  components: {
    Footer,
    Header,
  },
  mounted() {},
  computed: {},
};
</script>
