<template>
  <div class="SignIn_page">
    <section class="relative pt-12">
      <div class="container px-4 mx-auto">
        <div class="h-full">
          <div class="relative max-w-xs mx-auto text-center">
            <a
              class="w-24 inline-block mx-auto mb-10"
              href="https://social-marketing.io"
            >
              <img
                class="block"
                src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/sociama_01@4x.png"
              />
            </a>
            <h2 class="text-2xl text-gray-500 font-semibold mb-2">
              Create your account
            </h2>
            <br />
            <div
              class="relative w-full h-14 py-4 px-3 border border-gray-400 hover:border-blue focus-within:border-green-500 rounded-lg"
              :class="{ 'bg-gray-300': isChecked, '': !isChecked }"
            >
              <span
                class="absolute bottom-full left-0 ml-3 -mb-1 transform translate-y-0.5 text-xs font-semibold text-gray-600 px-1 bg-gray-200"
                >Organization name / Project name<i
                  class="pi pi-question-circle m-1"
                  v-tippy="{
                    content:
                      '当サービスを利用する団体名、プロジェクト名を入力してください',
                  }"
                ></i
              ></span>
              <input
                class="block w-full outline-none bg-transparent text-sm text-gray-600 font-medium placeholder-gray-300"
                id="signUpInput1"
                type="text"
                :disabled="isChecked"
                placeholder="example inc."
                v-model="name"
              />
            </div>
            <div class="flex justifi-left items-center my-2 mb-6">
              <div class="text-xs text-left mr-2">
                すでに存在するOrganizerに参加する場合はこちら
              </div>
              <input
                type="checkbox"
                id="checkbox"
                v-model="isChecked"
                class="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              />
            </div>
            <div
              class="relative w-full h-14 py-4 px-3 mb-8 border border-gray-400 hover:border-blue focus-within:border-green-500 rounded-lg"
              v-if="isChecked"
            >
              <span
                class="absolute bottom-full left-0 ml-3 -mb-1 transform translate-y-0.5 text-xs font-semibold text-gray-600 px-1 bg-gray-200"
                >Invite code</span
              >
              <input
                class="block w-full outline-none bg-transparent text-sm text-gray-600 font-medium placeholder-gray-300"
                id="invite_code"
                type="invite_code"
                placeholder="ABCDEFGHIJ"
                v-model="inviteCode"
              />
            </div>
            <div
              class="relative w-full h-14 py-4 px-3 mb-8 border border-gray-400 hover:border-blue focus-within:border-green-500 rounded-lg"
            >
              <span
                class="absolute bottom-full left-0 ml-3 -mb-1 transform translate-y-0.5 text-xs font-semibold text-gray-600 px-1 bg-gray-200"
                >Email</span
              >
              <input
                class="block w-full outline-none bg-transparent text-sm text-gray-600 font-medium placeholder-gray-300"
                id="email"
                type="email"
                placeholder="mail-address@example.com"
                v-model="email"
              />
            </div>
            <div
              class="relative w-full h-14 py-4 px-3 mb-6 border border-gray-400 hover:border-blue focus-within:border-green-500 rounded-lg"
            >
              <span
                class="absolute bottom-full left-0 ml-3 -mb-1 transform translate-y-0.5 text-xs font-semibold text-gray-600 px-1 bg-gray-200"
                >Password</span
              >
              <input
                class="block w-full outline-none bg-transparent text-sm text-gray-600 font-medium placeholder-gray-300"
                id="password"
                type="password"
                placeholder="password"
                v-model="password"
              />
            </div>
            <div v-if="!isChecked">
              <button
                class="block w-full py-4 mb-4 leading-6 text-white font-semibold rounded-lg transition duration-200"
                :class="
                  name != '' && email != '' && password != '' && !loading
                    ? 'bg-blue-500 hover:bg-blue-600'
                    : 'bg-gray-500 hover:bg-gray-600'
                "
                :disabled="!name || !email || !password || loading"
                @click="signUp"
              >
                <i class="pi pi-spin pi-spinner text-sm" v-if="loading"></i>
                Sign Up
              </button>
            </div>
            <div v-else>
              <button
                class="block w-full py-4 mb-4 leading-6 text-white font-semibold rounded-lg transition duration-200"
                :class="
                  inviteCode != '' && email != '' && password != '' && !loading
                    ? 'bg-blue-500 hover:bg-blue-600'
                    : 'bg-gray-500 hover:bg-gray-600'
                "
                :disabled="!inviteCode || !email || !password || loading"
                @click="signUpWithInvite"
              >
                <i class="pi pi-spin pi-spinner text-sm" v-if="loading"></i>
                Sign Up
              </button>
            </div>
            <p class="font-medium">
              <span class="text-gray-300"
                >Do you have any account already?
              </span>
              <router-link to="/login">
                <a class="inline-block text-blue-500 hover:underline" href="#">
                  Sign in</a
                >
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
} from "@firebase/auth";
import api from "@/api/api.js";
import store from "@/store";
import "@/firebase_config";

const auth = getAuth();

export default {
  data() {
    return {
      name: "",
      email: "",
      password: "",
      inviteCode: "",
      loading: false,
      isChecked: false,
    };
  },
  methods: {
    signUp: function () {
      var user = null;
      this.loading = true;
      createUserWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          user = userCredential.user;
          api
            .createOrganizer({
              name: this.name,
              small_campaign_count: 0,
              big_campaign_count: 0,
            })
            .then((organizer) => {
              api
                .createAccount({
                  organizer_id: organizer.data.organizer_id,
                  email: this.email,
                })
                .then(() => {
                  sendEmailVerification(user, {
                    url: "http://social-marketing.io/login",
                  });
                  store.commit("updateUser", null);
                  this.$router.replace("/sent-mail");
                })
                .catch((error) => {
                  this.$toast.show("アカウントの作成に失敗しました", {
                    type: "error",
                    position: "top-right",
                    duration: 5000,
                  });
                  store.commit("updateUser", null);
                  console.log(error);
                });
              this.loading = false;
            })
            .catch((error) => {
              this.$toast.show("組織の作成に失敗しました", {
                type: "error",
                position: "top-right",
                duration: 5000,
              });
              store.commit("updateUser", null);
              console.log(error);
              this.loading = false;
            });
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/email-already-in-use":
              this.$toast.show("このアドレスは既に登録済です", {
                type: "error",
                position: "top-right",
                duration: 5000,
              });
              break;
            case "auth/invalid-email":
              this.$toast.show("アドレスのフォーマットが正しくありません", {
                type: "error",
                position: "top-right",
                duration: 5000,
              });
              break;
            default:
              this.$toast.show("エラーが発生しました", {
                type: "error",
                position: "top-right",
                duration: 5000,
              });
              console.log(error.code + ": " + error.message);
          }
          this.loading = false;
        });
    },
    signUpWithInvite: function () {
      var user = null;
      this.loading = true;

      createUserWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          user = userCredential.user;
          api
            .getOrganizerByInvite({
              invite_code: this.inviteCode,
            })
            .then((organizer) => {
              api
                .createAccount({
                  organizer_id: organizer.data.organizer_id,
                  email: this.email,
                })
                .then(() => {
                  sendEmailVerification(user, {
                    url: "http://social-marketing.io/login",
                  });
                  store.commit("updateUser", null);
                  this.$router.replace("/sent-mail");
                })
                .catch((error) => {
                  this.$toast.show("アカウントの作成に失敗しました", {
                    type: "error",
                    position: "top-right",
                    duration: 5000,
                  });
                  store.commit("updateUser", null);
                  console.log(error);
                });
              this.loading = false;
            })
            .catch((error) => {
              this.$toast.show("存在しないInviteコードです", {
                type: "error",
                position: "top- right",
                duration: 5000,
              });
              store.commit("updateUser", null);
              console.log(error);
              this.loading = false;
            });
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/email-already-in-use":
              this.$toast.show("このアドレスは既に登録済です", {
                type: "error",
                position: "top-right",
                duration: 5000,
              });
              break;
            case "auth/invalid-email":
              this.$toast.show("アドレスのフォーマットが正しくありません", {
                type: "error",
                position: "top-right",
                duration: 5000,
              });
              break;
            default:
              this.$toast.show("エラーが発生しました", {
                type: "error",
                position: "top-right",
                duration: 5000,
              });
              console.log(error.code + ": " + error.message);
          }
          this.loading = false;
        });
    },
  },
};
</script>
