<template>
  <div class="container mx-auto p-4">
    <h1 class="text-2xl font-bold mb-4">ホーム通知一覧</h1>

    <div class="flex justify-between mb-4">
      <input
        v-model="searchQuery"
        class="border p-2 rounded w-1/3"
        placeholder="タイトルで検索..."
      />
      <button
        @click="navigateToCreateNotification"
        class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
      >
        新規通知作成
      </button>
    </div>

    <EasyDataTable
      :headers="headers"
      :items="notifications"
      :search-value="searchQuery"
      :sort-by="sortBy"
      :sort-type="sortType"
      :rows-per-page="15"
      :rows-items="[15, 25, 50, 100]"
      buttons-pagination
      @click-row="handleRowClick"
      @sort="handleSort"
    >
      <template #item-title="item">
        <input
          :value="item.title"
          @input="updateField(item, 'title', $event.target.value)"
          class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
        />
      </template>
      <template #item-content="item">
        <input
          :value="item.content"
          @input="updateField(item, 'content', $event.target.value)"
          class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
        />
      </template>
      <template #item-notification_type="item">
        <select
          :value="item.notification_type"
          @change="updateField(item, 'notification_type', $event.target.value)"
          class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
        >
          <option value="CAMPAIGN">キャンペーン</option>
          <option value="EVENT">イベント</option>
          <option value="MAINTENANCE">メンテナンス</option>
          <option value="UPDATE">アップデート</option>
        </select>
      </template>
      <template #item-priority="item">
        <input
          type="number"
          :value="item.priority"
          @input="updateField(item, 'priority', Number($event.target.value))"
          class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
        />
      </template>
      <template #item-target_audience="item">
        <select
          :value="item.target_audience"
          @change="updateField(item, 'target_audience', $event.target.value)"
          class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
        >
          <option value="ALL">全員</option>
          <option value="ANDROID">Android</option>
          <option value="IOS">iOS</option>
        </select>
      </template>
      <template #item-start_at="item">
        <input
          type="datetime-local"
          :value="convertToLocalDateTime(item.start_at)"
          @input="updateField(item, 'start_at', $event.target.value)"
          class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
        />
      </template>
      <template #item-end_at="item">
        <input
          type="datetime-local"
          :value="convertToLocalDateTime(item.end_at)"
          @input="updateField(item, 'end_at', $event.target.value)"
          class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
        />
      </template>
      <template #item-is_active="item">
        <input
          type="checkbox"
          :checked="item.is_active"
          @change="updateField(item, 'is_active', $event.target.checked)"
          class="rounded"
        />
      </template>
      <template #item-actions="item">
        <button
          @click="updateNotification(item)"
          class="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600 mr-2"
        >
          更新
        </button>
        <button
          @click="toggleActive(item)"
          :class="
            item.is_active
              ? 'bg-red-500 hover:bg-red-600'
              : 'bg-blue-500 hover:bg-blue-600'
          "
          class="text-white px-2 py-1 rounded"
        >
          {{ item.is_active ? "非表示" : "表示" }}
        </button>
      </template>
    </EasyDataTable>
  </div>
</template>

<script>
import { ref, onMounted, inject } from "vue";
import { useRouter } from "vue-router";
import api from "../api/api.js";

export default {
  setup() {
    const router = useRouter();
    const notifications = ref([]);
    const toast = inject("toast");
    const searchQuery = ref("");
    const sortBy = ref("priority");
    const sortType = ref("asc");

    const headers = [
      { text: "ID", value: "id", width: 60, sortable: true },
      { text: "タイトル", value: "title", width: 180, sortable: true },
      { text: "内容", value: "content", width: 250, sortable: true },
      { text: "種別", value: "notification_type", width: 100, sortable: true },
      { text: "優先度", value: "priority", width: 80, sortable: true },
      { text: "対象", value: "target_audience", width: 100, sortable: true },
      { text: "開始日時", value: "start_at", width: 150, sortable: true },
      { text: "終了日時", value: "end_at", width: 150, sortable: true },
      { text: "状態", value: "is_active", width: 80, sortable: true },
      { text: "操作", value: "actions", width: 150 },
    ];

    const fetchNotifications = async () => {
      try {
        const response = await api.getHomeNotifications();
        notifications.value = response.data.map((notification) => ({
          ...notification,
          _original: { ...notification },
        }));
      } catch (error) {
        console.error("通知の取得に失敗しました:", error);
        toast.error("通知の取得に失敗しました。", {
          position: "top-right",
          duration: 5000,
        });
      }
    };

    const updateField = (item, field, value) => {
      const index = notifications.value.findIndex((n) => n.id === item.id);
      if (index !== -1) {
        notifications.value[index] = {
          ...notifications.value[index],
          [field]: value,
        };
      }
      console.log(`Updated ${field} for notification ${item.id}:`, value);
    };

    const updateNotification = async (notification) => {
      try {
        const updatedNotification = { ...notification };
        delete updatedNotification._original;

        // 日時フィールドをUTCに変換
        if (updatedNotification.start_at) {
          const startDate = new Date(updatedNotification.start_at);
          updatedNotification.start_at = startDate.toISOString();
        }
        if (updatedNotification.end_at) {
          const endDate = new Date(updatedNotification.end_at);
          updatedNotification.end_at = endDate.toISOString();
        }

        console.log("Sending updated notification:", updatedNotification);
        await api.updateHomeNotification(
          updatedNotification.id,
          updatedNotification
        );
        toast.success("通知が更新されました", {
          position: "top-right",
          duration: 5000,
        });

        const index = notifications.value.findIndex(
          (n) => n.id === notification.id
        );
        if (index !== -1) {
          notifications.value[index]._original = { ...updatedNotification };
        }
      } catch (error) {
        console.error("通知の更新に失敗しました:", error);
        toast.error("通知の更新に失敗しました。", {
          position: "top-right",
          duration: 5000,
        });
        const index = notifications.value.findIndex(
          (n) => n.id === notification.id
        );
        if (index !== -1) {
          notifications.value[index] = {
            ...notifications.value[index]._original,
          };
        }
      }
    };

    const toggleActive = async (notification) => {
      try {
        await api.updateHomeNotification(notification.id, {
          is_active: !notification.is_active,
        });
        toast.success(
          `通知を${notification.is_active ? "非表示" : "表示"}に設定しました`,
          { position: "top-right", duration: 5000 }
        );
        await fetchNotifications();
      } catch (error) {
        console.error("通知の更新に失敗しました:", error);
        toast.error("通知の更新に失敗しました。", {
          position: "top-right",
          duration: 5000,
        });
      }
    };

    const navigateToCreateNotification = () => {
      router.push("/create-home-notification");
    };

    const handleRowClick = (item) => {
      console.log("Clicked row:", item);
    };

    const handleSort = (event) => {
      sortBy.value = event.sortBy;
      sortType.value = event.sortType;
    };

    const convertToLocalDateTime = (dateString) => {
      if (!dateString) return "";
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    onMounted(fetchNotifications);

    return {
      notifications,
      searchQuery,
      headers,
      sortBy,
      sortType,
      handleRowClick,
      handleSort,
      navigateToCreateNotification,
      updateField,
      updateNotification,
      toggleActive,
      convertToLocalDateTime,
    };
  },
};
</script>
