<template>
  <div class="container mx-auto p-4">
    <h1 class="text-2xl font-bold mb-4">プッシュ通知送信</h1>
    <form @submit.prevent="sendPushNotification" class="space-y-4">
      <div>
        <label for="title" class="block mb-1">タイトル</label>
        <input
          id="title"
          v-model="notification.title"
          type="text"
          class="w-full p-2 border rounded"
          required
          @input="resetTestStatus"
        />
      </div>
      <div>
        <label for="body" class="block mb-1">本文</label>
        <textarea
          id="body"
          v-model="notification.body"
          class="w-full p-2 border rounded"
          rows="3"
          required
          @input="resetTestStatus"
        ></textarea>
      </div>
      <div class="flex justify-center">
        <div class="flex space-x-4 w-full max-w-2xl">
          <button
            type="button"
            @click="sendTestNotification"
            class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 flex-1"
            :disabled="isLoading"
          >
            テスト送信
          </button>
          <button
            type="submit"
            class="px-4 py-2 rounded flex-1"
            :class="{
              'bg-blue-500 text-white hover:bg-blue-600':
                isTestSent && !isLoading,
              'bg-gray-400 text-gray-700 cursor-not-allowed':
                !isTestSent || isLoading,
            }"
            :disabled="isLoading || !isTestSent"
          >
            {{ isLoading ? "送信中..." : "全員に送信" }}
          </button>
        </div>
      </div>
      <div v-if="!isTestSent" class="text-red-500 text-center">
        全員に送信する前にテスト送信を行ってください。
      </div>
    </form>
  </div>
</template>

<script>
import { ref, inject, watch } from "vue";
import api from "../api/api.js";

export default {
  setup() {
    const toast = inject("toast");
    const isLoading = ref(false);
    const isTestSent = ref(false);
    const notification = ref({
      title: "",
      body: "",
    });
    const lastTestedNotification = ref({
      title: "",
      body: "",
    });

    const resetTestStatus = () => {
      if (
        notification.value.title !== lastTestedNotification.value.title ||
        notification.value.body !== lastTestedNotification.value.body
      ) {
        isTestSent.value = false;
      }
    };

    watch(notification, resetTestStatus, { deep: true });

    const sendPushNotification = async () => {
      if (!validateNotification()) return;
      if (!isTestSent.value) {
        toast.error("全員に送信する前にテスト送信を行ってください", {
          position: "top-right",
          duration: 5000,
        });
        return;
      }

      isLoading.value = true;
      try {
        const response = await api.sendBulkPushNotification(notification.value);
        console.log("通知が送信されました:", response);
        toast.success("通知が全員に送信されました", {
          position: "top-right",
          duration: 5000,
        });
        clearForm();
      } catch (error) {
        console.error("通知の送信に失敗しました:", error);
        toast.error("通知の送信に失敗しました", {
          position: "top-right",
          duration: 5000,
        });
      } finally {
        isLoading.value = false;
      }
    };

    const sendTestNotification = async () => {
      if (!validateNotification()) return;

      isLoading.value = true;
      try {
        // 142645 aihara main KANATOMAN
        // 649825 aihara sub INAZUMA
        // 67379 tommy002
        // 496374 katsuKing
        const testUserIds = [142645, 649825, 67379, 496374, 564462];
        const response = await api.sendPushNotification({
          ...notification.value,
          hub_user_ids: testUserIds,
        });
        console.log("テスト通知が送信されました:", response);
        toast.success("テスト通知が送信されました", {
          position: "top-right",
          duration: 5000,
        });
        isTestSent.value = true;
        lastTestedNotification.value = { ...notification.value };
      } catch (error) {
        console.error("テスト通知の送信に失敗しました:", error);
        toast.error("テスト通知の送信に失敗しました", {
          position: "top-right",
          duration: 5000,
        });
      } finally {
        isLoading.value = false;
      }
    };

    const validateNotification = () => {
      if (!notification.value.title || !notification.value.body) {
        toast.error("タイトルと本文は必須です", {
          position: "top-right",
          duration: 5000,
        });
        return false;
      }
      return true;
    };

    const clearForm = () => {
      notification.value = {
        title: "",
        body: "",
      };
      isTestSent.value = false;
      lastTestedNotification.value = {
        title: "",
        body: "",
      };
    };

    return {
      notification,
      isLoading,
      isTestSent,
      sendPushNotification,
      sendTestNotification,
      resetTestStatus,
    };
  },
};
</script>
