<template>
  <div class="campaign_analize">
    <!-- side var  -->
    <SideVar />
    <!-- main div -->
    <RightColumnOutline>
      <PageTitle>Discord Members</PageTitle>
      <div class="flex justify-between items-center">
        <div class="text-white text-left ml-4 text-sm">
          メンバーの活動状況を確認しましょう
        </div>
        <div class="text-xs text-white flex items-center w-auto">
          <div class="mr-4">表示期間</div>
          <div v-if="show_flag" class="w-64">
            <Datepicker
              v-model="date"
              range
              :format="yyyy / MM / dd"
              :enable-time-picker="false"
            />
          </div>
        </div>
      </div>
      <div class="m-4 p-2">
        <EasyDataTable
          :headers="headers"
          :items="items"
          :sort-by="sortBy"
          :sort-type="sortType"
        >
          <template #item-member_name="{ member_name, avatar, user_id }">
            <div class="flex items-center">
              <img
                v-if="avatar"
                :src="
                  'https://cdn.discordapp.com/avatars/' + user_id + '/' + avatar
                "
                class="w-6 mx-2"
              />
              <div v-else class="mx-5"></div>
              {{ member_name }}
            </div>
          </template>
        </EasyDataTable>
      </div>
    </RightColumnOutline>
  </div>
</template>

<script>
import api from "../api/api.js";
import Datepicker from "@vuepic/vue-datepicker";
import { ref, onMounted } from "vue";

export default {
  name: "Campaign_regit",
  components: {
    Datepicker,
  },
  setup() {
    let date = ref();
    const sortBy = "count_actions";
    const sortType = "desc";

    const headers = [
      { text: "メンバー名", value: "member_name" },
      {
        text: "アクション数",
        value: "count_actions",
        sortable: true,
        width: 120,
      },
      {
        text: "メッセージ数",
        value: "count_messages",
        sortable: true,
        width: 120,
      },
      {
        text: "リアクション数",
        value: "count_do_reactions",
        sortable: true,
        width: 120,
      },
      {
        text: "メンション数",
        value: "count_do_mentions",
        sortable: true,
        width: 120,
      },
      {
        text: "被リアクション数",
        value: "count_reactions",
        sortable: true,
        width: 150,
      },
      {
        text: "被メンション数",
        value: "count_mentions",
        sortable: true,
        width: 120,
      },
      {
        text: "在籍期間",
        value: "enrollment_period",
        sortable: true,
        width: 100,
      },
      { text: "参加日", value: "joined_date", sortable: true, width: 100 },
    ];

    let items = ref([]);

    onMounted(() => {
      // 日付の最小〜最大を計算する処理
      const today = new Date();
      let toDate = new Date(new Date().setDate(today.getDate() + 1));
      let fromDate = new Date(new Date().setDate(toDate.getDate() - 30));
      date.value = [fromDate, toDate];
    });
    return {
      date,
      headers,
      items,
      sortBy,
      sortType,
    };
  },
  mounted() {
    this.show_flag = true;
    // this.getDiscordMembers();
  },
  data() {
    return {
      show_flag: false,
      today: new Date(),
    };
  },
  computed: {},
  watch: {
    date: {
      handler() {
        this.items = [];
        console.log(
          "change date",
          this.date[0].toISOString().slice(0, 10),
          this.date[1].toISOString().slice(0, 10)
        );
        this.getDiscordMembers();
      },
      deep: true,
    },
  },

  methods: {
    getDiscordMembers() {
      api
        .getDiscordMembers({
          data_from: this.date[0].toISOString().slice(0, 10),
          data_to: this.date[1].toISOString().slice(0, 10),
        })
        .then((response) => {
          console.log(response);
          for (let i = 0; i < response["data"].length; i++) {
            this.items.push({
              member_name: response["data"][i]["username"],
              count_actions:
                response["data"][i]["count_messages"] +
                response["data"][i]["count_do_reactions"],
              count_messages: response["data"][i]["count_messages"],
              count_do_reactions: response["data"][i]["count_do_reactions"],
              count_do_mentions: response["data"][i]["count_do_mentions"],
              count_reactions: response["data"][i]["count_reactions"],
              count_mentions: response["data"][i]["count_mentions"],
              enrollment_period: response["data"][i]["enrollment_period"],
              joined_date: response["data"][i]["member_since"],
              avatar: response["data"][i]["avatar"],
              user_id: response["data"][i]["user_id"],
            });
          }
        })
        .catch(() => {
          this.$toast.show("分析データの取得に失敗しました", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
        });
    },
  },
};
</script>
