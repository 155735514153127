<template>
  <div class="campaign_data_download">
    <SideVar />
    <RightColumnOutline>
      <PageTitle>データダウンロード</PageTitle>
      <div class="flex flex-col items-center space-y-6 mt-8">
        <DoButton
          :clickFunction="downloadCampaignDataCsv"
          :disabled="isDownloadingCampaign"
        >
          抽選データダウンロード
        </DoButton>
        <div class="my-4"></div>
        <div class="flex items-center space-x-4">
          <div class="w-64">
            <label
              for="date-input"
              class="block text-sm font-medium text-white mb-2"
              >日付を選択</label
            >
            <input
              id="date-input"
              v-model="selectedDate"
              type="date"
              class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <DoButton
            :clickFunction="downloadUserPointsCsv"
            :disabled="isDownloadingPoints || !selectedDate"
          >
            獲得ポイントダウンロード
          </DoButton>
        </div>
      </div>
    </RightColumnOutline>
  </div>
</template>

<script>
import { ref } from "vue";
import api from "../api/api.js";

export default {
  name: "CampaignDataDownload",
  setup() {
    const isDownloadingCampaign = ref(false);
    const isDownloadingPoints = ref(false);
    const selectedDate = ref("");

    const downloadCampaignDataCsv = async () => {
      isDownloadingCampaign.value = true;
      try {
        await api.downloadCampaignDataCsv();
        console.log("Campaign CSV download successful");
      } catch (error) {
        console.error("Error downloading campaign CSV:", error);
      } finally {
        isDownloadingCampaign.value = false;
      }
    };

    const downloadUserPointsCsv = async () => {
      if (!selectedDate.value) return;

      isDownloadingPoints.value = true;
      try {
        await api.downloadUserPointsCsv(selectedDate.value);
        console.log("User points CSV download successful");
      } catch (error) {
        console.error("Error downloading user points CSV:", error);
      } finally {
        isDownloadingPoints.value = false;
      }
    };

    return {
      isDownloadingCampaign,
      isDownloadingPoints,
      selectedDate,
      downloadCampaignDataCsv,
      downloadUserPointsCsv,
    };
  },
};
</script>
