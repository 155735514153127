<template>
  <div class="campaign_analize">
    <SideVar />
    <RightColumnOutline>
      <PageTitle>User History Update</PageTitle>
      <div class="px-6">
        <div class="my-8 text-white text-xl text-left">
          Campaign name: {{ campaign_name }}
        </div>
        <div v-if="usernames.length > 1" class="mb-4">
          <label
            v-for="username in usernames"
            :key="username"
            class="inline-flex items-center mr-4"
          >
            <input
              type="radio"
              v-model="selectedUsername"
              :value="username"
              @change="handleUsernameChange"
              class="form-radio text-blue-600"
            />
            <span class="ml-2 text-white">{{ username }}</span>
          </label>
        </div>
        <div class="text-left mt-3">
          <div
            class="text-lg text-gray-100 font-semibold mb-6 mt-6 ml-6 flex items-center justify-left"
          >
            <div class="w-full mr-6">
              <div class="flex items-center justify-between mb-2">
                <div class="flex items-center justify-left">
                  <div>フォロワー推移</div>
                  <div class="text-xs text-gray-200 ml-2">
                    ※フォロワーが1万以上の場合は100件単位の取得となります
                  </div>
                </div>
              </div>
              <div v-if="chartOptions">
                <div id="chart" class="w-full text-black">
                  <apexchart
                    type="line"
                    height="350"
                    :options="chartOptions"
                    :series="chartSeries"
                  >
                  </apexchart>
                </div>
              </div>
            </div>
          </div>

          <div class="data-form mt-6">
            <div
              v-for="(item, index) in userData"
              :key="index"
              class="data-row"
            >
              <span class="text-white">{{
                formatDateTime(item.formatted_datetime)
              }}</span>
              <input
                type="number"
                v-model.number="item.followers"
                @input="updateChart"
                class="bg-gray-700 text-white px-2 py-1 rounded"
              />
            </div>
          </div>

          <button
            @click="updateUserHistory"
            :disabled="!hasChanges"
            class="mt-6 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Update User History
          </button>
        </div>
      </div>
    </RightColumnOutline>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import VueApexCharts from "vue3-apexcharts";
import api from "../api/api.js";

export default {
  name: "UserHistoryUpdate",
  components: {
    apexchart: VueApexCharts,
  },
  setup() {
    const route = useRoute();
    const campaign_id = route.params.id;
    const campaign_name = ref("");
    const userData = ref([]);
    const originalUserData = ref([]);
    const chartOptions = ref(null);
    const chartSeries = ref([]);
    const usernames = ref([]);
    const selectedUsername = ref("");
    const allData = ref([]);

    const hasChanges = computed(() => {
      return (
        JSON.stringify(userData.value) !==
        JSON.stringify(originalUserData.value)
      );
    });

    const formatDateTime = (dateTime) => {
      return new Date(dateTime).toLocaleString();
    };

    const loadCampaignData = async () => {
      try {
        const campaignResponse = await api.getCampaign({ id: campaign_id });
        campaign_name.value = campaignResponse.data[0].campaign_name;

        const response = await api.getFollowersHistory({
          campaign_id: campaign_id,
        });
        allData.value = response.data;
        usernames.value = response.data.map((series) => series[0].username);
        if (!selectedUsername.value) {
          selectedUsername.value = usernames.value[0];
        }

        updateUserDataAndChart();
      } catch (error) {
        console.error("Error loading campaign data:", error);
      }
    };

    const updateUserDataAndChart = () => {
      const selectedData = allData.value.find(
        (series) => series[0].username === selectedUsername.value
      );

      userData.value = selectedData.map((item) => ({
        ...item,
        followers: item.followers || 0,
      }));
      originalUserData.value = JSON.parse(JSON.stringify(userData.value));

      let tmp_followers = 0;
      chartSeries.value = [
        {
          name: selectedUsername.value,
          data: userData.value.map((item) => {
            if (item.followers !== 0) {
              tmp_followers = item.followers;
            }
            return tmp_followers;
          }),
        },
      ];

      chartOptions.value = {
        chart: {
          type: "line",
          height: 350,
        },
        xaxis: {
          categories: userData.value.map((item) => item.formatted_datetime),
          type: "datetime",
        },
        yaxis: {
          title: {
            text: "Followers",
          },
        },
        theme: {
          mode: "dark",
        },
      };
    };

    const updateChart = () => {
      let tmp_followers = 0;
      chartSeries.value[0].data = userData.value.map((item) => {
        if (item.followers !== 0) {
          tmp_followers = item.followers;
        }
        return tmp_followers;
      });
    };

    const updateUserHistory = async () => {
      try {
        const changedItems = userData.value.filter(
          (item, index) =>
            item.followers !== originalUserData.value[index].followers
        );

        for (const item of changedItems) {
          await api.updateUserHistory(item.id, { followers: item.followers });
        }
        alert("User history updated successfully");
        originalUserData.value = JSON.parse(JSON.stringify(userData.value));
      } catch (error) {
        console.error("Error updating user history:", error);
        alert("Failed to update user history");
      }
    };

    const handleUsernameChange = () => {
      if (hasChanges.value) {
        if (confirm("変更が保存されていません。破棄してもよろしいですか？")) {
          updateUserDataAndChart();
        } else {
          selectedUsername.value = originalUserData.value[0].username;
        }
      } else {
        updateUserDataAndChart();
      }
    };

    onMounted(loadCampaignData);

    return {
      campaign_name,
      userData,
      chartOptions,
      chartSeries,
      hasChanges,
      formatDateTime,
      updateChart,
      updateUserHistory,
      usernames,
      selectedUsername,
      handleUsernameChange,
    };
  },
};
</script>

<style scoped>
.data-form {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
}

.data-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
