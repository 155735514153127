<template>
  <!-- <input @change="selectedFile" type="file" name="file" />
  <button @click="upload" type="submit">アップロード</button> -->
  <div class="campaignPageCreate">
    <div>
      <!-- side var  -->
      <SideVar />
      <!-- input panel -->
      <RightColumnOutline>
        <PageTitle>Campaign Analisys </PageTitle>
        <div
          class="flex flex-wrap items-center -mx-4 pb-8 mb-8 border-b border-gray-400 border-opacity-20"
        >
          <div class="w-full sm:w-1/3 px-4 mb-4 sm:mb-0">
            <span class="text-sm font-medium text-gray-100"
              >キャンペーンURL
              <i
                class="pi pi-question-circle mr-1"
                v-tippy="{
                  content:
                    '利用者がキャンペーンに参加するページのURLです。キャンペーンを告知する際にご利用ください',
                }"
              ></i>
            </span>
          </div>
          <div class="text-white text-sm">
            https://app.social-marketing.io/user/instant-win/{{ campaign_id }}
            <button
              @click="copyToClipboard(campaignUrl)"
              class="ml-2 text-white hover:underline"
            >
              <i class="pi pi-copy text-xl"></i>
            </button>
          </div>
        </div>
        <div
          class="flex flex-wrap items-center -mx-4 pb-8 mb-8 border-b border-gray-400 border-opacity-20"
        >
          <div class="w-full sm:w-1/3 px-4 mb-4 sm:mb-0">
            <span class="text-sm font-medium text-gray-100"
              >当選受取URL
              <i
                class="pi pi-question-circle mr-1"
                v-tippy="{
                  content:
                    '当選者がギフトコードを受け取るページのURLです。当選画面にリンクが表示されるため告知する必要はありません。',
                }"
              ></i>
            </span>
          </div>
          <div class="text-white text-sm">https://app.social-marketing.io/</div>
        </div>
        <div
          class="flex flex-wrap items-center -mx-4 pb-8 mb-8 border-b border-gray-400 border-opacity-20"
        >
          <div class="w-full sm:w-1/3 px-4 mb-4 sm:mb-0">
            <span class="text-sm font-medium text-gray-100"
              >キャンペーン画像
            </span>
          </div>
          <input
            @change="selectedHeader"
            type="file"
            name="file"
            class="text-gray-100"
          />
          <div v-if="headerPreview" class="pl-3 w-1/3">
            <img :src="headerPreview" alt="" />
          </div>
          <div v-else class="pl-3 w-1/3">
            <div v-if="headerPicUrl">
              <img :src="headerPicUrl" alt="header" />
            </div>
          </div>
        </div>
        <div
          class="flex flex-wrap items-center -mx-4 pb-8 mb-8 border-b border-gray-400 border-opacity-20"
        >
          <div class="w-full sm:w-1/3 px-4 mb-4 sm:mb-0">
            <span class="text-sm font-medium text-gray-100">当選画像 </span>
          </div>
          <input
            @change="selectedWin"
            type="file"
            name="file"
            class="text-gray-100"
          />
          <div v-if="winPreview" class="pl-3 w-1/3">
            <img :src="winPreview" alt="" />
          </div>
          <div v-else class="pl-3 w-1/3">
            <div v-if="winPicUrl">
              <img :src="winPicUrl" alt="win" />
            </div>
          </div>
        </div>
        <div
          class="flex flex-wrap items-center -mx-4 pb-8 mb-8 border-b border-gray-400 border-opacity-20"
        >
          <div class="w-full sm:w-1/3 px-4 mb-4 sm:mb-0">
            <span class="text-sm font-medium text-gray-100"
              >当選時メッセージ *</span
            >
          </div>
          <div class="w-full sm:w-2/3 px-4">
            <div class="max-w-xl">
              <textarea
                class="block py-4 px-3 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg h-32"
                maxlength="120"
                v-model="winText"
                placeholder="当たり！おめでとうございます！"
              />
            </div>
          </div>
        </div>

        <div
          class="flex flex-wrap items-center -mx-4 pb-8 mb-8 border-b border-gray-400 border-opacity-20"
        >
          <div class="w-full sm:w-1/3 px-4 mb-4 sm:mb-0">
            <span class="text-sm font-medium text-gray-100">落選画像 </span>
          </div>
          <input
            @change="selectedLose"
            type="file"
            name="file"
            class="text-gray-100"
          />
          <div v-if="losePreview" class="pl-3 w-1/3">
            <img :src="losePreview" alt="" />
          </div>
          <div v-else class="pl-3 w-1/3">
            <div v-if="losePicUrl">
              <img :src="losePicUrl" alt="win" />
            </div>
          </div>
        </div>
        <div
          class="flex flex-wrap items-center -mx-4 pb-8 mb-8 border-b border-gray-400 border-opacity-20"
        >
          <div class="w-full sm:w-1/3 px-4 mb-4 sm:mb-0">
            <span class="text-sm font-medium text-gray-100"
              >落選時メッセージ *</span
            >
          </div>
          <div class="w-full sm:w-2/3 px-4">
            <div class="max-w-xl">
              <textarea
                class="block py-4 px-3 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg h-32"
                v-model="loseText"
                maxlength="120"
                placeholder="はずれです。またのご参加をお待ちしております。"
              />
            </div>
          </div>
        </div>
        <div
          class="flex flex-wrap items-center -mx-4 pb-8 mb-8 border-b border-gray-400 border-opacity-20"
        >
          <div class="w-full sm:w-1/3 px-4 mb-4 sm:mb-0">
            <span class="text-sm font-medium text-gray-100"
              >サービスサイトURL<i
                class="pi pi-question-circle mr-1"
                v-tippy="{
                  content:
                    'キャンペーン参加後に誘導したい先のURLがあれば入力してください。登録した場合当落画面にリンクが作成されます',
                }"
              ></i
            ></span>
          </div>
          <div class="w-full sm:w-2/3 px-4">
            <div class="max-w-xl">
              <textarea
                class="block py-4 px-3 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
                v-model="serviceSiteURL"
                maxlength="240"
                placeholder="https://sample.com"
              />
            </div>
          </div>
        </div>
        <button
          class="inline-block py-2 px-4 text-xs text-center font-semibold leading-normal rounded-lg transition duration-200"
          :class="btnclass"
          :disabled="regist_loading"
          @click="postPics"
        >
          <i class="pi pi-spin pi-spinner text-xs" v-if="regist_loading"></i>
          登録
        </button>
      </RightColumnOutline>
    </div>
  </div>
</template>

<script>
import SideVar from "@/components/SideVar.vue";
import api from "../api/api";
export default {
  data: function () {
    return {
      campaign_id: 0,
      headerPic: null,
      headerPreview: null,
      headerPicUrl: null, // S3 load用
      winPic: null,
      winPreview: null,
      winText: null,
      winPicUrl: null, // S3 load用
      losePic: null,
      losePreview: null,
      loseText: null,
      losePicUrl: null, // S3 load用
      regist_loading: false,
      serviceSiteURL: "",
    };
  },
  components: {
    SideVar,
  },
  mounted() {
    this.campaign_id = this.$route.params.id;
    this.getInstantwin();
  },
  computed: {
    campaignUrl() {
      return `https://app.social-marketing.io/user/instant-win/${this.campaign_id}`;
    },
    btnclass() {
      let return_class = "";
      let header = this.headerPic || this.headerPicUrl;
      let win = this.winPic || this.winPicUrl;
      let lose = this.losePic || this.losePicUrl;
      if (
        header &&
        win &&
        this.winText &&
        lose &&
        this.loseText &&
        !this.regist_loading
      ) {
        return_class = "text-blue-50 bg-blue-500 hover:bg-blue-600";
      } else {
        return_class = "text-gray-300 bg-gray-700 hover:bg-gray-800";
      }
      return return_class;
    },
  },
  methods: {
    getInstantwin: function () {
      api
        .getInstantWin({ campaign_id: this.$route.params.id })
        .then((response) => {
          this.winText = response.data[0].win_text;
          this.loseText = response.data[0].lose_text;
          this.headerPicUrl = response.data[0].header_pic;
          this.winPicUrl = response.data[0].win_pic;
          this.losePicUrl = response.data[0].lose_pic;
          this.serviceSiteURL = response.data[0].landing_page_url;
        })
        .catch(function (error) {
          this.$toast.show("キャンペーンデータの取得に失敗しました", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
          console.log(error);
        });
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.$toast.show("URLがコピーされました", {
            type: "success",
            position: "top-right",
            duration: 3000,
          });
        },
        (err) => {
          console.error("Failed to copy text: ", err);
        }
      );
    },
    selectedHeader: function (e) {
      e.preventDefault();
      let files = e.target.files;
      this.headerPic = files[0];
      this.createHeader(files[0]);
    },
    selectedWin: function (e) {
      e.preventDefault();
      let files = e.target.files;
      this.winPic = files[0];
      this.createWin(files[0]);
    },
    selectedLose: function (e) {
      e.preventDefault();
      let files = e.target.files;
      this.losePic = files[0];
      this.createLose(files[0]);
    },
    createHeader(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.headerPreview = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeHeader() {
      this.headerPreview = false;
    },
    createWin(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.winPreview = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeWin() {
      this.winPreview = false;
    },
    createLose(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.losePreview = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeLose() {
      this.losePreview = false;
    },
    postPics: async function () {
      this.regist_loading = true;
      let config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      let header = new FormData();
      header.append("file", this.headerPic);
      let win = new FormData();
      win.append("file", this.winPic);
      let lose = new FormData();
      lose.append("file", this.losePic);
      // 順次アップロード判断＋アップロード
      if (this.serviceSiteURL) {
        await api
          .updateInstantWin({
            campaign_id: this.$route.params.id,
            landing_page_url: this.serviceSiteURL,
          })
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            this.$toast.show("サービスサイトURLの登録に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log(error);
            this.regist_loading = false;
          });
      }
      if (this.headerPic) {
        await api
          .uploadHeader(
            { campaign_id: this.$route.params.id, form: header },
            config
          )
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            this.$toast.show("ヘッダー画像の登録に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log(error);
            this.regist_loading = false;
          });
      }
      if (this.winPic) {
        await api
          .uploadWin({ campaign_id: this.$route.params.id, form: win }, config)
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            this.$toast.show("当選画像の登録に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log(error);
            this.regist_loading = false;
          });
      }
      if (this.losePic) {
        await api
          .uploadLose(
            { campaign_id: this.$route.params.id, form: lose },
            config
          )
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            this.$toast.show("落選画像の登録に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log(error);
            this.regist_loading = false;
          });
      }
      await api
        .updateInstantWin(
          {
            campaign_id: this.$route.params.id,
            win_text: this.winText,
            lose_text: this.loseText,
          },
          config
        )
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          this.$toast.show("テキストの登録に失敗しました", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
          console.log(error);
          this.regist_loading = true;
        });
      this.regist_loading = false;
      this.$toast.show("画像・テキストの登録が完了しました", {
        type: "success",
        position: "top-right",
        duration: 3000,
      });
      this.$router.replace("/campaigns");
    },
  },
};
</script>
