<template>
  <div class="flex justify-center flex-col">
    <div class="flex justify-center">
      <div class="container max-w-2xl p-4">
        <h1 class="text-2xl font-bold mb-4 text-center">プッシュ通知送信</h1>
        <form @submit.prevent="handleSubmit" class="space-y-4 bg-white p-6">
          <div>
            <label for="title" class="block mb-1">タイトル</label>
            <input
              id="title"
              v-model="notification.title"
              type="text"
              class="w-full p-2 border rounded"
              required
              @input="resetTestStatus"
            />
          </div>

          <div>
            <label for="body" class="block mb-1">本文</label>
            <textarea
              id="body"
              v-model="notification.body"
              class="w-full p-2 border rounded"
              rows="3"
              required
              @input="resetTestStatus"
            ></textarea>
          </div>

          <div>
            <label for="target" class="block mb-1">配信対象</label>
            <select
              id="target"
              v-model="notification.target"
              class="w-full p-2 border rounded"
              required
            >
              <option value="ALL">全員</option>
            </select>
          </div>

          <div>
            <label for="scheduled_at" class="block mb-1"
              >投稿予定時刻（JST）</label
            >
            <input
              id="scheduled_at"
              v-model="notification.scheduled_at"
              type="datetime-local"
              class="w-full p-2 border rounded"
              @input="resetTestStatus"
            />
          </div>

          <div>
            <label for="memo" class="block mb-1">メモ</label>
            <textarea
              id="memo"
              v-model="notification.memo"
              class="w-full p-2 border rounded"
              rows="2"
            ></textarea>
          </div>

          <div class="flex justify-center">
            <div class="flex space-x-4 w-full">
              <button
                type="button"
                @click="sendTestNotification"
                class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 flex-1 disabled:bg-gray-400 disabled:cursor-not-allowed"
                :disabled="isLoading"
              >
                テスト送信
              </button>

              <button
                v-if="!hasScheduledTime"
                type="button"
                @click="handleImmediateSubmit"
                class="px-4 py-2 rounded flex-1"
                :class="{
                  'bg-blue-500 text-white hover:bg-blue-600':
                    isTestSent && !isLoading,
                  'bg-gray-400 text-gray-700 cursor-not-allowed':
                    !isTestSent || isLoading,
                }"
                :disabled="isLoading || !isTestSent"
              >
                {{ isLoading ? "送信中..." : "全員に送信" }}
              </button>

              <button
                v-else
                type="button"
                @click="handleScheduledSubmit"
                class="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600 flex-1 disabled:bg-gray-400 disabled:cursor-not-allowed"
                :disabled="isLoading || !isTestSent"
              >
                予約して送信
              </button>
            </div>
          </div>

          <div v-if="!isTestSent" class="text-red-500 text-center">
            送信する前にテスト送信を行ってください。
          </div>
        </form>
      </div>
    </div>
    <!-- プッシュ通知リスト -->
    <div class="container mx-auto p-4">
      <h2 class="text-2xl font-bold mb-4">プッシュ通知履歴</h2>

      <div class="flex justify-between mb-4">
        <input
          v-model="searchQuery"
          class="border p-2 rounded w-1/3"
          placeholder="タイトルで検索..."
        />
      </div>

      <EasyDataTable
        :headers="headers"
        :items="pushes"
        :search-value="searchQuery"
        :sort-by="sortBy"
        :sort-type="sortType"
        :rows-per-page="15"
        :rows-items="[15, 25, 50, 100]"
        buttons-pagination
        @sort="handleSort"
      >
        <template #item-title="item">
          <input
            :value="item.title"
            @input="updateField(item, 'title', $event.target.value)"
            class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
          />
        </template>

        <template #item-content="item">
          <textarea
            :value="item.content"
            @input="updateField(item, 'content', $event.target.value)"
            class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
            rows="2"
          />
        </template>

        <template #item-target="item">
          <select
            :value="item.target"
            @change="updateField(item, 'target', $event.target.value)"
            class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
          >
            <option value="ALL">全員</option>
            <option value="Android">Android</option>
            <option value="iOS">iOS</option>
          </select>
        </template>

        <template #item-published="item">
          <input
            type="checkbox"
            :checked="item.published"
            @change="updateField(item, 'published', $event.target.checked)"
          />
        </template>

        <template #item-published_at="item">
          <input
            type="datetime-local"
            :value="formatDateForInput(item.published_at)"
            @input="updateField(item, 'published_at', $event.target.value)"
            class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
          />
        </template>

        <template #item-memo="item">
          <textarea
            :value="item.memo"
            @input="updateField(item, 'memo', $event.target.value)"
            class="w-full border-b border-gray-300 focus:outline-none focus:border-blue-500"
            rows="2"
          />
        </template>

        <template #item-created_at="item">
          {{ formatDateTime(item.created_at) }}
        </template>

        <template #item-updated_at="item">
          {{ formatDateTime(item.updated_at) }}
        </template>

        <template #item-actions="item">
          <button
            @click="updatePush(item)"
            class="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600 mr-2"
          >
            更新
          </button>
        </template>
      </EasyDataTable>
    </div>
  </div>
</template>

<script>
import { ref, inject, watch, computed, onMounted } from "vue";
import api from "../api/api.js";

export default {
  setup() {
    const toast = inject("toast");
    const isLoading = ref(false);
    const isTestSent = ref(false);
    const notification = ref({
      title: "",
      body: "",
      target: "ALL",
      scheduled_at: "",
      memo: "",
    });
    const lastTestedNotification = ref({
      title: "",
      body: "",
    });
    const pushes = ref([]);
    const searchQuery = ref("");
    const sortBy = ref("created_at");
    const sortType = ref("desc");

    const headers = [
      { text: "ID", value: "id", width: 80, sortable: true },
      { text: "タイトル", value: "title", width: 200, sortable: true },
      { text: "内容", value: "content", width: 300, sortable: true },
      { text: "対象", value: "target", width: 100, sortable: true },
      { text: "送信済", value: "published", width: 80, sortable: true },
      {
        text: "投稿予定時刻",
        value: "published_at",
        width: 200,
        sortable: true,
      },
      { text: "メモ", value: "memo", width: 200, sortable: true },
      { text: "作成日時", value: "created_at", width: 150, sortable: true },
      { text: "更新日時", value: "updated_at", width: 150, sortable: true },
      { text: "操作", value: "actions", width: 100 },
    ];

    const fetchPushes = async () => {
      try {
        const response = await api.getPushes();
        pushes.value = response.data.map((push) => ({
          ...push,
          _original: { ...push },
        }));
      } catch (error) {
        console.error("プッシュ通知の取得に失敗しました:", error);
        toast.error("プッシュ通知の取得に失敗しました。", {
          position: "top-right",
          duration: 5000,
        });
      }
    };

    const updateField = (item, field, value) => {
      const index = pushes.value.findIndex((p) => p.id === item.id);
      if (index !== -1) {
        pushes.value[index] = {
          ...pushes.value[index],
          [field]: value,
        };
      }
    };

    const updatePush = async (push) => {
      try {
        const updatedPush = { ...push };
        delete updatedPush._original;

        // published_atをUTCに変換
        if (updatedPush.published_at) {
          const date = new Date(updatedPush.published_at);
          updatedPush.published_at = date.toISOString();
        }

        await api.updatePushes(push.id, updatedPush);
        toast.success("プッシュ通知が更新されました", {
          position: "top-right",
          duration: 5000,
        });

        // リストを再取得
        await fetchPushes();
      } catch (error) {
        console.error("プッシュ通知の更新に失敗しました:", error);
        toast.error("プッシュ通知の更新に失敗しました。", {
          position: "top-right",
          duration: 5000,
        });
        // エラー時は元の値に戻す
        const index = pushes.value.findIndex((p) => p.id === push.id);
        if (index !== -1) {
          pushes.value[index] = { ...pushes.value[index]._original };
        }
      }
    };

    const handleSort = (event) => {
      sortBy.value = event.sortBy;
      sortType.value = event.sortType;
    };

    const formatDateTime = (dateString) => {
      if (!dateString) return "";
      const date = new Date(dateString);
      // UTCをJSTに変換（+9時間）
      const jstDate = new Date(date.getTime() + 9 * 60 * 60 * 1000);
      return jstDate.toLocaleString("ja-JP");
    };

    const formatDateForInput = (dateString) => {
      if (!dateString) return "";
      const date = new Date(dateString);
      // UTCをJSTに変換（+9時間）
      const jstDate = new Date(date.getTime() + 9 * 60 * 60 * 1000);
      return jstDate.toISOString().slice(0, 16);
    };

    const hasScheduledTime = computed(() => {
      return !!notification.value.scheduled_at;
    });

    const resetTestStatus = () => {
      if (
        notification.value.title !== lastTestedNotification.value.title ||
        notification.value.body !== lastTestedNotification.value.body
      ) {
        isTestSent.value = false;
      }
    };

    watch(notification, resetTestStatus, { deep: true });

    const handleImmediateSubmit = async () => {
      if (!validateNotification()) return;

      isLoading.value = true;
      try {
        const response = await api.sendBulkPushNotification({
          title: notification.value.title,
          body: notification.value.body,
          target: notification.value.target,
          memo: notification.value.memo,
        });
        console.log("通知が送信されました:", response);
        toast.success("通知が全員に送信されました", {
          position: "top-right",
          duration: 5000,
        });
        clearForm();
      } catch (error) {
        console.error("通知の送信に失敗しました:", error);
        toast.error("通知の送信に失敗しました", {
          position: "top-right",
          duration: 5000,
        });
      } finally {
        isLoading.value = false;
      }
    };

    const handleScheduledSubmit = async () => {
      if (!validateNotification()) return;

      isLoading.value = true;
      try {
        // Convert JST datetime-local to UTC for API
        const jstDate = new Date(notification.value.scheduled_at);
        const published_at = jstDate.toISOString();

        const response = await api.sendBulkPushNotificationScheduled({
          title: notification.value.title,
          body: notification.value.body,
          target: notification.value.target,
          published_at: published_at,
          published: false,
          memo: notification.value.memo,
        });
        console.log("通知が予約されました:", response);
        toast.success("通知が予約されました", {
          position: "top-right",
          duration: 5000,
        });
        clearForm();
      } catch (error) {
        console.error("通知の予約に失敗しました:", error);
        toast.error("通知の予約に失敗しました", {
          position: "top-right",
          duration: 5000,
        });
      } finally {
        isLoading.value = false;
      }
    };

    const sendTestNotification = async () => {
      if (!validateNotification()) return;

      isLoading.value = true;
      try {
        // 142645 aihara main KANATOMAN
        // 649825 aihara sub INAZUMA
        // 67379 tommy002
        // 496374 katsuKing
        // 630946 amaririsu
        const testUserIds = [142645, 649825, 67379, 496374, 564462, 630946];
        const response = await api.sendPushNotification({
          title: notification.value.title,
          body: notification.value.body,
          hub_user_ids: testUserIds,
        });
        console.log("テスト通知が送信されました:", response);
        toast.success("テスト通知が送信されました", {
          position: "top-right",
          duration: 5000,
        });
        isTestSent.value = true;
        lastTestedNotification.value = {
          title: notification.value.title,
          body: notification.value.body,
        };
      } catch (error) {
        console.error("テスト通知の送信に失敗しました:", error);
        toast.error("テスト通知の送信に失敗しました", {
          position: "top-right",
          duration: 5000,
        });
      } finally {
        isLoading.value = false;
      }
    };

    const validateNotification = () => {
      if (!notification.value.title || !notification.value.body) {
        toast.error("タイトルと本文は必須です", {
          position: "top-right",
          duration: 5000,
        });
        return false;
      }
      return true;
    };

    const clearForm = () => {
      notification.value = {
        title: "",
        body: "",
        target: "ALL",
        scheduled_at: "",
        memo: "",
      };
      isTestSent.value = false;
      lastTestedNotification.value = {
        title: "",
        body: "",
      };
    };

    onMounted(fetchPushes);

    return {
      notification,
      isLoading,
      isTestSent,
      hasScheduledTime,
      handleImmediateSubmit,
      handleScheduledSubmit,
      sendTestNotification,
      resetTestStatus,
      pushes,
      searchQuery,
      headers,
      sortBy,
      sortType,
      updatePush,
      updateField,
      handleSort,
      formatDateTime,
      formatDateForInput,
    };
  },
};
</script>
