<template>
  <div class="login_page">
    <section class="relative pt-12">
      <div class="container px-4 mx-auto">
        <div class="h-full">
          <div class="relative max-w-xs mx-auto text-center">
            <a
              class="w-24 inline-block mx-auto mb-10"
              href="https://social-marketing.io"
            >
              <img
                class="block"
                src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/sociama_01@4x.png"
              />
            </a>
            <h2 class="text-2xl text-gray-500 font-semibold mb-2">
              Log in to your account
            </h2>
            <p class="text-gray-400 font-medium mb-10">
              Welcome back! Please enter your account.
            </p>
            <form action="">
              <div
                class="relative w-full h-14 py-4 px-3 mb-8 border border-gray-400 hover:border-blue focus-within:border-green-500 rounded-lg"
              >
                <span
                  class="absolute bottom-full left-0 ml-3 -mb-1 transform translate-y-0.5 text-xs font-semibold text-gray-600 px-1 bg-gray-200"
                  >Email</span
                >
                <input
                  class="block w-full outline-none text-sm text-gray-600 font-medium"
                  id="email"
                  type="email"
                  placeholder="mail-address@example.com"
                  v-model="email"
                />
              </div>
              <div
                class="relative w-full h-14 py-4 px-3 mb-3 border border-gray-400 hover:border-blue focus-within:border-green-500 rounded-lg"
              >
                <span
                  class="absolute bottom-full left-0 ml-3 -mb-1 transform translate-y-0.5 text-xs font-semibold text-gray-600 px-1 bg-gray-200"
                  >Password</span
                >
                <input
                  class="block w-full outline-none bg-transparent text-sm text-gray-600 font-medium"
                  id="password"
                  type="password"
                  placeholder="password"
                  v-model="password"
                />
              </div>
            </form>
            <button
              class="mt-6 block w-full py-4 mb-4 leading-6 text-white font-semibold rounded-lg transition duration-200"
              :class="
                email != '' && this.password != '' && !loading
                  ? 'bg-blue-500 hover:bg-blue-600'
                  : 'bg-gray-500 hover:bg-gray-600'
              "
              :disabled="!email || !password || loading"
              @click="signIn"
            >
              <i class="pi pi-spin pi-spinner text-sm" v-if="loading"></i>
              Sign In
            </button>
            <p class="font-medium">
              <router-link to="/sign-up">
                <span class="text-gray-300">Don’t have an account?</span>
                <a class="inline-block text-blue-500 hover:underline" href="#">
                  Sign up</a
                >
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "@/api/api.js";
import "@/firebase_config";
import {
  getAuth,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "@firebase/auth";
import store from "@/store";

const auth = getAuth();

export default {
  data() {
    return {
      email: "",
      password: "",
      loading: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["user"];
    },
    idToken() {
      return this.$store.getters["idToken"];
    },
  },
  methods: {
    signIn: async function () {
      let user = null;
      this.loading = true;
      await signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          user = userCredential.user;
          if (user.emailVerified == false) {
            this.$toast.show(
              "メール認証が未済です。送付したメールから認証を完了してください",
              {
                type: "warning",
                position: "top-right",
                duration: 5000,
              }
            );
            sendEmailVerification(user, {
              url: "https://social-marketing.io/login",
            });
            this.loading = false;
          } else {
            store.commit("updateUser", user);
            this.$toast.show("ログインしました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            api
              .getAccounts()
              .then(() => {
                api
                  .checkSubscribe()
                  .then((response) => {
                    store.commit("updateSubscribePlan", response["data"]);
                    this.$router.replace("/campaigns");
                  })
                  .catch((error) => {
                    this.$toast.show("プラン取得に失敗しました", {
                      type: "error",
                      position: "top-right",
                      duration: 5000,
                    });
                    console.log("subscribe error:", error);
                    this.$router.replace("/campaigns");
                  });
                this.loading = false;
                this.$router.replace("/campaigns");
              })
              .catch((error) => {
                this.$toast.show("アカウントの取得に失敗しました", {
                  type: "error",
                  position: "top-right",
                  duration: 5000,
                });
                console.log("login getAccounts error:", error);
                this.loading = false;
              });
          }
        })
        .catch((error) => {
          this.$toast.show("EmailかPasswordが間違っています", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
          this.loading = false;
          console.log(error.code + " " + error.message);
        });
    },
    refresh_error: function () {
      this.error_message = "";
    },
  },
};
</script>
