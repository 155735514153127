<template>
  <div class="container mx-auto p-4">
    <h1 class="text-2xl font-bold mb-4">お知らせ一覧</h1>

    <div class="flex justify-between mb-4">
      <input
        v-model="searchQuery"
        class="border p-2 rounded w-1/3"
        placeholder="タイトルで検索..."
      />
      <button
        @click="navigateToCreateAnnouncement"
        class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
      >
        新規お知らせ作成
      </button>
    </div>

    <EasyDataTable
      :headers="headers"
      :items="announcements"
      :search-value="searchQuery"
      :sort-by="sortBy"
      :sort-type="sortType"
      :rows-per-page="15"
      :rows-items="[15, 25, 50, 100]"
      buttons-pagination
      @click-row="handleRowClick"
      @sort="handleSort"
    >
      <template #item-title="item">
        {{ item.title }}
      </template>
      <template #item-content="item">
        {{ truncateContent(item.content) }}
      </template>
      <template #item-target_audience="item">
        {{ item.target_audience }}
      </template>
      <template #item-created_at="item">
        {{ formatDate(item.created_at) }}
      </template>
      <template #item-expiration_datetime="item">
        {{ formatDate(item.expiration_datetime) }}
      </template>
      <template #item-actions="item">
        <button
          @click="navigateToUpdateAnnouncement(item.id)"
          class="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600 mr-2"
        >
          更新
        </button>
        <button
          @click="confirmDelete(item)"
          class="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
        >
          削除
        </button>
      </template>
    </EasyDataTable>
  </div>
</template>

<script>
import { ref, onMounted, inject } from "vue";
import { useRouter } from "vue-router";
import api from "../api/api.js";

export default {
  setup() {
    const router = useRouter();
    const announcements = ref([]);
    const toast = inject("toast");
    const searchQuery = ref("");
    const sortBy = ref("created_at");
    const sortType = ref("desc");

    const headers = [
      { text: "ID", value: "id", width: 80, sortable: true },
      { text: "タイトル", value: "title", width: 200, sortable: true },
      { text: "内容", value: "content", width: 300, sortable: true },
      { text: "対象者", value: "target_audience", width: 100, sortable: true },
      { text: "作成日時", value: "created_at", width: 150, sortable: true },
      {
        text: "期限",
        value: "expiration_datetime",
        width: 150,
        sortable: true,
      },
      { text: "操作", value: "actions", width: 150 },
    ];

    const fetchAnnouncements = async () => {
      try {
        const response = await api.getAnnouncements();
        announcements.value = response.data;
      } catch (error) {
        console.error("お知らせの取得に失敗しました:", error);
        toast.error("お知らせの取得に失敗しました。", {
          position: "top-right",
          duration: 5000,
        });
      }
    };

    const confirmDelete = async (announcement) => {
      if (
        window.confirm(
          `本当にお知らせ「${announcement.title}」を削除してよろしいですか？`
        )
      ) {
        try {
          await api.deleteAnnouncement(announcement.id);
          toast.success("お知らせが削除されました", {
            position: "top-right",
            duration: 5000,
          });
          await fetchAnnouncements(); // 一覧を再取得
        } catch (error) {
          console.error("お知らせの削除に失敗しました:", error);
          toast.error("お知らせの削除に失敗しました。", {
            position: "top-right",
            duration: 5000,
          });
        }
      }
    };

    const navigateToCreateAnnouncement = () => {
      router.push("/create-announcement");
    };

    const navigateToUpdateAnnouncement = (id) => {
      router.push(`/update-announcement/${id}`);
    };

    const handleRowClick = (item) => {
      console.log("Clicked row:", item);
    };

    const handleSort = (event) => {
      sortBy.value = event.sortBy;
      sortType.value = event.sortType;
    };

    const truncateContent = (content) => {
      return content.length > 50 ? content.substring(0, 50) + "..." : content;
    };

    const formatDate = (dateString) => {
      if (!dateString) return "";
      const date = new Date(dateString);
      return date.toLocaleString("ja-JP");
    };

    onMounted(fetchAnnouncements);

    return {
      announcements,
      searchQuery,
      headers,
      sortBy,
      sortType,
      handleRowClick,
      handleSort,
      navigateToCreateAnnouncement,
      navigateToUpdateAnnouncement,
      confirmDelete,
      truncateContent,
      formatDate,
    };
  },
};
</script>
