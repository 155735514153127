<template>
  <div class="landingPage">
    <div class="overflow-hidden">
      <!-- header -->
      <Header />
      <!-- header end -->
      <!-- helo -->
      <section class="w-full">
        <div class="relative">
          <img
            class="transform absolute right-0 top-0 w-full"
            src="../../../public/smoke_light.png"
          />
          <div
            class="absolute space-y-14 items-start justify-start top-40 left-32 hidden md:inline-block"
          >
            <div class="relative text-left">
              <div class="text-6xl font-black leading-10 text-white flex">
                <div>
                  <VueWriter
                    :array="arr"
                    :typeSpeed="100"
                    :iterations="3"
                    :eraseSpeed="50"
                  />
                </div>
                <p>で、</p>
              </div>
              <p class="text-6xl font-black leading-10 text-white mt-6">
                トレンドを作る。
              </p>
            </div>
            <div
              class="inline-flex space-x-6 items-center justify-end hidden md:flex"
            >
              <router-link to="/company-detail">
                <div
                  class="flex items-center justify-center w-64 px-12 py-3 bg-yellow-400 rounded"
                >
                  <p class="text-sm font-bold text-gray-800">
                    資料ダウンロード
                  </p>
                </div>
              </router-link>
              <router-link to="/login">
                <div
                  class="flex items-center justify-center w-64 h-full px-12 py-3 bg-white rounded"
                >
                  <button class="text-sm font-bold text-gray-800">
                    無料ではじめる
                  </button>
                </div>
              </router-link>
            </div>
          </div>
          <div
            class="absolute space-y-14 items-start justify-start top-16 left-16 inline-block md:hidden"
          >
            <div class="relative text-left">
              <div class="text-2lg font-black text-white flex">
                <div>
                  <VueWriter
                    :array="arr"
                    :typeSpeed="100"
                    :iterations="3"
                    :eraseSpeed="50"
                  />
                </div>
                <p>で、</p>
              </div>
              <p class="text-2lg font-black text-white">トレンドを作る。</p>
            </div>
          </div>
        </div>
      </section>
      <!-- content 悩み訴求 -->
      <div class="w-auto">
        <img src="../../../public/A_03_osusume_light.png" />
      </div>
      <div class="md:mx-12">
        <div>
          <div class="w-auto">
            <img
              src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/contents_nayami.png"
              loading="lazy"
            />
          </div>
          <div class="w-auto" id="feature">
            <img
              src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/contents_riyu.png"
              loading="lazy"
            />
          </div>
          <div class="w-auto">
            <img
              src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/contents_powerful_campaign.png"
              loading="lazy"
            />
          </div>
          <div class="w-auto">
            <img
              src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/contents_streamline_Rewards.png"
              loading="lazy"
            />
          </div>
          <div class="w-auto">
            <!-- <img src="../assets/contents_web3勧誘.png" /> -->
            <img
              class="w-auto mx-auto my-12 px-12"
              src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/content_web3kanyu_1.png"
              loading="lazy"
            />
            <img
              class="mx-auto my-12"
              src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/content_web3kanyu_2.png"
              loading="lazy"
            />
            <img
              class="mx-auto my-12"
              src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/content_web3kanyu_3.png"
              loading="lazy"
            />
            <img
              class="w-auto mx-auto my-12 px-12"
              src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/message.png"
              loading="lazy"
            />
            <div class="justify-center hidden md:flex">
              <router-link to="/company-detail">
                <div>
                  <img
                    class="my-6 mx-6 w-64"
                    src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/btn_download.png"
                    loading="lazy"
                  />
                </div>
              </router-link>
              <router-link to="/login">
                <img
                  class="my-6 mx-6 w-64"
                  src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/btn_free.png"
                  loading="lazy"
                />
              </router-link>
            </div>
          </div>
          <div class="w-auto mt-16">
            <img
              class="w-auto my-12"
              src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/content_tasya_1.png"
              loading="lazy"
            />
            <img
              class="w-auto my-12 mx-auto px-32"
              src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/content_tasya_2.png"
              loading="lazy"
            />
            <div class="mx-auto text-base">
              詳細は担当者よりご案内いたしますのでお問い合わせください
            </div>
            <div class="justify-center hidden md:flex">
              <router-link to="/contact">
                <img
                  class="my-6 mx-6 w-64"
                  src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/btn_contact.png"
                  loading="lazy"
                />
              </router-link>
              <router-link to="/company-detail">
                <div>
                  <img
                    class="my-6 mx-6 w-64"
                    src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/btn_download.png"
                    loading="lazy"
                  />
                </div>
              </router-link>
            </div>
          </div>
          <div class="w-auto mt-6 mb:mt-16" id="result">
            <img
              class="w-auto"
              src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/contents_campaign_kouka.png"
              loading="lazy"
            />
          </div>
          <div class="w-auto">
            <img
              src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/contents_jire_01.png"
              loading="lazy"
            />
          </div>
          <div class="w-full hidden md:inline-block">
            <img
              class="mx-auto my-12 mx-6"
              src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/message_3.png"
              loading="lazy"
            />
            <div class="flex justify-center mx-48">
              <div class="mx-4 w-1/4">
                <blockquote class="twitter-tweet">
                  <p lang="ja" dir="ltr">
                    【アマギフ1万円‼️1,000人に当たる⁉️】<br />7月7日にIZUMO LAND
                    NFTを発売することを記念し、Amazonギフト券のプレゼントキャンペーンを実施いたします✨<br /><br />【参加条件】<br />✅エントリー期限：2023.07.04
                    0:00(JST)<br />✅このアカウント
                    <a
                      href="https://twitter.com/IZUMO_official?ref_src=twsrc%5Etfw"
                      >@IZUMO_official</a
                    >
                    をフォロー<br />✅このツイートをリツイート<br /><br />【Campaign…
                    <a href="https://t.co/nR8rYTp3jr"
                      >pic.twitter.com/nR8rYTp3jr</a
                    >
                  </p>
                  &mdash; IZUMO｜AR Metaverse &amp; NFT Playground｜0728
                  NFTSALE🗺 (@IZUMO_official)
                  <a
                    href="https://twitter.com/IZUMO_official/status/1674643264727842818?ref_src=twsrc%5Etfw"
                    >June 30, 2023</a
                  >
                </blockquote>
              </div>
              <div class="mx-4 w-1/4">
                <blockquote class="twitter-tweet">
                  <p lang="ja" dir="ltr">
                    Magic Eden NFTセール記念✨<br />WL獲得キャンペーン🎁<br />⁰<a
                      href="https://twitter.com/hashtag/Gensokishi?src=hash&amp;ref_src=twsrc%5Etfw"
                      >#Gensokishi</a
                    >
                    を付けてツイートいただいたり、Twitter上でのアクションによってポイントGET!<br />獲得ポイント上位250名様へ
                    WLを付与いたします!<br /><br />🥇ランキング50位まで：Tier1
                    WL×1⁰🥈ランキング51~250位まで：Tier2
                    WL×1⁰⁰ポイント獲得方法は…👇
                  </p>
                  &mdash; Genso Meta (@genso_meta)
                  <a
                    href="https://twitter.com/genso_meta/status/1671363938951524352?ref_src=twsrc%5Etfw"
                    >June 21, 2023</a
                  >
                </blockquote>
              </div>
              <div class="mx-4 w-1/4">
                <blockquote class="twitter-tweet">
                  <p lang="ja" dir="ltr">
                    ＼🎊WebX開催記念🎊／<br /><br />総額135万円相当🎉<br /><br />WebX開催を記念して、ハッシュタグキャンペーンを開催！<br /><br />ツイッターで
                    <a
                      href="https://twitter.com/hashtag/WebX%E6%99%82%E4%BB%A3?src=hash&amp;ref_src=twsrc%5Etfw"
                      >#WebX時代</a
                    >
                    のタグをつけて投稿して<br />『Amazonギフト券・KABOSUグッズ・WebXチケット』をもらおう！<br /><br />【キャンペーン期間】<br />7月11日(火)20時
                    ～ 7月18日(火)20時
                    <a href="https://t.co/3TQ1QPsDpf"
                      >pic.twitter.com/3TQ1QPsDpf</a
                    >
                  </p>
                  &mdash; CoinPost（仮想通貨メディア） (@coin_post)
                  <a
                    href="https://twitter.com/coin_post/status/1678720800084328454?ref_src=twsrc%5Etfw"
                    >July 11, 2023</a
                  >
                </blockquote>
              </div>
              <div class="mx-4 w-1/4">
                <blockquote class="twitter-tweet">
                  <p lang="ja" dir="ltr">
                    1/7<br />🎉TSUBASA RIVALS FESTIVAL 🎉<br /><br />大型FESTIVALがスタートしました！<br />4つのキャンペーンがありますので全て見逃さないように👀<br /><br />➀<a
                      href="https://twitter.com/hashtag/TsubasaRivals?src=hash&amp;ref_src=twsrc%5Etfw"
                      >#TsubasaRivals</a
                    >
                    ハッシュタグキャンペーン！<br />Twitter上でのアクションによってポイントを獲得し、Amazon
                    Gift Cardと$TSUBASAUTをゲット💰<br /><br />詳細は画像とリプから！
                    <a href="https://t.co/uKfB4WTLXa"
                      >pic.twitter.com/uKfB4WTLXa</a
                    >
                  </p>
                  &mdash; キャプテン翼 -RIVALS- (@TsubasaRivalsJA)
                  <a
                    href="https://twitter.com/TsubasaRivalsJA/status/1639103546997211137?ref_src=twsrc%5Etfw"
                    >March 24, 2023</a
                  >
                </blockquote>
              </div>
            </div>
          </div>
          <div class="w-auto" id="contact">
            <!-- <img src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/assets/Signup_C.png" /> -->
            <img
              class="mx-auto my-12 px-12"
              src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/Signup_1.png"
              loading="lazy"
            />
            <img
              class="mx-auto my-12 px-12"
              src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/Signup_2.png"
              loading="lazy"
            />
            <div class="justify-center hidden md:flex">
              <router-link to="/company-detail">
                <div>
                  <img
                    class="my-6 mx-6 w-64"
                    src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/btn_download.png"
                    loading="lazy"
                  />
                </div>
              </router-link>
              <router-link to="/login">
                <img
                  class="my-6 mx-6 w-64"
                  src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/btn_free.png"
                  loading="lazy"
                />
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/FooterParts.vue";
import Header from "@/components/HeaderParts.vue";

export default {
  name: "HomeView",
  data() {
    return { arr: ["Twitter", "Tiktok", "Instagram"] };
  },
  components: {
    Footer,
    Header,
  },
  mounted() {},
  computed: {},
};
</script>
