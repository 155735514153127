<template>
  <div class="campaignAddConditions">
    <!-- side var  -->
    <SideVar />
    <!-- input panel -->
    <RightColumnOutline>
      <PageTitle>External Connect</PageTitle>
      <div class="flex justify-left items-center my-4 text-white text-lg">
        <img
          class="w-8 mx-4"
          src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/discord_icon_130958.png"
          alt="discord_icon"
        />
        <div class="mr-2">Discord</div>
        <div v-if="discord_guild" class="flex justify-left items-center w-auto">
          <img
            :src="
              'https://cdn.discordapp.com/icons/' +
              discord_guild.item_data_1 +
              '/' +
              discord_guild.item_data_3
            "
            alt="discord_icon"
            class="w-8 mx-4"
          />
          <div class="w-1/2">
            {{ discord_guild.item_data_2 }}
          </div>
          <button
            class="text-lg py-1 px-3 mx-2 text-white font-semibold rounded-xl focus:ring focus:ring-gray-300 transition ease-in-out duration-200"
            :class="
              discord_disconnect_loading
                ? 'bg-red-700 hover:bg-red-800'
                : 'bg-red-500 hover:bg-red-600'
            "
            type="button"
            @click="deleteDiscordInfo"
          >
            <i
              class="pi pi-spin pi-spinner text-sm mx-1"
              v-if="discord_disconnect_loading"
            ></i
            >Disconnect
          </button>
        </div>
        <div v-else>
          <a
            href="https://discord.com/api/oauth2/authorize?client_id=1120705399532826754&permissions=269552672&redirect_uri=https%3A%2F%2Fsocial-marketing.io%2Fsetting%2Fexternal-connect%2Fdiscord-bot-added&response_type=code&scope=identify%20bot"
            ><button
              class="text-lg py-1 px-3 text-white font-semibold rounded-xl focus:ring focus:ring-blue-300 transition ease-in-out duration-200 bg-blue-600 hover:bg-blue-700"
              type="button"
            >
              Connect
            </button></a
          >
        </div>
      </div>
    </RightColumnOutline>
  </div>
</template>

<script>
import api from "../api/api.js";

export default {
  name: "externalConnect",
  components: {},
  data() {
    return {
      discord_guild: null,
      discord_disconnect_loading: null,
    };
  },
  mounted() {
    this.getDiscordInfo();
  },
  computed: {},
  watch: {},
  methods: {
    getDiscordInfo() {
      api
        .getItemsDiscord()
        .then((response) => {
          console.log(response);
          this.discord_guild = response.data[0];
        })
        .catch((error) => {
          console.log("error", error);
          this.$toast.show("Discordのデータ取得に失敗しました", {
            type: "error",
            position: "top",
            duration: 5000,
          });
        });
    },
    deleteDiscordInfo() {
      api
        .deleteItemsDiscord()
        .then((response) => {
          console.log(response);
          this.discord_guild = null;
          this.$toast.show(
            "Discordの連携解除に成功しました。サーバーからのBotの削除は別途実施してください",
            {
              type: "success",
              position: "top",
              duration: 3000,
            }
          );
        })
        .catch((error) => {
          console.log("error", error);
          this.$toast.show("Discordの連携解除に失敗しました", {
            type: "error",
            position: "top",
            duration: 5000,
          });
        });
    },
  },
};
</script>
