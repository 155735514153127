<template>
  <div class="campaign_analize">
    <!-- side var  -->
    <SideVar />
    <!-- main div -->
    <RightColumnOutline>
      <PageTitle>Discord Summary</PageTitle>
      <div class="flex justify-between items-center">
        <div class="text-white text-left ml-4 text-sm">
          コミュニティの状況を確認しましょう
        </div>
        <div class="text-xs text-white flex items-center w-auto">
          <div class="mr-4">表示期間</div>
          <div v-if="show_flag" class="w-64">
            <Datepicker
              v-model="date"
              range
              format="yyyy/MM/dd"
              :enable-time-picker="false"
            />
          </div>
        </div>
      </div>
      <div class="px-4">
        <div class="text-left grid grid-cols-3 mt-3">
          <div v-for="dailycount in dailycountSeries" :key="dailycount">
            <p class="text-lg text-gray-200 font-semibold mb-3 mt-3">
              {{ dailycount["name"] }}
            </p>
            <div v-if="dailycountOptions.xaxis.categories.length > 0">
              <div
                v-if="
                  dailycount['name'] == '被メンションランカー' ||
                  dailycount['name'] == 'メンションランカー' ||
                  dailycount['name'] == 'リアクションランカー'
                "
              >
                <div>
                  <RankingPanel
                    :panelClass="''"
                    :ranking="dailycount['data']"
                  ></RankingPanel>
                </div>
              </div>
              <div v-else>
                <apexchart
                  :width="full"
                  :height="chart_height"
                  :title="dailycount.name"
                  :options="dailycountOptions"
                  :series="[dailycount]"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </RightColumnOutline>
  </div>
</template>

<script>
import api from "../api/api.js";
// import { useRoute } from "vue-router";
import VueApexCharts from "vue3-apexcharts";
import RankingPanel from "../components/layout/RankingComponent.vue";
import Datepicker from "@vuepic/vue-datepicker";
import { ref, onMounted } from "vue";

export default {
  name: "Campaign_regit",
  components: {
    apexchart: VueApexCharts,
    RankingPanel,
    Datepicker,
  },
  setup() {
    let date = ref();
    onMounted(() => {
      // 日付の最小〜最大を計算する処理
      const today = new Date();
      let toDate = new Date(new Date().setDate(today.getDate() + 1));
      let fromDate = new Date(new Date().setDate(toDate.getDate() - 30));
      date.value = [fromDate, toDate];
    });
    return {
      date,
    };
  },
  mounted() {},
  watch: {
    date: {
      handler() {
        this.dailycountSeries = [];
        console.log(
          "change date",
          this.date[0].toISOString().slice(0, 10),
          this.date[1].toISOString().slice(0, 10)
        );
        this.show_flag = true;
        this.getDiscordSummary();
      },
      deep: true,
    },
  },
  data() {
    return {
      chart_height: window.innerHeight / 3,
      chart_width: window.innerWidth / 3,
      show_flag: false,
      dailycountOptions: {
        chart: {
          id: "Daily_Counts",
          type: "area",
        },
        title: {
          text: "",
          align: "left",
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "datetime",
          categories: [],
          labels: {
            style: {
              colors: "#C4BBAF",
            },
          },
        },
        yaxis: {
          // type: "datetime",
          labels: {
            style: {
              colors: "#C4BBAF",
            },
          },
        },
        legend: {
          show: true,
          labels: {
            colors: "#C4BBAF",
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      dailycountSeries: [],
    };
  },
  computed: {},
  methods: {
    getDiscordSummary() {
      api
        .getDiscordSummary({
          data_from: this.date[0].toISOString().slice(0, 10),
          data_to: this.date[1].toISOString().slice(0, 10),
        })
        .then((response) => {
          console.log(response);
          this.dailycountOptions.xaxis.categories =
            response["data"]["categories"];
          for (let i = 0; i < response["data"]["name"].length; i++) {
            this.dailycountSeries.push({
              name: response["data"]["name"][i],
              data: response["data"]["data"][i],
            });
          }
        })
        .catch(() => {
          this.$toast.show("分析データの取得に失敗しました", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
        });
    },
  },
};
</script>
