<template>
  <div class="companyDetail">
    <Header />
    <section class="relative py-18 bg-white overflow-hidden">
      <img
        class="absolute left-0 top-0 z-0"
        src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/elipse.svg"
        alt=""
      />
      <div class="relative z-10">
        <div class="container max-w-2xl mx-auto mt-2">
          <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div class="mb-12">
              <label class="block text-gray-700 text-2xl font-bold mb-2">
                お問合せ
              </label>
              <div class="text-xs">以下のご入力をお願い致します。</div>
              <div class="text-xs">
                お問合せ内容を確認し、当社の営業担当からご連絡させていただきます。
              </div>
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2">
                会社名
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline max-w-lg"
                type="text"
                placeholder="会社名"
                v-model="company_name"
              />
            </div>
            <!-- Repeat for other fields... -->
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2">
                お名前
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline max-w-lg"
                type="text"
                placeholder="ソーシャル 太郎"
                v-model="name"
              />
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2">
                メールアドレス
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline max-w-lg"
                type="email"
                placeholder="会社のメールアドレスを入力"
                v-model="email"
              />
              <p v-if="emailError" class="text-red-500 text-sm italic">
                フリーアドレスは使用できません。
              </p>
            </div>
            <div class="mb-6">
              <label
                class="block text-gray-700 text-sm font-bold mb-2"
                for="tel"
              >
                電話番号
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline max-w-lg"
                id="tel"
                type="tel"
                placeholder="09012345678"
                v-model="tel"
              />
              <p v-if="telError" class="text-red-500 text-sm italic">
                電話番号は半角数字11文字で入力してください。
              </p>
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2">
                お問合せ内容
              </label>
              <textarea
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline max-w-lg"
                placeholder="例：各プランの価格について確認したい。"
                rows="8"
                v-model="content"
              />
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2">
                導入時期の想定
              </label>
              <select
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700leading-tight focus:outline-none focus:shadow-outline max-w-lg"
                id="use_in"
                v-model="use_in"
              >
                <option class="text-black" value="0">1週間以内</option>
                <option class="text-black" value="1">1ヶ月以内</option>
                <option class="text-black" value="2">3ヶ月以内</option>
                <option class="text-black" value="3">それ以上/未定</option>
              </select>
            </div>
            <div class="mt-16 mb-4">
              <div v-if="filled">
                <button
                  class="w-36 py-3 rounded mx-auto"
                  :class="filled == false ? 'bg-gray-300' : 'bg-yellow-400'"
                  @click="postCompanyInfomation()"
                >
                  <p class="text-xs font-bold text-gray-800">送信</p>
                </button>
              </div>
              <div v-else>
                <div
                  class="w-36 py-3 rounded mx-auto text-xs font-bold text-gray-800"
                  :class="filled == false ? 'bg-gray-300' : 'bg-yellow-400'"
                >
                  送信
                </div>
              </div>
            </div>
            <div class="text-xs">
              当社のプライバシーポリシーをご確認いただき、同意いただける場合送信を行ってください。
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- footter -->
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/FooterParts.vue";
import Header from "@/components/HeaderParts.vue";
import api_public from "../../api/api_public.js";
export default {
  name: "companyDetail",
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      email: "",
      name: "",
      tel: "",
      company_name: "",
      content: "",
      use_in: null,
      emailError: false,
      telError: false,
    };
  },
  mounted() {},
  computed: {
    filled() {
      return (
        this.email &&
        this.name &&
        this.tel &&
        this.company_name &&
        this.content &&
        this.use_in &&
        !this.emailError &&
        !this.telError
      );
    },
  },
  watch: {
    email(newValue) {
      if (newValue.endsWith("@gmail.com")) {
        this.emailError = true;
      } else {
        this.emailError = false;
      }
    },
    tel(newValue) {
      const pattern = /^\d{11}$/;
      this.telError = !pattern.test(newValue);
    },
  },
  methods: {
    postCompanyInfomation: function () {
      api_public
        .postCompanyInformation({
          company_name: this.company_name,
          visitor_name: this.name,
          email: this.email,
          tel: this.tel,
          content: this.content,
          want_to_use_in: this.use_in,
        })
        .then(() => {
          console.log("OK");
          this.$toast.show(
            "お問合せいただきありがとうございます。内容を確認し次第、ご連絡させていただきます。",
            {
              type: "success",
              position: "top-right",
              duration: 5000,
            }
          );
          this.$router.replace("/");
        })
        .catch(() => {
          console.log("NG");
          this.$toast.show(
            "お問合せの送信に失敗しました。入力内容をご確認ください。",
            { type: "error", position: "top-right", duration: 5000 }
          );
        });
    },
  },
};
</script>
