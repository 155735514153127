<template>
  <div class="AboutUs">
    <Header />
    <section class="relative py-32 bg-white overflow-hidden">
      <img
        class="absolute left-0 top-0"
        src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/elipse.svg"
        alt=""
      />
      <div class="mx-12 md:mx-24 text-left text-xs md:text-base">
        <p class="text-lg md:text-5xl text-left border-b mb-6">
          プライバシーポリシー
        </p>
        <p align="left"></p>
        <p align="left">
          ソーシャルマーケティング株式会社(以下｢当社｣といいます)は、当社のサービス(以下｢サービス｣といいます)を提供するにあたって、当社が取得するユーザーの個人情報の取扱いについて、以下のとおりプライバシーポリシー(以下｢本ポリシー｣といいます)を定めます。
        </p>
        <p align="left">
          なお本ポリシーにおける定義は、個人情報の保護に関する法律(平成15年法律第57号。以下｢個人情報保護法｣といいます)によります。
        </p>
        <p align="left">第1条 (関係法令・ガイドライン等の遵守)</p>
        <p align="left">
          当社は、個人情報保護法その他の関係法令、個人情報保護委員会の定めるガイドライン等および本ポリシーを遵守し、個人情報を適法かつ適正に取り扱います。
        </p>
        <p align="left">第2条 (個人情報の取得と利用)</p>
        <p align="left">
          当社は、ユーザーの個人情報について、以下の目的に必要な範囲で取得および利用し(取得状況から利用目的が明らかである場合を除きます)、ユーザーの同意がある場合または法令で認められている場合を除き、他の利用目的で利用しません。
        </p>
        <p>(1) サービスへの登録のため</p>
        <p>(2) サービスの提供のため</p>
        <p>(3) サービスの運営上必要となる事項を通知するため</p>
        <p>
          (4) サービスの利用代金の請求、返金、支払等および関連する事務処理のため
        </p>
        <p>
          (5)
          サービスの利用規約その他当社が定めるルールに違反する行為への対応、その他サービスの不正利用の調査・検知・予防のため
        </p>
        <p>
          (6)
          サービスの新機能、更新情報、キャンペーン等および当社が提供する他のサービスのご案内(電子メール、チラシ、その他のダイレクトメールの送付を含む)のため
        </p>
        <p>(7) サービスに関するユーザーから問い合わせ等に対応するため</p>
        <p>
          (8)
          サービスに関するアンケート等への協力を依頼し、またはその結果等を報告するため
        </p>
        <p>
          (9)
          サービスの利用履歴等を調査・分析し、その結果をサービスの改良・開発や広告の配信に利用するため
        </p>
        <p>(10) 本ポリシー定める第三者提供のため</p>
        <p>(11) 本ポリシーの変更を通知するため</p>
        <p align="left">第3条 (個人データの共同利用)</p>
        <p align="left">
          当社は、ユーザーの個人データを、以下のとおり共同利用する場合があります。
        </p>
        <p>(1) 共同利用する個人データの項目</p>
        <p align="left">
          ユーザーの氏名等個人を識別する情報を有しない第三者が保有する、Cookieを含む行動履歴などのユーザーに関する情報(個人関連情報)
        </p>
        <p>(2) 共同利用者の範囲</p>
        <p align="left">当社グループ企業</p>
        <p>(3) 共同利用する個人データの利用目的</p>
        <p align="left">本ポリシー第2条記載の利用目的</p>
        <p>(4) 共同利用する個人データの管理責任者</p>
        <p align="left">当社</p>
        <p align="left">第4条 (個人データの第三者提供)</p>
        <p align="left">
          当社は、本ポリシーで定める場合のほか、法令に定める場合を除いて、あらかじめユーザーから同意を得ることなくして、ユーザーの個人データを第三者に提供しません。
        </p>
        <p align="left">第5条 (保有個人データ等の開示、訂正等、利用停止等)</p>
        <p align="left">
          1.
          当社は、ユーザーから、個人情報保護法の定めに基づき保有個人データもしくは第三者提供記録の開示または保有個人データの利用目的の開示を求められたときは、ユーザー本人からの請求であることを確認した上で、遅滞なく開示または通知します。ただし、個人情報保護法その他の法令により、当社が開示または通知する義務を負わない場合はこの限りではありません。
        </p>
        <p align="left">
          2.
          当社は、ユーザーから、個人情報保護法の定めに基づき、保有個人データの内容が事実でないとして、当該保有個人データの内容の訂正、追加または削除(以下｢訂正等｣といいます)を求められたときは、ユーザー本人からの請求であることを確認した上で、遅滞なく必要な調査を行い、その結果に基づき、訂正等を行います。ただし、個人情報保護法その他の法令により、当社が訂正等を行う義務を負わない場合はこの限りではありません。
        </p>
        <p align="left">
          3.
          当社は、ユーザーから、個人情報保護法の定めに基づき、保有個人データの利用停止、消去、または第三者への提供の停止(以下｢利用停止等｣といいます)を求められたときは、ユーザー本人からの請求であることを確認した上で、遅滞なく利用停止等を行います。ただし、個人情報保護法その他の法令により、当社が利用停止等を行う義務を負わない場合はこの限りではありません。
        </p>
        <p align="left">
          4.
          前三項に定めるユーザーからの請求は、第9条記載の問い合わせ窓口にて対応を行います。
        </p>
        <p align="left">第6条 (本ポリシーの変更)</p>
        <p align="left">
          当社は、本ポリシーの内容を適宜見直し、必要に応じて変更する場合があります。この場合、当社が別途定める適用日から、変更後の本ポリシーが適用されます。
        </p>
        <p align="left">第7条 (個人データの安全管理措置)</p>
        <p align="left">
          当社は、取り扱う個人データの漏えい、滅失または毀損の防止その他の個人データの安全管理のために必要かつ適切な措置を講じます。
        </p>
        <p align="left">第8条 (当社の概要)</p>
        <p align="left">当社の商号、所在地、代表者は以下のとおりです。</p>
        <p align="left">商号: ソーシャルマーケティング株式会社</p>
        <p align="left">所在地: 東京都文京区本郷4-1-4</p>
        <p align="left">代表取締役: 相原 和明</p>
        <p align="left">第9条 (問い合わせ窓口)</p>
        <p align="left">
          当社の個人情報の取扱に関するお問い合せは下記までご連絡ください。
        </p>
        <p align="left">ソーシャルマーケティング株式会社</p>
        <p align="left">Mail: support@social-marketing.io</p>
        <p align="left">2023年6月1日制定</p>
      </div>
    </section>
    <!-- footter -->
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/FooterParts.vue";
import Header from "@/components/HeaderParts.vue";
export default {
  name: "AboutUs",
  components: {
    Footer,
    Header,
  },
  mounted() {},
  computed: {},
};
</script>
