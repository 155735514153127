<template>
  <div class="container mx-auto p-4 flex justify-center">
    <div class="w-full max-w-2xl">
      <h1 class="text-2xl font-bold mb-4">新規ホーム通知作成</h1>
      <form @submit.prevent="createNotification" class="space-y-4">
        <div>
          <label for="title" class="block mb-1">タイトル(表示されない)</label>
          <input
            id="title"
            v-model="notification.title"
            type="text"
            class="w-full p-2 border rounded"
            required
          />
        </div>

        <div>
          <label for="content" class="block mb-1"
            >内容(こっちがバーに出る。改行入れない)</label
          >
          <textarea
            id="content"
            v-model="notification.content"
            class="w-full p-2 border rounded"
            rows="5"
            required
          ></textarea>
        </div>

        <div>
          <label for="notification_type" class="block mb-1">種別</label>
          <select
            id="notification_type"
            v-model="notification.notification_type"
            class="w-full p-2 border rounded"
            required
          >
            <option value="CAMPAIGN">キャンペーン</option>
            <option value="EVENT">イベント</option>
            <option value="MAINTENANCE">メンテナンス</option>
            <option value="UPDATE">アップデート</option>
          </select>
        </div>

        <div>
          <label for="priority" class="block mb-1"
            >優先度（小さいほど上位に表示）</label
          >
          <input
            id="priority"
            v-model="notification.priority"
            type="number"
            min="1"
            max="999"
            class="w-full p-2 border rounded"
            required
          />
        </div>

        <div>
          <label for="target_audience" class="block mb-1">対象者</label>
          <select
            id="target_audience"
            v-model="notification.target_audience"
            class="w-full p-2 border rounded"
            required
          >
            <option value="ALL">全員</option>
            <option value="ANDROID">Android</option>
            <option value="IOS">iOS</option>
          </select>
        </div>

        <div>
          <label for="start_at" class="block mb-1">表示開始日時(JST)</label>
          <input
            id="start_at"
            v-model="notification.start_at"
            type="datetime-local"
            class="w-full p-2 border rounded"
            required
          />
        </div>

        <div>
          <label for="end_at" class="block mb-1">表示終了日時(JST)</label>
          <input
            id="end_at"
            v-model="notification.end_at"
            type="datetime-local"
            class="w-full p-2 border rounded"
            required
          />
        </div>

        <div class="flex items-center space-x-2">
          <input
            id="is_active"
            v-model="notification.is_active"
            type="checkbox"
            class="rounded"
          />
          <label for="is_active">すぐに表示する</label>
        </div>

        <div>
          <button
            type="submit"
            class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            :disabled="isLoading"
          >
            {{ isLoading ? "作成中..." : "通知を作成" }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, inject } from "vue";
import { useRouter } from "vue-router";
import api from "../api/api.js";

export default {
  setup() {
    const router = useRouter();
    const toast = inject("toast");
    const isLoading = ref(false);

    // 現在のJST時刻を取得してデフォルト値として設定
    const now = new Date();
    const jstOffset = 9 * 60 * 60 * 1000;
    const jstNow = new Date(now.getTime() + jstOffset);
    const defaultDateTime = jstNow.toISOString().slice(0, 16);

    const notification = ref({
      title: "",
      content: "",
      notification_type: "CAMPAIGN",
      priority: 100,
      target_audience: "ALL",
      start_at: defaultDateTime,
      end_at: "",
      is_active: true,
    });

    const validateNotification = () => {
      if (!notification.value.title || !notification.value.content) {
        toast.error("タイトルと内容は必須項目です", {
          position: "top-right",
          duration: 5000,
        });
        return false;
      }

      const startDate = new Date(notification.value.start_at);
      const endDate = new Date(notification.value.end_at);
      const now = new Date();

      if (endDate <= startDate) {
        toast.error("終了日時は開始日時より後に設定してください", {
          position: "top-right",
          duration: 5000,
        });
        return false;
      }

      if (startDate <= now && !notification.value.is_active) {
        toast.error(
          "過去の開始日時を設定する場合は、すぐに表示するを有効にしてください",
          {
            position: "top-right",
            duration: 5000,
          }
        );
        return false;
      }

      return true;
    };

    const createNotification = async () => {
      if (!validateNotification()) {
        return;
      }

      isLoading.value = true;
      try {
        const notificationData = { ...notification.value };

        // 日時をJSTからUTCに変換
        if (notificationData.start_at) {
          const startDate = new Date(notificationData.start_at);
          notificationData.start_at = startDate.toISOString();
        }
        if (notificationData.end_at) {
          const endDate = new Date(notificationData.end_at);
          notificationData.end_at = endDate.toISOString();
        }

        const response = await api.createHomeNotification(notificationData);
        console.log("Created notification:", response);

        toast.success("通知が正常に作成されました", {
          position: "top-right",
          duration: 5000,
        });
        router.push("/home-notifications");
      } catch (error) {
        console.error("通知の作成に失敗しました:", error);
        toast.error(error.message || "通知の作成に失敗しました", {
          position: "top-right",
          duration: 5000,
        });
      } finally {
        isLoading.value = false;
      }
    };

    return {
      notification,
      isLoading,
      createNotification,
    };
  },
};
</script>
