import axios from "axios";

let base = "";
if (JSON.parse(process.env.VUE_APP_ENDPOINT_USE_PROD)) {
  base = "https://social-marketing.io/api/v1";
} else if (JSON.parse(process.env.VUE_APP_ENDPOINT_USE_STG)) {
  base = "https://stg.social-marketing.io/api/v1";
} else {
  base = "http://127.0.0.1:8000/api/v1";
}
export const client = axios.create({
  baseURL: base,
});

export default {
  postCompanyInformation(params) {
    console.log(params);
    return client.post("/visited_company", params);
  },
};
