<template>
  <div class="campaign_regist">
    <SideVar />
    <RightColumnOutline>
      <PageTitle>Organizer config</PageTitle>
      <div>
        <h4
          class="text-xl font-bold tracking-wide text-white mb-5 text-left flex items-center"
        >
          <p>アカウント追加</p>
          <i
            class="pi pi-question-circle ml-1"
            v-tippy="{
              content:
                'アカウント作成時にInviteコードを入力することでお客様のOrganizerにアカウントを追加することができます。',
            }"
          ></i>
        </h4>
        <div class="mb-4 border-gray-400 border-opacity-20">
          <div class="mb-2">
            <div class="flex items-center justify-center mb-2">
              <div class="w-1/4 pl-12 text-left">
                <span class="text-lg font-medium text-gray-100"
                  >Inviteコード
                </span>
              </div>
              <div class="w-full sm:w-1/4 px-4">
                <div
                  class="max-w-xl bg-gray-400 rounded-lg border-2 border-gray-300 text-white py-2"
                >
                  {{ invite_code }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="h-36"></div>
      </div>
    </RightColumnOutline>
  </div>
</template>

<script>
import api from "../api/api.js";

export default {
  name: "OrganizerConfig",
  data() {
    return {
      invite_code: "",
    };
  },
  computed: {},
  mounted() {
    this.checkOrganizer();
  },
  methods: {
    checkOrganizer() {
      api
        .getMyOrganizer()
        .then((response) => {
          console.log(response);
          this.invite_code = response.data.invite_code;
        })
        .catch((error) => {
          this.$toast.show("オーガナイザー情報の取得に失敗しました。", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
          console.log("signout error", error);
        });
    },
  },
};
</script>
