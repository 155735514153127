<template>
  <div class="reset_password">
    <section class="relative pt-12">
      <div class="container px-4 mx-auto">
        <div class="h-full">
          <div class="relative max-w-xs mx-auto text-center">
            <a class="inline-block mx-auto mb-10" href="#">
              <img class="block" src="" alt="" />
            </a>
            <h2 class="text-2xl text-gray-500 font-semibold mb-2">
              Reset Password
            </h2>
            <br />
            <div
              class="relative w-full h-14 py-4 px-3 mb-8 border border-gray-400 hover:border-blue focus-within:border-green-500 rounded-lg"
            >
              <span
                class="absolute bottom-full left-0 ml-3 -mb-1 transform translate-y-0.5 text-xs font-semibold text-gray-600 px-1 bg-gray-200"
                >Email</span
              >
              <input
                class="block w-full outline-none bg-transparent text-sm text-gray-600 font-medium"
                id="signInInput2-1"
                type="email"
                placeholder="xxx@test.com"
                v-model="email"
              />
            </div>
            <button
              v-bind:class="btnclass"
              v-bind:disabled="!email"
              @click="resetPassword"
            >
              パスワード初期化
            </button>
            <div class="py-3" v-if="error_message">
              <div class="container px-4 mx-auto">
                <div class="p-4 bg-red-500 rounded-lg">
                  <div class="flex w-full h-full items-center justify-between">
                    <div class="flex items-center pr-6">
                      <span class="flex-shrink-0 self-start">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 12C9.75278 12 9.5111 12.0733 9.30554 12.2107C9.09998 12.348 8.93976 12.5432 8.84516 12.7716C8.75055 13.0001 8.72579 13.2514 8.77402 13.4939C8.82225 13.7363 8.94131 13.9591 9.11612 14.1339C9.29094 14.3087 9.51367 14.4277 9.75614 14.476C9.99862 14.5242 10.25 14.4995 10.4784 14.4049C10.7068 14.3102 10.902 14.15 11.0393 13.9445C11.1767 13.7389 11.25 13.4972 11.25 13.25C11.25 12.9185 11.1183 12.6005 10.8839 12.3661C10.6495 12.1317 10.3315 12 10 12ZM10 10.5C10.2652 10.5 10.5196 10.3946 10.7071 10.2071C10.8946 10.0196 11 9.76522 11 9.5V6.5C11 6.23478 10.8946 5.98043 10.7071 5.79289C10.5196 5.60536 10.2652 5.5 10 5.5C9.73479 5.5 9.48043 5.60536 9.2929 5.79289C9.10536 5.98043 9 6.23478 9 6.5V9.5C9 9.76522 9.10536 10.0196 9.2929 10.2071C9.48043 10.3946 9.73479 10.5 10 10.5ZM10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34874 18.9425 4.80691 17.0678 2.93219C15.1931 1.05746 12.6513 0.00294858 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C17.9976 12.121 17.1539 14.1544 15.6542 15.6542C14.1544 17.1539 12.121 17.9976 10 18Z"
                            fill="#7A0C2E"
                          ></path>
                        </svg>
                      </span>
                      <span
                        class="text-sm leading-5 text-red-900 font-medium ml-3"
                        >{{ error_message }}</span
                      >
                    </div>
                    <button class="inline-block" @click="refresh_error">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.17495 5.99999L10.7583 2.42499C10.9152 2.26807 11.0034 2.05524 11.0034 1.83333C11.0034 1.61141 10.9152 1.39858 10.7583 1.24166C10.6014 1.08474 10.3885 0.996582 10.1666 0.996582C9.9447 0.996582 9.73187 1.08474 9.57495 1.24166L5.99995 4.82499L2.42495 1.24166C2.26803 1.08474 2.0552 0.996582 1.83328 0.996582C1.61136 0.996582 1.39854 1.08474 1.24162 1.24166C1.0847 1.39858 0.996539 1.61141 0.996539 1.83333C0.996539 2.05524 1.0847 2.26807 1.24162 2.42499L4.82495 5.99999L1.24162 9.57499C1.16351 9.65246 1.10151 9.74463 1.05921 9.84618C1.0169 9.94773 0.995117 10.0566 0.995117 10.1667C0.995117 10.2767 1.0169 10.3856 1.05921 10.4871C1.10151 10.5887 1.16351 10.6809 1.24162 10.7583C1.31908 10.8364 1.41125 10.8984 1.5128 10.9407C1.61435 10.983 1.72327 11.0048 1.83328 11.0048C1.94329 11.0048 2.05221 10.983 2.15376 10.9407C2.25531 10.8984 2.34748 10.8364 2.42495 10.7583L5.99995 7.17499L9.57495 10.7583C9.65242 10.8364 9.74459 10.8984 9.84614 10.9407C9.94769 10.983 10.0566 11.0048 10.1666 11.0048C10.2766 11.0048 10.3855 10.983 10.4871 10.9407C10.5886 10.8984 10.6808 10.8364 10.7583 10.7583C10.8364 10.6809 10.8984 10.5887 10.9407 10.4871C10.983 10.3856 11.0048 10.2767 11.0048 10.1667C11.0048 10.0566 10.983 9.94773 10.9407 9.84618C10.8984 9.74463 10.8364 9.65246 10.7583 9.57499L7.17495 5.99999Z"
                          fill="#212B36"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import { sendPasswordResetEmail } from "@firebase/auth";
// import api from "../api/api.js";
// import store from "@/store";

// const config = {
//   apiKey: "AIzaSyCZyV2mq2f0slEe7L2cWQVkF7rZfNUikr8",
//   authDomain: "fuelcrypto-client.firebaseapp.com",
//   projectId: "fuelcrypto-client",
//   storageBucket: "fuelcrypto-client.appspot.com",
//   messagingSenderId: "431295275079",
//   appId: "1:431295275079:web:d494ad063f797e6c279162",
// };

const config = {
  apiKey: "AIzaSyBRBc9h6AOwtBqJlyYKolhPvD3HgMzPHqk",
  authDomain: "sociama-client.firebaseapp.com",
  projectId: "sociama-client",
  storageBucket: "sociama-client.appspot.com",
  messagingSenderId: "690362204245",
  appId: "1:690362204245:web:90ea919e84be53171694dc",
};

firebase.initializeApp(config);

export default {
  data() {
    return {
      email: "",
      error_message: "",
    };
  },
  computed: {
    btnclass: function () {
      if (this.email != "") {
        return "block w-full py-4 mb-4 leading-6 text-white font-semibold bg-blue-500 hover:bg-blue-600 rounded-lg transition duration-200";
      } else {
        return "block w-full py-4 mb-4 leading-6 text-white font-semibold bg-gray-500 hover:bg-gray-600 rounded-lg transition duration-200";
      }
    },
  },
  methods: {
    resetPassword: function () {
      sendPasswordResetEmail(this.email)
        .then(() => {
          console.log("success to sent reset mail");
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/email-already-in-use":
              console.log("登録済アドレスの登録要求" + ": " + error.message);
              this.error_message = "このアドレスは登録済です";
              break;
            case "auth/invalid-email":
              console.log("アドレスフォーマット不正" + ": " + error.message);
              this.error_message = "アドレスのフォーマットが正しくありません";
              break;
            default:
              console.log(error.code + ": " + error.message);
              this.error_message = "something wrong";
          }
        });
    },
    refresh_error: function () {
      this.error_message = "";
    },
  },
};
</script>
