<template>
  <div class="AboutUs">
    <Header />
    <section class="relative py-32 bg-white overflow-hidden">
      <img
        class="absolute left-0 top-0"
        src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/elipse.svg"
        alt=""
      />
      <div class="mx-12 md:mx-24 text-left text-xs md:text-base">
        <p class="text-lg md:text-5xl text-left border-b mb-6">利用規約</p>
        <p align="center">第1章総則</p>
        <p>第1条 (本規約の目的)</p>
        <p>
          この規約(以下｢本規約｣といいます)は、ソーシャルマーケティング株式会社(以下｢当社｣といいます)が提供するSNSにおけるマーケティング状況を閲覧するためのSaaSサービス｢ソシャマ｣(以下｢本サービス｣といいます)の利用に関して必要な条件を定めることを目的とします。
        </p>
        <p>第2条 (用語の定義)</p>
        <p>本規約において用いる用語の定義は以下に定める通りとします。</p>
        <p>
          (1) 利用契約:
          本規約に基づき当社とユーザーとの間で締結される本サービスの提供に関する契約
        </p>
        <p>(2) 申込者: 当社に本サービスの提供を申し込む法人、機関等</p>
        <p>
          (3) ユーザー情報:
          ユーザーが本サービスの申込み及び利用に際して当社に対して提供したユーザーに関する情報
        </p>
        <p>
          (4) ユーザー:
          利用契約を当社と締結し、本サービスの提供を受ける法人、機関等
        </p>
        <p>
          (5) 認証情報:
          ユーザーID、パスワード、外部サービスのアカウント情報その他本サービスの利用に必要なユーザー認証を行うための情報
        </p>
        <p>第3条 (本規約の変更)</p>
        <p>
          1.
          当社は、予め定めた予告期間をおいて、(i)本規約を変更する旨、(ii)変更後の新規約の内容、(iii)変更の効力発生日をユーザーに通知または当社所定の方法で周知することにより、本規約を変更することができます。
        </p>
        <p>
          2.
          前項の規定にかかわらず、当社は、次の各号に定める場合、本規約の内容、本サービスの利用料金等をユーザーの承諾なく変更することができます。
        </p>
        <p>(1) 本規約の変更が、利用者の一般の利益に適合する場合。</p>
        <p>
          (2)
          本規約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものである場合。
        </p>
        <p>
          3.
          本規約の変更の効⼒発生日の後、ユーザーが本サービスを利用した場合には、変更後の本規約に同意したものとみなします。
        </p>
        <p align="center">第2章利用契約</p>
        <p>第4条 (利用契約の適用)</p>
        <p>
          当社は、利用契約その他当社が定める条件にて本サービスの提供を行い、ユーザーは利用契約その他当社が定める条件に従ってこれを利用します。
        </p>
        <p>第5条 (利用契約の成立)</p>
        <p>
          1.
          申込者は、本規約の内容を承諾の上、当社所定のフォームにおいて所定の事項を入力して当社に対して送信その他当社が定める方法により、本サービスの利用のための申込みを行い、当社は、当該申込みを予め承諾します。ユーザーが当社に対して当該申込みをした時に利用契約が成立し、本規約は、利用契約の一部を構成します。
        </p>
        <p>
          2.
          当社は、前項の規定にかかわらず、次に掲げる事項に該当することが判明した場合には、当社による承諾を取り消す場合があります。
        </p>
        <p>(1) 申込者が虚偽の事実を申告したとき</p>
        <p>(2) 利用申込内容に不備があるとき</p>
        <p>
          (3) 申込者が本サービスの利用にかかる料金の支払を怠るおそれがあるとき
        </p>
        <p>(4) 本サービスの提供が技術上困難なとき</p>
        <p>(5) 申込者が過去に当社との契約に違反したことがあるとき</p>
        <p>(6) 申込者が同業他社であるとき</p>
        <p>
          (7)
          本サービスの利用目的が、評価、解析その他本サービスの本来の目的と異なるものであると疑われる場合
        </p>
        <p>(8) その他当社が不適当と判断する相当な理由があるとき</p>
        <p>
          3.
          利用契約は、契約成立日におけるユーザー、当社間の合意を規定するものであり、利用契約締結前に相互に取り交わした合意事項、各種資料、申入れ等が利用契約の内容と相違する場合は、利用契約の内容が優先されます。
        </p>
        <p>第6条 (通知及びユーザー情報の変更)</p>
        <p>
          1.
          本サービスに関して当社がユーザーに対して行う通知は、本サービスにおいて実施する方法、ユーザー情報としてユーザーから提供された連絡先に対して送信その他の方法によって行うものとし、当該通知は、本規約において特段の定めがない限り、通常到達すべきであった時にユーザーに到達したものとみなします。
        </p>
        <p>
          2.
          ユーザーは、ユーザー情報に変更が生じた場合は、当社の定める方法により、当該ユーザー情報の変更を、遅滞なく当社に届け出なければなりません。
        </p>
        <p>
          3.
          ユーザーが前項の通知を怠ったことにより、ユーザーまたは第三者が損害または不利益を被った場合であっても、当社は、当社に故意又は過失がある場合を除いて責任を負いません。
        </p>
        <p>
          4.
          当社はユーザーに対して、ユーザー情報の真偽を確認し、また追加の情報提供を求める場合があり、ユーザーは予めこれに同意します。
        </p>
        <p>第7条 (利用契約の期間) <a name="_Hlk85804665"></a></p>
        <p>
          利用契約の契約期間は、本サービスの利用開始日(利用開始の申込みに対して当社が承諾をした日または当社とユーザーとの間で合意した日をいいます)から1年間とします。ただし、契約期間満了の1か月前までにユーザーおよび当社のいずれからも別段の意思表示のないときは、引き続き同一条件をもって、契約期間はさらに1年間自動的に継続延長され、以後もまた同様とします。
        </p>
        <p>第8条 (利用契約の解約)</p>
        <p>
          1.
          ユーザーは、当社に解約の申込を行うことにより、利用契約を解約し、本サービスの利用を終了することができます。ユーザーは、利用契約を解約するときには、解約を希望する日の1か月前までに、当社が定める方法により当社に解約の申込みを行います。利用契約は、ユーザーから当社に解約の申込が到達し、当社が本サービスに係るユーザーの利用権限を削除した時点で終了します。
        </p>
        <p>
          2.
          当社は、ユーザーが次の各号のいずれか一つにでも該当したときは、ユーザーに何らの通知・催告を要せず直ちに利用契約の全部または一部を解除できます。
        </p>
        <p>(1) 手形または小切手が不渡りとなったとき</p>
        <p>
          (2)
          差押え、仮差押え、仮処分もしくは競売の申し立てがあったとき、または、租税滞納処分を受けたとき
        </p>
        <p>
          (3)
          破産手続開始、特定調停手続開始、会社更生手続開始もしくは民事再生手続開始、その他これらに類似する倒産手続開始の申し立てがあったとき、または清算に入ったとき
        </p>
        <p>
          (4)
          解散または利用契約の履行に係る事業の全部もしくは重要な一部を第三者に譲渡しようとしたとき
        </p>
        <p>
          (5)
          監督省庁から営業の取消・停止処分等を受けたとき、または転廃業しようとしたときであって、利用契約を履行できないと合理的に見込まれるとき
        </p>
        <p>
          (6)
          その他前各号に準ずるような利用契約を継続し難い重大な事由が発生したとき
        </p>
        <p>
          3.
          当社は、利用契約に基づくユーザーの債務が履行されず、ユーザーへ相当の期間を定めて催告したにもかかわらず、なおその期間内に履行されないときは、利用契約の全部または一部を解除することができます。
        </p>
        <p>
          4.
          当社は、前2項に基づき利用契約を解除することでユーザーに生じた損害を賠償する責任を負いません。
        </p>
        <p>
          5.
          ユーザーは、第2項各号のいずれかに該当したとき、または第3項に定める催告をしても債務を履行しないときは、当然に期限の利益を失い、当社に対して負担する一切の金銭債務を直ちに弁済します。
        </p>
        <p align="center">第3章本サービスの提供</p>
        <p>第9条 (サービスの範囲)</p>
        <p>
          1.
          当社は、当社指定の条件下で、ユーザーが管理する端末機器(パソコン、スマートフォン、タブレット等をいい、以下｢端末機器｣といいます)から電気通信回線を経由して当社の指定サーバーに接続することにより、本サービスを利用することのできる環境を提供します。
        </p>
        <p>
          2.
          ユーザーは、本サービスの利用のためにインストールが必要なソフトウェア(以下｢必要ソフトウェア｣といいます)がある場合、当社または第三者が提供するアプリケーション配信サービス(以下｢配信サービス｣といいます)を通じて取得し、必要なライセンスを受けます。当社は、当社が別途定める場合を除き、必要ソフトウェアおよびアプリケーション配信サービスの性能、内容、継続性、完全性について何ら保証しません。必要ソフトウェアまたは配信サービスの全部または一部について、不具合その他の理由による中止、停止、バグ等によって、ユーザーが端末機器で本サービスの利用ができなくなった場合であっても、当社はその責任を負いません。但し、当社に故意または重過失がある場合にはこの限りではありません。
        </p>
        <p>
          3.
          ユーザーが使用する端末機器から本サービスに接続する電気通信回線は、ユーザー自身の責任と費用負担において、確保、維持されるものとし、当社は一切の責任を負いません。
        </p>
        <p>
          4.
          ユーザーはその利用する端末機器に応じて、コンピューター・ウィルスの感染の防止、不正アクセスおよび情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じます。
        </p>
        <p>第10条 (認証情報)</p>
        <p>
          1.
          認証情報は、当社が定める方法および使用条件に基づき、ユーザー自身が作成します。
        </p>
        <p>
          2.
          ユーザーは、自らの管理責任により、その認証情報を不正使用されないように厳格に管理し、当社は、認証情報の不正使用によってユーザーに生じた損害について責任を負いません。但し、当社の故意または重過失による場合を除きます。
        </p>
        <p>第11条 (本サービスの提供・利用制限)</p>
        <p>
          1.
          本サービスは、ユーザーが指定する特定のキャンペーン等に係るSNSにおけるマーケティング状況を数値化して閲覧できるようにすることを目的とするサービスです。
        </p>
        <p>
          2.
          本サービスはSaaSであり、ユーザーによる本サービスの利用は、ユーザーの管理する端末機器から当社指定のURLへ接続することにより行われるものとし、別途定められているものを除き、本サービスを構成するソフトウェア自体をダウンロードしたり、コピーする等の方法により本サービスを構成するソフトウェアを入手することはできません。
        </p>
        <p>
          3.
          ユーザーは、本サービスを、ユーザーの役職員(ユーザーの業務実施職域内でユーザーの職務に従事する業務委託先の従業員を含みます)に対してのみ使用させることができ、当社が認める場合を除き、その他の第三者に対して使用させることはできません。
        </p>
        <p>第12条 (本サービスのメンテナンス)</p>
        <p>
          1.
          当社は、本サービスの円滑な運営のために、計画的なメンテナンス(以下｢計画メンテナンス｣といいます)を実施することがあるものとし、計画メンテナンスの実施のために
          本サービスの提供を一時的に中断することがあります。このとき、当社は、計画メンテナンスを実施する旨を、当該計画メンテナンスにかかるユーザーに周知するものとし、計画メンテナンスにより生じたユーザーの損害等について当社は責任を負いません。但し、当社に故意または重大な過失がある場合を除きます。
        </p>
        <p>
          2.
          当社は、本サービスの維持のためにやむを得ないと判断したときには、緊急のメンテナンス(以下｢緊急メンテナンス｣といいます)を実施するために本サービスの提供を一時的に中断することがあります。このとき、当社は、当該緊急メンテナンスの実施後速やかに、緊急メンテナンスを実施した旨を、当該緊急メンテナンスにかかるユーザーに報告するものとし、緊急メンテナンスにより生じたユーザーの損害等について当社は責任を負いません。但し、当社に故意または重大な過失がある場合を除きます。
        </p>
        <p>第13条 (本サービスの変更および廃止)</p>
        <p>
          1.
          当社は、本サービスの機能追加、改善を目的として、当社の裁量により本サービスの一部の追加・変更を行うことがあります。ただし、当該追加・変更によって、変更前の本サービスの全ての機能・性能が維持されることを保証しません。
        </p>
        <p>
          2.
          当社は、本サービスの全部または一部を、いつでも廃止することができます。
        </p>
        <p>
          3.
          本サービスの全部または一部を廃止する場合、当社は、[3]か月の予告期間をおいてユーザーにその旨を通知します。但し、やむを得ない理由がある場合、予告期間なく、又はより短期の予告にて廃止することができます。
        </p>
        <p align="center">第4章利用料金</p>
        <p>第14条 (本サービスの利用料金、算定方法等)</p>
        <p>
          本サービスの利用料金、算定方法は別途当社が定めるとおりとします。当社は利用料金を随時改定することがあります。この改定は第３条に従って行います。
        </p>
        <p>第15条 (利用料金の支払方法)</p>
        <p>
          1.
          ユーザーは、利用契約が成立した日から起算して利用契約の終了日までの期間について、本サービスの利用料金およびこれにかかる消費税等(以下併せて｢利用料金等｣といいます)を支払います。
        </p>
        <p>
          2.
          ユーザーは、本サービスの利用料金等を、当社からの請求書に従い当社が指定する期日(以下｢支払期日｣といいます)までに当社指定の銀行口座に振込送金する方法で支払います(支払期日が金融機関の休業日にあたる場合は、当該支払期日は前営業日とします)。なお、支払に必要な振込手数料その他の必要はユーザーの負担とします。
        </p>
        <p>第16条 (遅延損害金)</p>
        <p>
          ユーザーが、本サービスの利用料金等を所定の支払期日が過ぎてもなお支払わない場合、ユーザーは、所定の支払期日の翌日から支払日までの日数に、年14.6%の利率で計算した金額を遅延損害金として支払います。
        </p>
        <p align="center">第5章 その他</p>
        <p>第17条 (個人情報の管理)</p>
        <p>
          当社は、本サービスの提供により当社が取得するユーザーのデータに個人情報が含まれていた場合、個人情報の保護に関する法律および当社が以下に定めるプライバシーポリシーに基づいて、紛失・破壊・改竄・漏洩等の危険から保護するための合理的な安全管理措置を講じます。
        </p>
        <p>[プライバシーポリシーのURLを記載]</p>
        <p>第18条 (当社による情報の管理・利用)</p>
        <p>
          1.
          当社は、本サービスの改良、サービスの維持管理等を目的とする統計調査等のため、ユーザーの本サービスの利用状況、画面・項目の利用頻度等の統計数値等を利用し、解析し、二次加工して活用する場合があり、ユーザーはこれらに同意します。
        </p>
        <p>
          2.
          ユーザーは、当社が、裁判所、その他の法的な権限のある官公庁、自主規制機関からの命令・要請等により、ユーザーが入力したデータの開示ないし提供を求められた場合は、かかる命令・要請等に従って、ユーザーが入力したデータの開示ないし提出をすることがあることを承諾し、かかる開示ないし提出に対して異議を述べません。
        </p>
        <p>第19条 (委託)</p>
        <p>
          当社は、本サービスの提供に関する業務の全部または一部をユーザーの承諾なしに第三者に委託することができます。ただし、その場合、当社は委託先を管理する責任を負います。
        </p>
        <p>第20条 (本サービスにかかる著作権等)</p>
        <p>
          1.
          本サービスを構成する有形・無形の構成物(ソフトウェアプログラム、データベース、アイコン、画像、文章、マニュアル等の関連ドキュメントを含みます)に関する著作権、商標権等の知的財産権、その他の一切の権利は、当社または当社に権利を許諾した第三者に帰属します。
        </p>
        <p>
          2.
          本サービスの一部として当社がユーザーに提供する、クライアント環境にて動作させるソフトウェア等において、その使用許諾条件が別途書面等にて提示されている場合には、ユーザーは、当該使用許諾条件に従って当該ソフトウェア等を使用します。
        </p>
        <p>第21条 (禁止事項)</p>
        <p>
          1.
          ユーザーは、本サービスの利用において以下の行為を行ってはなりません。
        </p>
        <p>
          (1)
          当社もしくは第三者の著作権、商標権等の知的財産権を侵害する行為、または、侵害するおそれのある行為
        </p>
        <p>
          (2)
          当社もしくは第三者の財産、プライバシーもしくは肖像権を侵害する行為、または、侵害するおそれのある行為
        </p>
        <p>
          (3)
          当社もしくは第三者を誹謗中傷・侮辱し、または、当社もしくは第三者の名誉もしくは信用を毀損する行為
        </p>
        <p>
          (4) 詐欺、恐喝等の犯罪に結びつく行為、または結びつくおそれのある行為
        </p>
        <p>(5) 公序良俗に反する行為</p>
        <p>(6) 第三者になりすまして本サービスを利用する行為</p>
        <p>
          (7)
          当社もしくは第三者の設備等の利用、運営に支障を与える行為、または、支障を与えるおそれのある行為
        </p>
        <p>
          (8)
          有害なコンピュータプログラム等を送信もしくは掲載し、または、第三者が受信可能な状態におく行為
        </p>
        <p>
          (9)
          本サービスを構成するハードウェアまたはソフトウェアへの不正アクセス行為、クラッキング行為その他設備等に支障を与える行為
        </p>
        <p>(10) 本サービスの提供を妨害する行為またはそのおそれがある行為</p>
        <p>
          (11)
          本サービスを構成するソフトウェアの解析、リバースエンジニアリングその他ソースコードを入手しようとする行為
        </p>
        <p>
          (12)
          法令に基づき監督官庁等への届出、許認可の取得等の手続が義務づけられている場合に、当該手続を履行せず、その他当該法令に違反する行為
        </p>
        <p>
          (13)
          上記各号の他、法令もしくは公序良俗に違反する行為、当社の信用を毀損し、もしくは、当社の財産を侵害する行為、または、第三者に不利益を与える行為
        </p>
        <p>
          2.
          当社は、ユーザーの行為が前項各号のいずれかに該当すると判断した場合、または他のユーザーその他の第三者からの権利侵害の主張があった場合には、ユーザーへの事前の通知なしに、ユーザーの本サービスの利用を停止し、または利用契約の解除をする等、当社が適当と判断する措置を講ずることができます。これによりユーザーに損害が生じた場合であっても、当社は一切の責任を負いません。
        </p>
        <p>第22条 (ユーザーの責任)</p>
        <p>
          1.
          ユーザーは、本サービスの利用に伴い、自己の責に帰すべき事由により第三者に対して損害を与えた場合、または第三者からクレーム等の請求がなされた場合、自己の責任と費用をもって処理、解決します。ユーザーが本サービスの利用に伴い、第三者から損害を被った場合、または第三者に対してクレーム等の請求を行う場合においても同様とします。
        </p>
        <p>
          2.
          ユーザーが、本サービスの利用に関連して当社または第三者に損害を及ぼした場合には、その損害(直接損害および通常損害のみならず、逸失利益、事業機会の喪失、データの喪失、事業の中断、その他の間接損害、特別損害、派生的損害および付随的損害を含む全ての損害を意味します)を賠償する責任を負います。
        </p>
        <p>第23条 (当社の責任)</p>
        <p>
          当社の責に帰すべき事由により、利用契約に基づく個々の本サービスが全く利用できない(当社が当該本サービスを全く提供しない場合もしくは当該本サービスの支障が著しく本サービスが全く利用できない場合を含み、以下｢利用不能｣といいます)ためにユーザーに損害が発生した場合、ユーザーが本サービスを利用不能となったことを当社が知った時から起算して24時間以上利用不能の状態が継続したときに限り、当社は、利用不能の生じた日の属する月の利用料金等の金額を限度として、賠償責任を負います。ただし、当社の責に帰することができない事由から生じた損害、当社が予見すべきであったか否かを問わず特別の事情から生じた損害、逸失利益については、当社は賠償責任を負いません。本サービスの利用不能に関して当社が負う法律上の責任は、本項に定める範囲に限られます。但し、当社に故意または重過失がある場合を除きます。
        </p>
        <p align="center">第6章雑則</p>
        <p>第24条 (秘密情報の取り扱い)</p>
        <p>
          1.
          ユーザーは、本サービスに関し当社から口頭、書面その他の記録媒体等により提供もしくは開示されたかまたは知り得た、当社の技術、営業、業務、財務、組織、その他の情報(以下総称して｢秘密情報｣といいます)を善良な管理者の注意をもって管理し、事前の当社の書面による同意がない限り、他に漏洩し、または開示してはいけません。ただし以下の情報は秘密情報から除外されます。
        </p>
        <p>
          (1)
          当社から提供もしくは開示がなされたとき、または知得したときに、既に一般に公知となっていた、または、既に知得していたもの
        </p>
        <p>
          (2)
          当社から提供もしくは開示がなされた後、または知得した後、自己の責に帰せざる事由により刊行物その他により公知となったもの
        </p>
        <p>
          (3)
          提供または開示の権限のある第三者から秘密保持義務を負わされることなく適法に取得したもの
        </p>
        <p>(4) 秘密情報によることなく単独で開発したもの</p>
        <p>
          2.
          ユーザーは、法令上の強制力を伴う開示請求が公的機関よりなされた場合は、その請求に応じる限りにおいて、当社への速やかな通知を行うことを条件に秘密情報を開示することができます。
        </p>
        <p>
          3.
          ユーザーは秘密情報を本サービスを利用する目的以外の目的をもって使用することはできません。
        </p>
        <p>
          4.
          ユーザーは、利用契約の終了時または当社から求められた場合にはいつでも、遅滞なく、当社の指示に従い、秘密情報ならびに秘密情報を記載または包含した書面その他の記録媒体およびその全ての複製物を返却または廃棄します。
        </p>
        <p>第25条 (権利譲渡等の禁止)</p>
        <p>
          1.
          ユーザーは、当社の書面(電磁的記録を含みます)による事前の承諾なく、利用契約上の地位を第三者に承継させ、または利用契約に基づく権利および義務の全部または一部を、第三者に譲渡、貸与、担保に供する等はできません。
        </p>
        <p>
          2.
          当社は本サービスに係る事業を第三者に譲渡した場合(会社分割その他事業が移転するあらゆる場合を含みます)には、当該事業譲渡に伴い、利用契約上の地位、本規約に基づく権利及び義務並びにユーザー情報を事業譲渡の譲受人に譲渡することができ、ユーザーは、かかる譲渡につき予め承諾します。
        </p>
        <p>第26条 (反社会的勢力等の排除)</p>
        <p>
          1.
          当社は、ユーザーが反社会的勢力(暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋、その他暴力、威力または詐欺的手法を使用して経済的利益を追求する集団または個人を意味します。以下同様です)であることが判明したときには、催告を要せずユーザーに通知することにより直ちに利用契約を将来に向かって解除することができます。
        </p>
        <p>
          2.
          前項に定める場合を除き、当社は、ユーザーの取締役、監査役、従業員その他の構成員、株主、取引先、もしくは顧問その他のアドバイザーが反社会的勢力であること、またはユーザーが資金提供その他を通じて反社会的勢力の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力との何らかの交流もしくは関与を行っていることが判明した場合、ユーザーに通知することにより直ちに利用契約を将来に向かって解除することができます。
        </p>
        <p>
          3.
          当社は、本条に定める解除によりユーザーに生じた損害の賠償責任を負いません。
        </p>
        <p>第27条 (存続規定)</p>
        <p>
          利用契約が解除その他の理由により終了した場合であっても、第2条、第5条第3項、第8条第4項および第5項、第9条第2項および第3項、第10条第2項、第12条、第15条、第16条、第18条、第20条、第21条第2項、第22条、第23条、第25条、第26条第3項、本条、第29条および第30条の規定は、利用契約終了後も有効に存続します。また、第24条の規定は、利用契約終了後5年間に限り有効に存続します。
        </p>
        <p>第28条 (協議)</p>
        <p>
          本規約に定めのない事項および解釈の疑義については、法令の規定並びに慣習に従うほか、両当事者誠意をもって協議解決を図ります。
        </p>
        <p>第29条 (合意管轄)</p>
        <p>
          本規約および利用契約に関する紛争については、東京地方裁判所をもって第一審の専属的合意管轄裁判所とします。
        </p>
        <p>第30条 (準拠法)</p>
        <p>本規約および利用契約に関する準拠法は、日本法とします。</p>
        <p>以 上</p>
        <p align="left">2023年6月1日制定</p>
      </div>
    </section>
    <!-- footter -->
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/FooterParts.vue";
import Header from "@/components/HeaderParts.vue";
export default {
  name: "AboutUs",
  components: {
    Footer,
    Header,
  },
  mounted() {},
  computed: {},
};
</script>
