<!-- QuizHeaderImageUpload.vue -->
<template>
  <div>
    <div class="mb-4">
      <label for="quiz_header_url" class="block mb-1">クイズヘッダーURL</label>
      <input
        id="quiz_header_url"
        v-model="localHeaderUrl"
        type="url"
        class="w-full max-w-lg p-2 border rounded"
        @input="updateHeaderUrl"
      />
    </div>
    <div class="mb-4">
      <button
        @click="openFileUpload"
        class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mr-2"
      >
        画像をアップロード
      </button>
      <button
        @click.prevent="openGallery"
        class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
      >
        ギャラリーから選択
      </button>
      <input
        ref="fileInput"
        type="file"
        accept="image/*"
        style="display: none"
        @change="uploadFile"
      />
    </div>
    <div class="flex space-x-4">
      <div v-if="showGallery" class="w-3/5 mb-4 bg-gray-100 p-4 rounded">
        <h3 class="text-lg font-semibold mb-2">ギャラリー</h3>
        <div class="grid grid-cols-3 gap-4">
          <div
            v-for="image in galleryImages"
            :key="image.url"
            class="cursor-pointer"
            @click="selectGalleryImage(image.url)"
          >
            <img :src="image.url" alt="Gallery image" class="w-full h-auto" />
          </div>
        </div>
      </div>
      <div v-if="localHeaderUrl" class="w-3/5 mb-4">
        <h3 class="text-lg font-semibold mb-2">プレビュー</h3>
        <img
          :src="localHeaderUrl"
          alt="Quiz Header preview"
          class="max-w-full h-auto"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import api from "../api/api.js";

export default {
  name: "QuizHeaderImageUpload",
  props: {
    headerUrl: {
      type: String,
      default: "",
    },
  },
  emits: ["update:headerUrl"],
  setup(props, { emit }) {
    const localHeaderUrl = ref(props.headerUrl);
    const fileInput = ref(null);
    const showGallery = ref(false);
    const galleryImages = ref([]);

    watch(
      () => props.headerUrl,
      (newValue) => {
        localHeaderUrl.value = newValue;
      }
    );

    const updateHeaderUrl = () => {
      emit("update:headerUrl", localHeaderUrl.value);
    };

    const openFileUpload = () => {
      fileInput.value.click();
    };

    const uploadFile = async (event) => {
      const file = event.target.files[0];
      if (file) {
        try {
          const formData = new FormData();
          formData.append("file", file);
          const response = await api.uploadQuizHeaderImage(formData);
          localHeaderUrl.value = response.data.url;
          updateHeaderUrl();
        } catch (error) {
          console.error(
            "クイズヘッダー画像のアップロードに失敗しました:",
            error
          );
          // エラー処理を追加（例：トースト通知）
        }
      }
    };

    const openGallery = async () => {
      try {
        const response = await api.getQuizHeaderImages();
        galleryImages.value = response.data;
        showGallery.value = true;
      } catch (error) {
        console.error("クイズヘッダー画像の取得に失敗しました:", error);
        // エラー処理を追加（例：トースト通知）
      }
    };

    const selectGalleryImage = (url) => {
      localHeaderUrl.value = url;
      updateHeaderUrl();
      showGallery.value = false;
    };

    return {
      localHeaderUrl,
      fileInput,
      showGallery,
      galleryImages,
      updateHeaderUrl,
      openFileUpload,
      uploadFile,
      openGallery,
      selectGalleryImage,
    };
  },
};
</script>
