<template>
  <div class="campaignAddConditions">
    <!-- side var  -->
    <SideVar />
    <!-- input panel -->
    <RightColumnOutline>
      <div
        class="flex flex-wrap items-center justify-between -mx-4 mb-8 pb-6 border-b border-gray-400 border-opacity-20"
      >
        <div class="w-full sm:w-auto px-4 mb-6 sm:mb-0">
          <h2
            class="mb-6 md:text-3xl font-bold font-heading tracking-px-n leading-none md:max-w-xl mx-auto text-gray-100"
          >
            External Connect -> Discord
          </h2>
        </div>
        <div class="w-full sm:w-auto px-4"></div>
      </div>
      <div class="flex justify-left items-center my-4 text-white text-lg">
        <img
          class="w-8 mx-4"
          src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/discord_icon_130958.png"
          alt="discord_icon"
        />
        {{ message }}
      </div>
    </RightColumnOutline>
  </div>
</template>

<script>
import SideVar from "@/components/SideVar.vue";
import { useRoute } from "vue-router";
import api from "../api/api.js";
// import { ref } from "vue";

export default {
  name: "externalConnect",
  components: {
    SideVar,
  },
  data() {
    return {
      message: "登録中...",
    };
  },
  mounted() {
    const route = useRoute();
    console.log(route.query);
    if (route.query.code != null && this.guild_id == null) {
      this.postDiscordInfo(route.query.guild_id);
    }
  },
  computed: {},
  watch: {},
  methods: {
    postDiscordInfo(guild_id) {
      api
        .postItemsDiscord({ item_type: "DISCORD", item_data_1: guild_id })
        .then((response) => {
          console.log(response);
          this.message = "Discordサーバーとの連携が成功しました。";
          this.$toast.show("Discordの連携に成功しました", {
            type: "success",
            position: "top",
            duration: 3000,
          });
        })
        .catch((error) => {
          console.log("error", error);
          this.$toast.show("Discordの連携に失敗しました", {
            type: "error",
            position: "top",
            duration: 5000,
          });
        });
    },
  },
};
</script>
