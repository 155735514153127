import firebase from "firebase/compat/app";
import { getAuth } from "@firebase/auth";
import store from "@/store";

// const config = {
//   apiKey: "AIzaSyCZyV2mq2f0slEe7L2cWQVkF7rZfNUikr8",
//   authDomain: "fuelcrypto-client.firebaseapp.com",
//   projectId: "fuelcrypto-client",
//   storageBucket: "fuelcrypto-client.appspot.com",
//   messagingSenderId: "431295275079",
//   appId: "1:431295275079:web:d494ad063f797e6c279162",
// };

// new sociama
const config = {
  apiKey: "AIzaSyBRBc9h6AOwtBqJlyYKolhPvD3HgMzPHqk",
  authDomain: "sociama-client.firebaseapp.com",
  projectId: "sociama-client",
  storageBucket: "sociama-client.appspot.com",
  messagingSenderId: "690362204245",
  appId: "1:690362204245:web:90ea919e84be53171694dc",
};

firebase.initializeApp(config);
const auth = getAuth();

auth.onAuthStateChanged((user) => {
  store.commit("updateUser", user);
});

export function initFirebaseAuth() {
  return new Promise((resolve) => {
    var unsubscribe = auth.onAuthStateChanged((user) => {
      // user オブジェクトを resolve
      resolve(user);
      // 登録解除
      unsubscribe();
    });
  });
}
