<template>
  <div class="campaign_analize">
    <!-- side var  -->
    <SideVar />
    <!-- main div -->
    <RightColumnOutline>
      <PageTitle>Discord Channels</PageTitle>
      <div class="flex justify-between items-center">
        <div class="text-white text-left ml-4 text-sm">
          チャンネルごとの盛り上がりを確認しましょう
        </div>
        <div class="text-xs text-white flex items-center w-auto">
          <div class="mr-4">表示期間</div>
          <div v-if="show_flag" class="w-64">
            <Datepicker
              v-model="date"
              range
              :format="yyyy / MM / dd"
              :enable-time-picker="false"
            />
          </div>
        </div>
      </div>
      <div class="m-4 p-2">
        <EasyDataTable
          :headers="headers"
          :items="items"
          :sort-by="sortBy"
          :sort-type="sortType"
        />
      </div>
    </RightColumnOutline>
  </div>
</template>

<script>
import api from "../api/api.js";
import Datepicker from "@vuepic/vue-datepicker";
import { ref, onMounted } from "vue";

export default {
  name: "Campaign_regit",
  components: {
    Datepicker,
  },
  setup() {
    let date = ref();
    const sortBy = "count_actions";
    const sortType = "desc";

    const headers = [
      { text: "チャンネル名", value: "channel_name" },
      {
        text: "アクション数",
        value: "count_actions",
        sortable: true,
        width: 150,
      },
      {
        text: "メッセージ数",
        value: "count_messages",
        sortable: true,
        width: 150,
      },
      {
        text: "リアクション数",
        value: "count_reactions",
        sortable: true,
        width: 150,
      },
      // { text: "アクション人数", value: "count_action_users", sortable: true },
      {
        text: "メッセージ人数",
        value: "count_message_users",
        sortable: true,
        width: 150,
      },
      {
        text: "リアクション人数",
        value: "count_reaction_users",
        sortable: true,
        width: 150,
      },
    ];

    let items = ref([]);

    onMounted(() => {
      // 日付の最小〜最大を計算する処理
      const today = new Date();
      let toDate = new Date(new Date().setDate(today.getDate() + 1));
      let fromDate = new Date(new Date().setDate(toDate.getDate() - 30));
      date.value = [fromDate, toDate];
    });
    return {
      date,
      headers,
      items,
      sortBy,
      sortType,
    };
  },
  mounted() {
    this.show_flag = true;
    // this.getDiscordChannels();
  },
  watch: {
    date: {
      handler() {
        this.items = [];
        console.log(
          "change date",
          this.date[0].toISOString().slice(0, 10),
          this.date[1].toISOString().slice(0, 10)
        );
        this.getDiscordChannels();
      },
      deep: true,
    },
  },

  data() {
    return {
      show_flag: false,
      today: new Date(),
    };
  },
  computed: {},
  methods: {
    getDiscordChannels() {
      api
        .getDiscordChannels({
          data_from: this.date[0].toISOString().slice(0, 10),
          data_to: this.date[1].toISOString().slice(0, 10),
        })
        .then((response) => {
          console.log(response);
          for (let i = 0; i < response["data"].length; i++) {
            this.items.push({
              channel_name: response["data"][i]["channel_name"],
              count_actions:
                response["data"][i]["count_messages"] +
                response["data"][i]["count_reactions"],
              count_messages: response["data"][i]["count_messages"],
              count_reactions: response["data"][i]["count_reactions"],
              count_message_users: response["data"][i]["count_message_users"],
              count_reaction_users: response["data"][i]["count_reaction_users"],
            });
          }
        })
        .catch(() => {
          this.$toast.show("分析データの取得に失敗しました", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
        });
    },
  },
};
</script>
