<template>
  <div class="campaignModifyConditions">
    <!-- side var  -->
    <SideVar />
    <!-- input panel -->
    <RightColumnOutline>
      <div
        class="items-center -mx-4 mb-8 pb-6 border-b border-gray-400 border-opacity-3"
      >
        <div class="w-auto px-4 mb-0">
          <PageTitle>Campaign Setting </PageTitle>
          <div
            v-if="campaign_category == 'FOLLOW_AND_RETWEET'"
            class="flex items-center justify-left"
          >
            <div class="w-auto px-4 mb-0">
              <span class="text-sm font-medium text-gray-100"
                >キャンペーンURL
                <i
                  class="pi pi-question-circle mr-1"
                  v-tippy="{
                    content:
                      '利用者がキャンペーン参加条件を確認するページのURLです。キャンペーンを告知する際にご利用ください',
                  }"
                ></i>
              </span>
            </div>
            <div class="text-white text-sm w-auto">
              https://app.social-marketing.io/action-raffle/{{ campaign_id }}
            </div>
          </div>
          <div class="w-auto px-4 mb-0" v-if="campaign_status != 1">
            <div class="text-sm text-red-300 text-left my-2">
              キャンペーン確定後はRepost対象の変更のみ実行可能です。キャンペーン参加者へのアナウンスの内容と齟齬が出ないよう更新する際は十分にご注意ください。
            </div>
          </div>
        </div>
        <div class="w-auto px-4"></div>
      </div>
      <!-- general -->
      <div v-if="campaign_category == 'INSTANT_WIN'">
        <div class="pb-8 mb-8 border-b border-gray-400 border-opacity-3">
          <h4
            class="text-xl font-bold tracking-wide text-white mb-5 text-left flex items-center"
          >
            <i class="w-8 ml-6 pi pi-gift"></i>
            抽選設定
          </h4>
          <div
            class="flex flex-wrap items-center -mx-4 mb-4 border-gray-400 border-opacity-20"
          >
            <div class="w-1/3 pl-18 text-left">
              <span class="text-sm font-medium text-gray-100"
                >毎日抽選<i
                  class="pi pi-question-circle mx-1"
                  v-tippy="{
                    content:
                      'ONにすると未当選のアカウントは毎日00:00(JST)に抽選可能数がリセットされて、毎日抽選できるようになります。',
                  }"
                ></i>
              </span>
            </div>
            <div class="w-1/3 px-4 text-left text-gray-100 text-sm">
              現在の設定：{{ raffleEveryday_display }}
            </div>
            <div class="w-1/4 px-4">
              <div class="max-w-xl" v-if="!raffleEveryday">
                <DoButton
                  :clickFunction="onRaffleEverydayPromise"
                  :values="{}"
                  :disabled="raffleEveryday || campaign_status != 1"
                  >ONにする</DoButton
                >
              </div>
              <div class="max-w-xl" v-else>
                <BackButton
                  :clickFunction="offRaffleEverydayPromise"
                  :values="{}"
                  :disabled="!raffleEveryday || campaign_status != 1"
                  >OFFにする</BackButton
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Twitter -->
      <div>
        <!-- FOLLOW -->
        <div>
          <h4
            class="text-xl font-bold tracking-wide text-white mb-5 text-left flex items-center"
          >
            <img
              class="w-8 mx-4"
              src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/iconfinder-social-media-applications-6twitter-4102580_113802.png"
              alt="twitter_icon"
            />
            FOLLOW
          </h4>
          <div
            class="flex flex-wrap items-center -mx-4 mb-4 border-gray-400 border-opacity-20"
          >
            <div class="w-1/3 pl-18 text-left">
              <span class="text-sm font-medium text-gray-100"
                >Twitterアカウント *
              </span>
            </div>
            <div class="w-full sm:w-1/3 px-4">
              <div class="max-w-xl">
                <input
                  class="block py-4 px-3 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
                  id="formInput1-3"
                  type="text"
                  v-model="twitter_account"
                  placeholder="@username"
                />
              </div>
            </div>
            <div class="w-1/4 px-4">
              <div class="max-w-xl">
                <DoButton
                  :clickFunction="checkTwitterAccountPromise"
                  :values="{}"
                  :disabled="
                    !twitter_account ||
                    twitter_account_check ||
                    campaign_status != 1
                  "
                  >アカウントチェック</DoButton
                >
              </div>
            </div>
          </div>
          <div class="flex flex-wrap items-center -mx-4 mb-8">
            <div class="w-1/3 px-4 pb-4"></div>
            <div class="w-2/3 px-4">
              <div
                v-if="twitter_account_result"
                class="max-w-xl text-gray-100 text-left"
              >
                {{ twitter_account_result }}
              </div>
            </div>
          </div>
          <div class="mb-8">
            <DoButton
              :clickFunction="createFollowConditionPromise"
              :values="{}"
              :disabled="!twitter_account_check || campaign_status != 1"
              >登録</DoButton
            >
          </div>
          <div
            class="flex flex-wrap items-center -mx-4 pb-8 mb-8 border-b border-gray-400 border-opacity-3"
          >
            <div class="w-1/3 pl-18 text-left">
              <span class="text-sm font-medium text-gray-100">
                登録済条件
              </span>
            </div>
            <div class="w-2/3 px-4">
              <table class="w-full min-w-max">
                <thead>
                  <tr class="text-left">
                    <th class="p-0">
                      <div class="py-3 px-6 rounded-l-xl bg-gray-600">
                        <span class="text-xs text-gray-300 font-semibold"
                          >username</span
                        >
                      </div>
                    </th>
                    <th class="p-0">
                      <div class="py-3 px-6 bg-gray-600">
                        <span class="text-xs text-gray-300 font-semibold"
                          >user_id</span
                        >
                      </div>
                    </th>
                    <th class="p-0">
                      <div class="py-3 px-6 bg-gray-600">
                        <span class="text-xs text-gray-300 font-semibold"
                          >followers</span
                        >
                      </div>
                    </th>
                    <th class="p-0">
                      <div class="py-6 px-6 bg-gray-600 rounded-r-xl">
                        <span class="text-xs text-gray-300 font-semibold">
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="twitter_account in conditions_twitter_account"
                    v-bind:key="twitter_account"
                  >
                    <td class="p-0">
                      <div class="flex items-center h-16 px-6">
                        <a
                          class="text-sm text-blue-400 font-medium underline"
                          :href="
                            'https://twitter.com/' + twitter_account.username
                          "
                          target="_blank"
                          rel="noopener noreferrer"
                          >{{ twitter_account.username }}</a
                        >
                      </div>
                    </td>
                    <td class="p-0">
                      <div class="flex items-center h-16 px-6">
                        <div class="flex h-full items-center">
                          <span class="text-sm font-medium text-gray-100">{{
                            twitter_account.user_id
                          }}</span>
                        </div>
                      </div>
                    </td>
                    <td class="p-0">
                      <div class="flex items-center h-16 px-6">
                        <span class="text-sm text-gray-100 font-medium">{{
                          twitter_account.followers
                        }}</span>
                      </div>
                    </td>
                    <td class="p-0">
                      <BackButton
                        :clickFunction="deleteFollowConditionPromise"
                        :disabled="campaign_status != 1"
                        :values="twitter_account.user_id"
                        ><i class="pi pi-trash"></i
                      ></BackButton>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- RETWEET -->
        <div>
          <h4
            class="text-xl tracking-wide text-white mb-5 text-left flex items-center"
          >
            <img
              class="w-8 mx-4"
              src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/iconfinder-social-media-applications-6twitter-4102580_113802.png"
              alt="twitter_icon"
            />
            <div class="font-bold">REPOST</div>
            <div class="text-xs ml-4">
              キャンペーンの対象はリポストのみです(引用リポストは非対象)
            </div>
          </h4>
          <div
            class="flex flex-wrap items-center -mx-4 mb-4 border-gray-400 border-opacity-20"
          >
            <div class="w-1/3 pl-18 text-left">
              <span class="text-sm font-medium text-gray-100"> Post URL </span>
            </div>
            <div class="w-1/3 px-4">
              <div class="max-w-xl">
                <input
                  class="block py-4 px-3 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
                  type="text"
                  v-model="tweet_url"
                  placeholder="https://"
                />
              </div>
            </div>
            <div class="w-1/4 px-4">
              <div class="max-w-xl">
                <DoButton
                  :clickFunction="checkTweetPromise"
                  :values="{}"
                  :disabled="!tweet_url || tweet_url_check"
                >
                  ツイートチェック</DoButton
                >
              </div>
            </div>
          </div>
          <div class="flex flex-wrap items-center -mx-4 pb-8">
            <div class="w-full sm:w-1/3 px-4 pb-4 sm:mb-0">
              <span class="text-sm font-medium text-gray-100"></span>
            </div>
            <div class="w-full sm:w-2/3 px-4">
              <div
                v-if="tweet_url_result"
                class="max-w-xl text-gray-100 text-left"
              >
                {{ tweet_url_result }}
              </div>
            </div>
          </div>
          <div class="mb-8">
            <DoButton
              :clickFunction="createRetweetConditionPromise"
              :values="{}"
              :disabled="!tweet_url_check"
              >登録
            </DoButton>
          </div>
          <div
            class="flex flex-wrap items-center -mx-4 pb-8 mb-8 border-b border-gray-400 border-opacity-3"
          >
            <div class="w-1/3 pl-18 text-left">
              <span class="text-sm font-medium text-gray-100">登録済条件 </span>
            </div>
            <div class="w-2/3 px-4">
              <table class="w-full min-w-max">
                <thead>
                  <tr class="text-left">
                    <th class="p-0">
                      <div class="py-3 px-6 rounded-l-xl bg-gray-600">
                        <span class="text-xs text-gray-300 font-semibold"
                          >post_id</span
                        >
                      </div>
                    </th>
                    <th class="p-0">
                      <div class="py-3 px-6 bg-gray-600">
                        <span class="text-xs text-gray-300 font-semibold"
                          >user_name</span
                        >
                      </div>
                    </th>
                    <th class="p-0">
                      <div class="py-3 px-6 bg-gray-600">
                        <span class="text-xs text-gray-300 font-semibold"
                          >text</span
                        >
                      </div>
                    </th>
                    <th class="p-0">
                      <div class="py-6 px-6 bg-gray-600 rounded-r-xl">
                        <span class="text-xs text-gray-300 font-semibold">
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="tweet in conditions_tweet" v-bind:key="tweet">
                    <td class="p-0">
                      <div class="flex items-center h-16 px-6">
                        <a
                          class="text-sm text-blue-400 font-medium underline"
                          :href="
                            'https://twitter.com/' +
                            tweet.username +
                            '/status/' +
                            tweet.tweet_id
                          "
                          target="_blank"
                          rel="noopener noreferrer"
                          >{{ tweet.tweet_id }}</a
                        >
                      </div>
                    </td>
                    <td class="p-0">
                      <div class="flex items-center h-16 px-6">
                        <div class="flex h-full items-center">
                          <span class="text-sm font-medium text-gray-100">{{
                            tweet.username
                          }}</span>
                        </div>
                      </div>
                    </td>
                    <td class="p-0">
                      <div class="flex items-center h-16 px-6">
                        <span class="text-sm text-gray-100 font-medium">{{
                          tweet.text.length < 10
                            ? tweet.text
                            : tweet.text.slice(0, 10) + "..."
                        }}</span>
                      </div>
                    </td>
                    <td class="p-0">
                      <BackButton
                        :clickFunction="deleteRetweetConditionPromise"
                        :values="tweet.tweet_id"
                        ><i class="pi pi-trash"></i
                      ></BackButton>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- Instagram -->
      <div v-if="campaign_category == 'INSTANT_WIN'">
        <!-- FOLLOW -->
        <div
          class="pb-8 mb-8 border-b border-gray-400 border-opacity-3"
          v-if="plan == 'GOLD'"
        >
          <h4
            class="text-xl font-bold tracking-wide text-white mb-5 text-left flex items-center"
          >
            <img
              class="w-8 mx-4"
              src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/Instagram_Glyph_Gradient.png"
              alt="instagram_icon"
            />
            FOLLOW
          </h4>
          <div
            class="flex flex-wrap items-center -mx-4 mb-4 border-gray-400 border-opacity-20"
          >
            <div class="w-1/3 pl-18 text-left">
              <span class="text-sm font-medium text-gray-100"
                >InstagramアカウントURL<i
                  class="pi pi-question-circle mx-1"
                  v-tippy="{
                    content:
                      '条件に登録したいInstagramアカウントのURLを入力してください',
                  }"
                ></i>
              </span>
            </div>
            <div class="w-1/3">
              <div class="max-w-xl text-white text-left">
                <div class="flex items-center justify-start">
                  <div class="">
                    <input
                      class="block py-4 px-3 mx-4 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
                      type="text"
                      v-model="instagram_account_url"
                      placeholder="https://www.instagram.com/"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="w-1/4 px-4">
              <div class="max-w-xl" v-if="!instagramFollowConditionExists">
                <DoButton
                  :clickFunction="createInstagramFollowConditionPromise"
                  :values="instagram_account_url"
                  :disabled="
                    instagramFollowConditionExists ||
                    !instagram_account_url ||
                    campaign_status != 1
                  "
                  >キャンペーン条件に追加</DoButton
                >
              </div>
              <div class="max-w-xl" v-else>
                <BackButton
                  :clickFunction="deleteInstagramFollowConditionPromise"
                  :values="{}"
                  :disabled="
                    !instagramFollowConditionExists || campaign_status != 1
                  "
                  >キャンペーン条件から削除</BackButton
                >
              </div>
            </div>
          </div>
        </div>
        <!-- Like -->
        <div
          class="pb-8 mb-8 border-b border-gray-400 border-opacity-3"
          v-if="plan == 'GOLD'"
        >
          <h4
            class="text-xl font-bold tracking-wide text-white mb-5 text-left flex items-center"
          >
            <img
              class="w-8 mx-4"
              src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/Instagram_Glyph_Gradient.png"
              alt="instagram_icon"
            />
            LIKE
          </h4>
          <div
            class="flex flex-wrap items-center -mx-4 mb-4 border-gray-400 border-opacity-20"
          >
            <div class="w-1/3 pl-18 text-left">
              <span class="text-sm font-medium text-gray-100"
                >Instagram投稿URL<i
                  class="pi pi-question-circle mx-1"
                  v-tippy="{
                    content:
                      '条件に登録したいInstagram投稿のURLを入力してください',
                  }"
                ></i>
              </span>
            </div>
            <div class="w-1/3">
              <div class="max-w-xl text-white text-left">
                <div class="flex items-center justify-start">
                  <div class="">
                    <input
                      class="block py-4 px-3 mx-4 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
                      type="text"
                      v-model="instagram_feed_url"
                      placeholder="https://www.instagram.com/"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="w-1/4 px-4">
              <div class="max-w-xl" v-if="!instagramLikeConditionExists">
                <DoButton
                  :clickFunction="createInstagramLikeConditionPromise"
                  :values="instagram_feed_url"
                  :disabled="
                    instagramLikeConditionExists ||
                    !instagram_feed_url ||
                    campaign_status != 1
                  "
                  >キャンペーン条件に追加</DoButton
                >
              </div>
              <div class="max-w-xl" v-else>
                <BackButton
                  :clickFunction="deleteInstagramLikeConditionPromise"
                  :values="{}"
                  :disabled="
                    !instagramLikeConditionExists || campaign_status != 1
                  "
                  >キャンペーン条件から削除</BackButton
                >
              </div>
            </div>
          </div>
        </div>
        <!-- Hashtag_post -->
        <div
          class="pb-8 mb-8 border-b border-gray-400 border-opacity-3"
          v-if="plan == 'GOLD'"
        >
          <h4
            class="text-xl font-bold tracking-wide text-white mb-5 text-left flex items-center"
          >
            <img
              class="w-8 mx-4"
              src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/Instagram_Glyph_Gradient.png"
              alt="instagram_icon"
            />
            HASHTAG投稿
          </h4>
          <div
            class="flex flex-wrap items-center -mx-4 mb-4 border-gray-400 border-opacity-20"
          >
            <div class="w-1/3 pl-18 text-left">
              <span class="text-sm font-medium text-gray-100"
                >キーワード<i
                  class="pi pi-question-circle mx-1"
                  v-tippy="{
                    content: '条件に登録したいキーワードを入力してください',
                  }"
                ></i>
              </span>
            </div>
            <div class="w-1/3">
              <div class="max-w-xl text-white text-left">
                <div class="flex items-center justify-start">
                  <div class="">
                    <input
                      class="block py-4 px-3 mx-4 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
                      type="text"
                      v-model="instagram_keyword"
                      placeholder="#instagram"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="w-1/4 px-4">
              <div class="max-w-xl" v-if="!instagramHashtagConditionExists">
                <DoButton
                  :clickFunction="createInstagramHashtagConditionPromise"
                  :values="instagram_keyword"
                  :disabled="
                    instagramHashtagConditionExists ||
                    !instagram_keyword ||
                    campaign_status != 1
                  "
                  >キャンペーン条件に追加</DoButton
                >
              </div>
              <div class="max-w-xl" v-else>
                <BackButton
                  :clickFunction="deleteInstagramHashtagConditionPromise"
                  :values="{}"
                  :disabled="
                    !instagramHashtagConditionExists || campaign_status != 1
                  "
                  >キャンペーン条件から削除</BackButton
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- DISCORD -->
      <div
        class="pb-8 mb-8 border-b border-gray-400 border-opacity-3"
        v-if="plan == 'GOLD'"
      >
        <h4
          class="text-xl font-bold tracking-wide text-white mb-5 text-left flex items-center"
        >
          <img
            class="w-8 mx-4"
            src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/discord_icon_130958.png"
            alt="discord_icon"
          />
          DISCORD
        </h4>
        <div
          class="flex flex-wrap items-center -mx-4 mb-4 border-gray-400 border-opacity-20"
        >
          <div class="w-1/3 pl-18 text-left">
            <span class="text-sm font-medium text-gray-100"
              >サーバーへの参加<i
                class="pi pi-question-circle mx-1"
                v-tippy="{
                  content:
                    'Discordサーバーへの参加を条件にする場合、事前に外部サービス連携画面からDiscordサーバーとの連携が必要です',
                }"
              ></i>
            </span>
          </div>
        </div>
        <div
          class="flex flex-wrap items-center -mx-4 mb-4 border-gray-400 border-opacity-20"
        >
          <div class="w-1/3 pl-18 text-left">
            <span class="text-sm font-medium text-gray-100"
              >参加先サーバー
            </span>
          </div>
          <div class="w-1/3">
            <div class="max-w-xl text-white text-left">
              <div v-if="discordGuild" class="flex items-center justify-start">
                <img
                  :src="
                    'https://cdn.discordapp.com/icons/' +
                    discordGuild.item_data_1 +
                    '/' +
                    discordGuild.item_data_3
                  "
                  alt="discord_icon"
                  class="w-8 mx-4"
                />
                {{ discordGuild.item_data_2 }}
              </div>
              <div v-else>Discordサーバーが未連携です</div>
            </div>
          </div>
        </div>
        <div
          class="flex flex-wrap items-center -mx-4 mb-4 border-gray-400 border-opacity-20"
        >
          <div class="w-1/3 pl-18 text-left">
            <span class="text-sm font-medium text-gray-100">招待URL </span>
          </div>
          <div class="w-1/3">
            <div class="max-w-xl text-white text-left">
              <div v-if="discordGuild" class="flex items-center justify-start">
                <div class="">
                  <input
                    class="block py-4 px-3 mx-4 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
                    type="text"
                    v-model="discordInviteUrl"
                    placeholder="https://"
                  />
                </div>
              </div>
              <div v-else>Discordサーバーが未連携です</div>
            </div>
          </div>
          <div class="w-1/4 px-4">
            <div class="max-w-xl" v-if="!discordConditionExists">
              <DoButton
                :clickFunction="createDiscordConditionPromise"
                :values="{}"
                :disabled="
                  !discordConnect ||
                  discordConditionExists ||
                  !discordInviteUrl ||
                  campaign_status != 1
                "
                >キャンペーン条件に追加</DoButton
              >
            </div>
            <div class="max-w-xl" v-else>
              <BackButton
                :clickFunction="deleteDiscordConditionPromise"
                :values="{}"
                :disabled="!discordConditionExists || campaign_status != 1"
                >キャンペーン条件から削除</BackButton
              >
            </div>
          </div>
        </div>
      </div>
      <!-- WALLET -->
      <div
        class="pb-8 mb-8 border-b border-gray-400 border-opacity-3"
        v-if="plan == 'GOLD'"
      >
        <h4
          class="text-xl font-bold tracking-wide text-white mb-5 text-left flex items-center"
        >
          <img
            class="w-8 mx-4"
            src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/MetaMask_Fox.svg.png"
            alt="metamask_icon"
          />
          WALLET
        </h4>
        <div
          class="flex flex-wrap items-center -mx-4 mb-4 border-gray-400 border-opacity-20"
        >
          <div class="w-1/3 pl-18 text-left">
            <span class="text-sm font-medium text-gray-100"
              >ウォレット連携（アドレス登録）<i
                class="pi pi-question-circle mx-1"
                v-tippy="{
                  content:
                    'キャンペーン参加者がMetamaskウォレットを連携してERC20アドレスを登録することを条件とする項目です',
                }"
              ></i>
            </span>
          </div>
          <div class="w-1/3 px-4"></div>
          <div class="w-1/4 px-4">
            <div class="max-w-xl" v-if="!walletConditionExists">
              <DoButton
                :clickFunction="createWalletConditionPromise"
                :values="{}"
                :disabled="walletConditionExists || campaign_status != 1"
                >キャンペーン条件に追加</DoButton
              >
            </div>
            <div class="max-w-xl" v-else>
              <BackButton
                :clickFunction="deleteWalletConditionPromise"
                :values="{}"
                :disabled="!walletConditionExists || campaign_status != 1"
                >キャンペーン条件から削除</BackButton
              >
            </div>
          </div>
        </div>
      </div>
      <!-- 外部サイトリンク -->
      <div
        class="pb-8 mb-8 border-b border-gray-400 border-opacity-3"
        v-if="plan == 'GOLD'"
      >
        <h4
          class="text-xl font-bold tracking-wide text-white mb-5 text-left flex items-center"
        >
          <i class="w-8 ml-6 pi pi-sign-out"></i>
          外部サイト
        </h4>
        <div
          class="flex flex-wrap items-center -mx-4 mb-4 border-gray-400 border-opacity-20"
        >
          <div class="w-1/3 pl-18 text-left">
            <span class="text-sm font-medium text-gray-100"
              >外部サイトURL<i
                class="pi pi-question-circle mx-1"
                v-tippy="{
                  content:
                    '登録された外部リンクに遷移することを条件として設定することができます。',
                }"
              ></i>
            </span>
          </div>
          <div class="w-1/3">
            <div class="max-w-xl text-white text-left">
              <div class="flex items-center justify-start">
                <div class="">
                  <input
                    class="block py-4 px-3 mx-4 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
                    type="text"
                    v-model="externalSiteUrl"
                    placeholder="https://"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="w-1/4 px-4">
            <div class="max-w-xl" v-if="!externalSiteConditionExists">
              <DoButton
                :clickFunction="createExternalSiteConditionPromise"
                :values="externalSiteUrl"
                :disabled="
                  externalSiteConditionExists ||
                  !externalSiteUrl ||
                  campaign_status != 1
                "
                >キャンペーン条件に追加</DoButton
              >
            </div>
            <div class="max-w-xl" v-else>
              <BackButton
                :clickFunction="deleteExternalSiteConditionPromise"
                :values="{}"
                :disabled="!externalSiteConditionExists || campaign_status != 1"
                >キャンペーン条件から削除</BackButton
              >
            </div>
          </div>
        </div>
      </div>
      <!-- アプリDL -->
      <div
        class="pb-8 mb-8 border-b border-gray-400 border-opacity-3"
        v-if="isFuelhash"
      >
        <h4
          class="text-xl font-bold tracking-wide text-white mb-5 text-left flex items-center"
        >
          <i class="w-8 ml-6 pi pi-mobile"></i>
          アプリDL
        </h4>
        <div
          class="flex flex-wrap items-center -mx-4 mb-4 border-gray-400 border-opacity-20"
        >
          <div class="w-1/3 pl-18 text-left">
            <span class="text-sm font-medium text-gray-100"
              >ディープリンク</span
            >
          </div>
          <div class="w-1/3">
            <div class="max-w-xl text-white text-left">
              <div class="flex items-center justify-start">
                <div class="">
                  <input
                    class="block py-4 px-3 mx-4 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
                    type="text"
                    v-model="appDownloadUrl"
                    placeholder="https://"
                    @input="validateAppDownloadUrl"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="w-1/4 px-4">
            <div class="max-w-xl" v-if="!appDownloadConditionExists">
              <DoButton
                :clickFunction="createAppDownloadConditionPromise"
                :values="appDownloadUrl"
                :disabled="
                  appDownloadConditionExists ||
                  !isValidAppDownloadUrl ||
                  campaign_status != 1
                "
                >キャンペーン条件に追加</DoButton
              >
            </div>
            <div class="max-w-xl" v-else>
              <BackButton
                :clickFunction="deleteAppDownloadConditionPromise"
                :values="{}"
                :disabled="!appDownloadConditionExists || campaign_status != 1"
                >キャンペーン条件から削除</BackButton
              >
            </div>
          </div>
        </div>
        <div
          v-if="!isValidAppDownloadUrl && appDownloadUrl"
          class="text-red-500 text-sm mt-2"
        >
          フォーマットエラー: 正しいディープリンク形式を入力してください。
        </div>
      </div>
      <!-- REWORD -->
      <div>
        <div class="flex justify-between">
          <h4 class="text-xl font-bold tracking-wide text-white mb-8 text-left">
            REWARD
          </h4>
          <div>
            <input
              class="text-white mr-4"
              type="file"
              accept="text/csv"
              @change="handleFileSelect"
            />
            <div v-if="csvError" class="text-white text-left mt-2">
              解決しない場合は
              <a
                class="text-sm text-blue-400 font-medium underline"
                href="https://www.notion.so/d6913178b00640c2b5e9925d5682544d?pvs=4#fd71d5a063fc45b78509fa7ac0bf7e5e"
                target="_blank"
                rel="noopener noreferrer"
                >QA</a
              >
              をご覧ください
            </div>
          </div>
        </div>
        <div class="flex flex-wrap justify-center items-center -mx-4">
          <div class="text-white">No</div>
          <div class="w-full sm:w-1/3 px-4">
            <div class="max-w-xl">
              <a
                class="block py-4 px-3 w-full text-sm text-gray-50 font-medium outline-none"
                >ラベル</a
              >
            </div>
          </div>
          <div class="w-full sm:w-1/2 px-4">
            <div class="max-w-xl">
              <a
                class="block py-4 px-3 w-full text-sm text-gray-50 font-medium outline-none"
                >ギフトコード</a
              >
            </div>
          </div>
        </div>
        <div
          class="flex flex-wrap justify-center items-center -mx-4 mb-4 border-gray-400 border-opacity-20"
          v-for="(reword, index) in reword_list"
          v-bind:key="reword.code"
        >
          <div class="text-white">{{ index + 1 }}</div>
          <div class="w-full sm:w-1/3 px-4">
            <div class="max-w-xl">
              <input
                class="block py-4 px-3 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
                id="formInput1-3"
                type="text"
                v-model="reword[0]"
                placeholder="label"
              />
            </div>
          </div>
          <div class="w-full sm:w-1/2 px-4">
            <div class="max-w-xl">
              <input
                class="block py-4 px-3 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
                id="formInput1-3"
                type="text"
                v-model="reword[1]"
                placeholder="code"
              />
            </div>
          </div>
        </div>
        <div class="mb-8">
          <div v-if="!reword_update">
            <DoButton
              :clickFunction="createGiftsPromise"
              :values="{}"
              :disabled="!gift_filled || campaign_status != 1"
            >
              登録</DoButton
            >
          </div>
          <div v-else>
            <DoButton
              :clickFunction="updateGiftsPromise"
              :values="{}"
              :disabled="!gift_filled || campaign_status != 1"
            >
              変更</DoButton
            >
          </div>
        </div>
      </div>
    </RightColumnOutline>
  </div>
</template>

<script>
import SideVar from "@/components/SideVar.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import api from "../api/api.js";
import { ref } from "vue";
import { useRoute } from "vue-router";
import checks from "../assets/mixins/Checks";

export default {
  name: "Campaign_regit",
  mixins: [checks],
  components: {
    SideVar,
  },
  setup() {
    let date = ref();
    return {
      date,
    };
  },
  data() {
    return {
      campaign_id: null,
      campaign_status: 1,
      conditions_twitter_account: [],
      conditions_tweet: [],
      reword_amount: null,
      reword_list: [],
      gift_filled: false,
      reword_update: false,
      campaign_category: null,

      discordGuild: null,
      discordInviteUrl: "",
      discordConnect: false,
      discordConditionExists: false,

      walletConditionExists: false,

      externalSiteUrl: "",
      externalSiteConditionExists: false,

      instagram_account_url: "",
      instagraFollowConditionExists: false,

      instagram_feed_url: "",
      instagraLikeConditionExists: false,

      instagram_keyword: "",
      instagraHashtagConditionExists: false,

      raffleEveryday: false,

      csvError: false,

      // アプリDL用
      appDownloadUrl: "",
      appDownloadConditionExists: false,
      isValidAppDownloadUrl: false,
      deepLinkValue: "",
      organizer_name: "",
      isFuelhash: false,
    };
  },
  mounted() {
    const route = useRoute();
    this.campaign_id = route.params.id;
    this.checkOrganizer();
    this.getConditions();
    this.getCampaign();
    this.getDiscordInfo();
  },
  computed: {
    plan: function () {
      return this.$store.getters["subscribePlan"];
    },
    raffleEveryday_display: function () {
      if (this.raffleEveryday == 1) {
        return "ON";
      } else {
        return "OFF";
      }
    },
  },
  watch: {
    // twitterアカウントのテキストボックス
    twitter_account(value) {
      this.twitter_account_check = false;
      this.twitter_account_result = "";
      if (value.indexOf("@")) {
        this.twitter_account = "@" + value;
      }
    },
    // tweet URLのテキストボックス
    tweet_url() {
      this.tweet_url_check = false;
      this.tweet_url_result = "";
      console.log(this.tweet_url);
      // x.comはAPIとしては無効なURLなので、twitter.comに置換する
      if (this.tweet_url.includes("https://x.com/") == true) {
        this.tweet_url = this.tweet_url.replace(
          "https://x.com/",
          "https://twitter.com/"
        );
      }
      // 謎のクエリパラメータが混入する可能性があるが、不要なので削除する
      if (this.tweet_url.includes("?") == true) {
        this.tweet_url = this.tweet_url.split("?")[0];
      }
    },
    instagram_keyword(value) {
      if (value.indexOf("#")) {
        this.instagram_keyword = "#" + value;
      }
    },
    reword_list: {
      handler() {
        let _ = true;
        for (var i = 0; i < this.reword_list.length; i++) {
          if (this.reword_list[i][0].length == 0) {
            this.gift_filled = false;
            _ = false;
            break;
          }
          if (this.reword_list[i][1].length == 0) {
            this.gift_filled = false;
            _ = false;
            break;
          }
        }
        if (_ == true) {
          this.gift_filled = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    getConditions: function () {
      this.conditions_tweet = [];
      this.conditions_twitter_account = [];
      this.discordConditionExists = false;
      this.walletConditionExists = false;
      this.instagramFollowConditionExists = false;
      this.instagram_account_url = "";
      this.instagramLikeConditionExists = false;
      this.instagram_feed_url = "";
      this.externalSiteConditionExists = false;
      this.externalSiteUrl = "";
      this.instagramHashtagConditionExists = false;
      this.instagram_keyword = "";

      api
        .getConditions({ campaign_id: this.$route.params.id })
        .then((response) => {
          console.log(response);
          let response_data = response["data"];
          for (var i in response_data) {
            if (response_data[i]["actions"] == "FOLLOW") {
              this.conditions_twitter_account.push({
                user_id: response_data[i]["user_id"],
                username: response_data[i]["username"],
                followers: response_data[i]["followers"],
              });
            } else if (response_data[i]["actions"] == "RETWEET") {
              this.conditions_tweet.push({
                tweet_id: response_data[i]["tweet_id"],
                username: response_data[i]["username"],
                text: response_data[i]["text"],
              });
            } else if (response_data[i]["actions"] == "DISCORD_SERVER_JOIN") {
              this.discordConditionExists = true;
              this.discordInviteUrl = response_data[i]["invite_url"];
            } else if (response_data[i]["actions"] == "WALLET_CONNECT") {
              this.walletConditionExists = true;
            } else if (response_data[i]["actions"] == "EXTERNAL_SITE_VISIT") {
              this.externalSiteConditionExists = true;
              this.externalSiteUrl = response_data[i]["external_site_url"];
            } else if (response_data[i]["actions"] == "INSTAGRAM_FOLLOW") {
              this.instagramFollowConditionExists = true;
              this.instagram_account_url =
                response_data[i]["external_site_url"];
            } else if (response_data[i]["actions"] == "INSTAGRAM_LIKE") {
              this.instagramLikeConditionExists = true;
              this.instagram_feed_url = response_data[i]["external_site_url"];
            } else if (response_data[i]["actions"] == "INSTAGRAM_HASHTAG") {
              this.instagramHashtagConditionExists = true;
              this.instagram_keyword = response_data[i]["keyword"];
            } else if (response_data[i]["actions"] == "INSTALL") {
              this.appDownloadConditionExists = true;
              this.appDownloadUrl = response_data[i]["invite_url"];
              this.deepLinkValue = response_data[i]["keyword"];
              this.validateAppDownloadUrl();
            }
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          this.$toast.show(
            "条件の取得に失敗しました。時間を置いて再度お試しください。",
            {
              type: "error",
              position: "top-right",
              duration: 5000,
            }
          );
        });
    },
    createFollowConditionPromise() {
      return new Promise((resolve) => {
        api
          .createConditions({
            campaign_id: this.$route.params.id,
            user_id: this.twitter_account_id,
            actions: "FOLLOW",
          })
          .then(() => {
            this.twitter_account = "@";
            this.twitter_account_check = false;
            this.getConditions();
            this.$toast.show("フォロー条件の追加が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("フォロー条件の追加に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            this.twitter_account_check = false;
            resolve("rejected");
          });
      });
    },
    createRetweetConditionPromise() {
      return new Promise((resolve) => {
        api
          .createConditions({
            campaign_id: this.$route.params.id,
            tweet_id: this.tweet_id,
            actions: "RETWEET",
          })
          .then(() => {
            this.conditions_tweet_url = [];
            this.tweet_url = "";
            this.tweet_url_check = false;
            this.getConditions();
            this.$toast.show("リツイート条件の追加が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("リツイート条件の追加に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    deleteFollowConditionPromise(user_id) {
      return new Promise((resolve) => {
        api
          .deletefollowConditions({
            campaign_id: this.$route.params.id,
            user_id: user_id,
          })
          .then(() => {
            this.twitter_account = "@";
            this.twitter_account_check = false;
            this.$toast.show("フォロー条件の削除が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            this.getConditions();
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("フォロー条件の削除に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    deleteRetweetConditionPromise(tweet_id) {
      return new Promise((resolve) => {
        api
          .deleteretweetConditions({
            campaign_id: this.$route.params.id,
            tweet_id: tweet_id,
          })
          .then(() => {
            this.tweet_url = "";
            this.tweet_url_check = false;
            this.$toast.show("リツイート条件の削除が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            this.getConditions();
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("リツイート条件の削除に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    createDiscordConditionPromise() {
      return new Promise((resolve) => {
        api
          .createConditions({
            campaign_id: this.$route.params.id,
            guild_id: this.discordGuild.item_data_1,
            actions: "DISCORD_SERVER_JOIN",
            invite_url: this.discordInviteUrl,
          })
          .then(() => {
            this.getConditions();
            this.$toast.show("Discord条件の追加が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("Discord条件の追加に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    deleteDiscordConditionPromise() {
      return new Promise((resolve) => {
        api
          .deletediscordConditions({
            campaign_id: this.$route.params.id,
            guild_id: this.discordGuild.item_data_1,
          })
          .then(() => {
            this.$toast.show("Discord条件の削除が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            this.getConditions();
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("Discord条件の削除に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    createWalletConditionPromise() {
      return new Promise((resolve) => {
        api
          .createConditions({
            campaign_id: this.$route.params.id,
            actions: "WALLET_CONNECT",
          })
          .then(() => {
            this.getConditions();
            this.$toast.show("Wallet条件の追加が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("Wallet条件の追加に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    deleteWalletConditionPromise() {
      return new Promise((resolve) => {
        api
          .deleteWalletConditions({
            campaign_id: this.$route.params.id,
          })
          .then(() => {
            this.$toast.show("Wallet条件の削除が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            this.getConditions();
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("Wallet条件の削除に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    createExternalSiteConditionPromise(url) {
      return new Promise((resolve) => {
        api
          .createConditions({
            campaign_id: this.$route.params.id,
            external_site_url: url,
            actions: "EXTERNAL_SITE_VISIT",
          })
          .then(() => {
            this.getConditions();
            this.$toast.show("外部サイト条件の追加が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("外部サイト条件の追加に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    deleteExternalSiteConditionPromise() {
      return new Promise((resolve) => {
        api
          .deleteExternalSiteConditions({
            campaign_id: this.$route.params.id,
          })
          .then(() => {
            this.$toast.show("外部サイト条件の削除が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            this.getConditions();
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("外部サイト条件の削除に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    createInstagramFollowConditionPromise(url) {
      return new Promise((resolve) => {
        api
          .createConditions({
            campaign_id: this.$route.params.id,
            external_site_url: url,
            actions: "INSTAGRAM_FOLLOW",
          })
          .then(() => {
            this.getConditions();
            this.$toast.show("インスタグラムフォロー条件の追加が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("インスタグラムフォロー条件の追加に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    deleteInstagramFollowConditionPromise() {
      return new Promise((resolve) => {
        api
          .deleteInstagramFollowConditions({
            campaign_id: this.$route.params.id,
          })
          .then(() => {
            this.$toast.show("インスタグラムフォロー条件の削除が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            this.getConditions();
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("インスタグラムフォロー条件の削除に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    createInstagramLikeConditionPromise(url) {
      return new Promise((resolve) => {
        api
          .createConditions({
            campaign_id: this.$route.params.id,
            external_site_url: url,
            actions: "INSTAGRAM_LIKE",
          })
          .then(() => {
            this.getConditions();
            this.$toast.show("インスタグラムいいね条件の追加が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("インスタグラムいいね条件の追加に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    deleteInstagramLikeConditionPromise() {
      return new Promise((resolve) => {
        api
          .deleteInstagramLikeConditions({
            campaign_id: this.$route.params.id,
          })
          .then(() => {
            this.$toast.show("インスタグラムいいね条件の削除が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            this.getConditions();
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("インスタグラムいいね条件の削除に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    createInstagramHashtagConditionPromise(keyword) {
      return new Promise((resolve) => {
        api
          .createConditions({
            campaign_id: this.$route.params.id,
            keyword: keyword,
            actions: "INSTAGRAM_HASHTAG",
          })
          .then(() => {
            this.getConditions();
            this.$toast.show("ハッシュタグ投稿条件の追加が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("ハッシュタグ投稿条件の追加に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    deleteInstagramHashtagConditionPromise() {
      return new Promise((resolve) => {
        api
          .deleteInstagramHashtagConditions({
            campaign_id: this.$route.params.id,
          })
          .then(() => {
            this.$toast.show("ハッシュタグ投稿条件の削除が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            this.getConditions();
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("ハッシュタグ投稿条件の削除に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    getCampaign() {
      api
        .getCampaign({ id: this.$route.params.id })
        .then((response) => {
          this.campaign_category = response["data"][0]["category"];
          this.campaign_status = response["data"][0]["status"];
          this.reword_amount = response["data"][0]["amount"];
          for (var i = 0; i < this.reword_amount; i++) {
            this.reword_list.push(["", "", ""]);
          }
          api
            .getGifts({
              campaign_id: this.$route.params.id,
            })
            .then((response) => {
              console.log(response);
              if (response["data"].length != 0) {
                for (var i = 0; i < this.reword_amount; i++) {
                  this.reword_list[i][0] = response["data"][i]["label"];
                  this.reword_list[i][1] = response["data"][i]["code"];
                  this.reword_list[i][2] = response["data"][i]["gift_id"];
                  this.reword_update = true;
                }
              }
            })
            .catch((error) => {
              this.$toast.show("ギフトの取得に失敗しました", {
                type: "error",
                position: "top-right",
                duration: 5000,
              });
              console.log("error: ", error);
            });
          this.getInstantwin();
        })
        .catch((error) => {
          this.$toast.show("キャンペーンの取得に失敗しました", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
          console.log("error: ", error);
        });
    },
    getInstantwin() {
      if (this.campaign_category == "INSTANT_WIN") {
        api
          .getInstantWin({ campaign_id: this.$route.params.id })
          .then((response) => {
            console.log("instantwin info:", response);
            this.raffleEveryday = response.data[0].raffle_everyday;
          })
          .catch((error) => {
            this.$toast.show("キャンペーン情報の取得に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
          });
      }
    },
    createGiftsPromise() {
      return new Promise((resolve) => {
        api
          .createGifts({
            campaign_id: this.$route.params.id,
            gifts: this.reword_list,
          })
          .then(() => {
            this.$toast.show("ギフトの登録が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            this.$router.go({
              name: "addConditions",
              params: { id: this.$route.params.id },
            });
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("ギフトの登録に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    updateGiftsPromise() {
      return new Promise((resolve) => {
        api
          .updateGifts({
            campaign_id: this.$route.params.id,
            gifts: this.reword_list,
          })
          .then(() => {
            this.$toast.show("ギフトの変更が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            this.$router.go({
              name: "addConditions",
              params: { id: this.$route.params.id },
            });
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("ギフトの変更に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    async handleFileSelect(e) {
      // 読み込んだCSVファイルを配列に変換
      const file = this.parseCSV(await e.target.files[0].text());

      // フォーマットチェック
      // 行数チェック
      if (file.length != this.reword_amount) {
        this.$toast.show(
          "CSVのデータ数が一致しません。ヘッダーなしでデータ行の数をギフトの数と一致させてください",
          {
            type: "error",
            position: "top-right",
            duration: 5000,
          }
        );
        this.csvError = true;
        return;
      }
      // 列数チェック
      for (let i = 0; i < this.reword_list.length; i++) {
        if (file[i].length != 2) {
          this.$toast.show(
            "CSVのデータ数が一致しません。各行は ラベル, コード の２列のみ入力してください",
            {
              type: "error",
              position: "top-right",
              duration: 5000,
            }
          );
          this.csvError = true;
          return;
        }
      }

      for (let i = 0; i < this.reword_list.length; i++) {
        this.reword_list[i][0] = file[i][0];
        this.reword_list[i][1] = file[i][1];
      }
      this.reword_list.splice;
      this.$toast.show("CSVの読み込みが完了しました", {
        type: "success",
        position: "top-right",
        duration: 3000,
      });
      this.csvError = false;
    },
    parseCSV(data) {
      return data.split("\n").map((row) => row.split(","));
    },
    getDiscordInfo() {
      api
        .getItemsDiscord()
        .then((response) => {
          console.log(response);
          if (response.data.length != 0) {
            this.discordGuild = response.data[0];
            this.discordConnect = true;
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.$toast.show("Discordのデータ取得に失敗しました", {
            type: "error",
            position: "top",
            duration: 5000,
          });
        });
    },
    onRaffleEverydayPromise() {
      return new Promise((resolve) => {
        api
          .updateInstantWin({
            campaign_id: this.$route.params.id,
            raffle_everyday: 1,
          })
          .then(() => {
            this.getInstantwin();
            this.$toast.show("毎日抽選をONにしました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("毎日抽選の設定更新に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    offRaffleEverydayPromise() {
      return new Promise((resolve) => {
        api
          .updateInstantWin({
            campaign_id: this.$route.params.id,
            raffle_everyday: 0,
          })
          .then(() => {
            this.getInstantwin();
            this.$toast.show("毎日抽選をOFFにしました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            this.getConditions();
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("毎日抽選の設定更新に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    validateAppDownloadUrl() {
      try {
        const url = new URL(this.appDownloadUrl);

        // onelink.meドメインであることを確認
        const isOnelinkDomain = url.hostname.endsWith("onelink.me");

        // deep_link_valueパラメータが存在することを確認
        const hasDeepLinkValue = url.searchParams.has("deep_link_value");

        this.isValidAppDownloadUrl = isOnelinkDomain && hasDeepLinkValue;

        if (this.isValidAppDownloadUrl) {
          this.deepLinkValue = url.searchParams.get("deep_link_value");
        } else {
          this.deepLinkValue = "";
        }
      } catch (error) {
        // URLの解析に失敗した場合（無効なURL）
        this.isValidAppDownloadUrl = false;
        this.deepLinkValue = "";
      }
    },

    createAppDownloadConditionPromise() {
      return new Promise((resolve) => {
        api
          .createConditions({
            campaign_id: this.$route.params.id,
            invite_url: this.appDownloadUrl,
            actions: "INSTALL",
            keyword: this.deepLinkValue,
          })
          .then(() => {
            this.getConditions();
            this.$toast.show("アプリDL条件の追加が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("アプリDL条件の追加に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },

    deleteAppDownloadConditionPromise() {
      return new Promise((resolve) => {
        api
          .deleteAppDownloadConditions({
            campaign_id: this.$route.params.id,
          })
          .then(() => {
            this.$toast.show("アプリDL条件の削除が完了しました", {
              type: "success",
              position: "top-right",
              duration: 3000,
            });
            this.getConditions();
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("アプリDL条件の削除に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    checkOrganizer() {
      api
        .getMyOrganizer()
        .then((response) => {
          this.$store.commit(
            "updatesmallCampaignCount",
            response.data.small_campaign_count
          );
          this.$store.commit(
            "updatebigCampaignCount",
            response.data.big_campaign_count
          );
          this.organizer_name = response.data.name;
          this.isFuelhash = this.organizer_name === "FUELHASH";
        })
        .catch((error) => {
          this.$toast.show("オーガナイザー情報の取得に失敗しました。", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
          console.log("organizer check error", error);
        });
    },
  },
};
</script>
