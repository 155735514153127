import MockAdapter from "axios-mock-adapter";

let campaigns = [
  {
    campaign_id: 1,
    organizer_id: 11,
    status: 1,
    keyword: "#Yuliverse",
    start_time: "2023-03-01 00:00:00",
    end_time: "2023-04-01 00:00:00",
    campaign_name: "Yuliverse 2023",
    abstruct:
      "To everyone who interested in BCG! We hold the campaign to introduce our new system for bleed your Yulis.",
    detail: "hogehogehoge.",
    distribution_type: 1,
    amount: 10000,
    currency: "USDT",
    category: 1,
  },
  {
    campaign_id: 2,
    organizer_id: 12,
    status: 1,
    keyword: "#TSUBASA_GOAL",
    start_time: "2023-03-02 12:00:00",
    end_time: "2023-04-01 00:00:00",
    campaign_name: "TSUBASA Festival",
    abstruct:
      "To everyone who interested in BCG! We hold the campaign to introduce our new system for bleed your Player.",
    detail: "hogehogehoge.",
    distribution_type: 2,
    amount: 1000000,
    currency: "TSUBASA",
    category: 2,
  },
  {
    campaign_id: 3,
    organizer_id: 13,
    status: 2,
    keyword: "#STEPN2023",
    start_time: "2023-01-01 01:00:00",
    end_time: "2023-02-01 01:00:00",
    campaign_name: "Walk Walk Walk 2023",
    abstruct:
      "To everyone who interested in BCG! We hold the campaign to introduce our new system for bleed your shoes. ",
    detail: "hogehogehoge.",
    distribution_type: 3,
    amount: 10000,
    currency: "GMT",
    category: 3,
  },
  {
    campaign_id: 4,
    organizer_id: 14,
    status: 2,
    keyword: "#AZUKI",
    start_time: "2023-01-01 02:00:00",
    end_time: "2023-02-01 01:00:00",
    campaign_name: "NFT party in Japan",
    abstruct:
      "To everyone who interested in BCG! We hold the campaign to introduce our new system for bleed your NFT. ",
    detail: "hogehogehoge.",
    distribution_type: 1,
    amount: 30000,
    currency: "USDT",
    category: 4,
  },
  {
    campaign_id: 5,
    organizer_id: 15,
    status: 2,
    keyword: "#Kudasai",
    start_time: "2023-01-06 13:00:00",
    end_time: "2023-02-01 01:00:00",
    campaign_name: "Kudasai TGE",
    abstruct:
      "To everyone who interested in BCG! We hold the campaign to introduce our new GAME. ",
    detail: "hogehogehoge.",
    distribution_type: 2,
    amount: 10000,
    currency: "USDT",
    category: 1,
  },
  {
    campaign_id: 6,
    organizer_id: 16,
    status: 2,
    keyword: "#SOCIAMA",
    start_time: "2023-01-21 23:00:00",
    end_time: "2023-02-01 01:00:00",
    campaign_name: "ソシャマ　サービス開始記念！",
    abstruct:
      "To everyone who interested in BCG! We hold the campaign to introduce our new Service. ",
    detail: "hogehogehoge.",
    distribution_type: 3,
    amount: 10000,
    currency: "USDT",
    category: 2,
  },
  {
    campaign_id: 7,
    organizer_id: 17,
    status: 3,
    keyword: "#TrustWallet",
    start_time: "2022-12-21 00:00:00",
    end_time: "2023-01-01 01:00:00",
    abstruct: "To everyone who interested in Wallet.",
    campaign_name: "トラストウォレット 10,000ユーザー突破キャンペーン",
    detail: "hogehogehoge.",
    distribution_type: 1,
    amount: 10000,
    currency: "USDT",
    category: 3,
  },
  {
    campaign_id: 8,
    organizer_id: 18,
    status: 3,
    keyword: "#TradersJoe",
    start_time: "2022-12-23 03:00:00",
    end_time: "2023-01-01 01:00:00",
    abstruct: "To everyone who interested in DeFi.",
    campaign_name: "No1 DeFi campaign",
    detail: "hogehogehoge.",
    distribution_type: 2,
    amount: 500000,
    currency: "AVAX",
    category: 4,
  },
  {
    campaign_id: 9,
    organizer_id: 19,
    status: 3,
    keyword: "#MZFund",
    start_time: "2022-12-24 02:00:00",
    end_time: "2023-01-01 01:00:00",
    abstruct: "To everyone who interested in Funds.",
    campaign_name: "Fund 結成1年記念",
    detail: "hogehogehoge.",
    distribution_type: 3,
    amount: 1000000,
    currency: "USDT",
    category: 1,
  },
  {
    campaign_id: 10,
    organizer_id: 20,
    status: 3,
    keyword: "#JobTribes",
    start_time: "2022-12-22 12:00:00",
    end_time: "2023-01-01 01:00:00",
    abstruct: "To everyone who interested in BCGs.",
    campaign_name: "ユーザー数10万人記念キャンペーン",
    detail: "hogehogehoge.",
    distribution_type: 1,
    amount: 10000,
    currency: "DEP",
    category: 2,
  },
];

export default {
  run: (client) => {
    const mock = new MockAdapter(client);
    mock.onGet("/campaigns").reply(200, campaigns);
  },
};
