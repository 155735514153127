<template>
  <div class="AboutUs">
    <Header />
    <section class="relative py-32 bg-white overflow-hidden">
      <img
        class="absolute left-0 top-0"
        src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/features/elipse.svg"
        alt=""
      />
      <div class="grid grid-cols-12">
        <div class="col-span-2"></div>
        <div class="col-span-8">
          <p class="text-lg md:text-5xl text-left border-b">
            特定商取引法に基づく表記
          </p>
          <table
            class="table-auto text-left w-full text-xs md:text-base border-separate border-spacing-2 md:border-spacing-5"
          >
            <thead>
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody class="my-3">
              <tr>
                <td class="border-b">事業者の名称</td>
                <td class="border-b">ソーシャルマーケティング株式会社</td>
              </tr>
              <tr>
                <td class="border-b">代表者</td>
                <td class="border-b">相原 和明</td>
              </tr>
              <tr>
                <td class="border-b">所在地</td>
                <td class="border-b">東京都文京区本郷4-1-4</td>
              </tr>
              <tr>
                <td class="border-b">メールアドレス</td>
                <td class="border-b">support@social-marketing.io</td>
              </tr>
              <tr>
                <td class="border-b">販売価格</td>
                <td class="border-b">購入手続の際に画面に表示されます。</td>
              </tr>
              <tr>
                <td class="border-b">販売価格以外の必要料金</td>
                <td class="border-b">
                  ウェブページの閲覧等に必要となる通信費用は、ユーザーの負担となります。
                </td>
              </tr>
              <tr>
                <td class="border-b">支払方法・支払時期</td>
                <td class="border-b">クレジットカード決済</td>
              </tr>
              <tr>
                <td class="border-b">引渡時期</td>
                <td class="border-b">
                  所定の事項を送信後、利用可能となります。
                </td>
              </tr>
              <tr>
                <td class="border-b">キャンセルに関する事項</td>
                <td class="border-b">
                  ユーザー都合によるキャンセルや返金には応じかねます。
                </td>
              </tr>
              <tr>
                <td class="border-b">動作環境</td>
                <td class="border-b">
                  ・推奨OS<br />Windows 10以上 <br />MacOSX 10.9 以上
                  <br /><br />・推奨ブラウザ <br />Internet
                  Explorer（最新版）<br />
                  Edge（最新版）<br />
                  Chrome（最新版）<br />
                  Firefox（最新版）<br />
                  Safari（最新版）<br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-span-2"></div>
      </div>
    </section>
    <!-- footter -->
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/FooterParts.vue";
import Header from "@/components/HeaderParts.vue";
export default {
  name: "AboutUs",
  components: {
    Footer,
    Header,
  },
  mounted() {},
  computed: {},
};
</script>
